import { useQuery } from '@tanstack/react-query';
import { getWorkingUnits } from '../../services/workingUnit';
import { QueryKey } from '../../enums/QueryKey';

export function useWorkingUnits({ location, hasMatchingOfferedCluster = false, prependAllOption = false } = {}) {
    const { data: workingUnits } = useQuery({
        queryKey: [QueryKey.WorkingUnits, { ...(location && { location }), hasMatchingOfferedCluster }],
        queryFn: () => getWorkingUnits({ location, hasMatchingOfferedCluster }),
        initialData: [],
    });

    if (workingUnits.length && prependAllOption) {
        return [
            [
                {
                    id: -1,
                    worktype: {
                        worktype: 'Alle Working Units',
                    },
                    channel: {
                        name: '',
                    },
                    task: {
                        name: '',
                    },
                },
                ...workingUnits,
            ],
        ];
    }

    return [workingUnits];
}
