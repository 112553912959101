import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';

const BASE = '/totals';

export const getTotals = async ({ dateFrom, dateTo, workingUnitLocation } = {}) => {
    let url = BASE;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo, workingUnitLocation });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const getTotalsWithWorkingUnits = async ({ dateFrom, dateTo, location, totalsType } = {}) => {
    let url = `${BASE}/with-working-units`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo, location, totalsType });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const getTotalsExportData = async ({ dateFrom, dateTo, totalsType }) => {
    let url = `${BASE}/export`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo, totalsType });
    return api.get(url, await setToken()).then((res) => res.data);
};
