import { useState } from 'react';
import ResponsiveDialog from '../UI/Dialogs/ResponsiveDialog';
import { DialogContent, DialogActions, Button, Typography, MenuItem } from '@mui/material';
import TEFExportTotalsButton from './TEFExportTotalsButton';
import OSPExportTotalsButton from './OSPExportTotalsButton';
import { TotalsType } from '../../enums/TotalsType';
import CustomSelect from '../UI/CustomSelect';
import { useCustomStyles } from '../../hooks';
import { UserRole } from '../../enums/UserRole';

const getTotalsTypeOptions = (exportButtonType) => [
    { value: TotalsType.BIDDING.value, name: TotalsType.BIDDING[`name${exportButtonType}`] },
    { value: TotalsType.CORRECTED.value, name: TotalsType.CORRECTED[`name${exportButtonType}`] },
];

const ExportTotalsDialog = ({ open, onClose, date, location, exportButtonType }) => {
    const classes = useCustomStyles();
    const dialogTitle = 'Export Totals - ' + date.format('MM.YYYY');

    const totalsTypeOptions = getTotalsTypeOptions(exportButtonType);
    const [totalsType, setTotalsType] = useState(totalsTypeOptions[0].value);

    return (
        <ResponsiveDialog
            fullScreen={false}
            fullWidth={true}
            title={dialogTitle}
            onClose={onClose}
            open={open}
            actionButtons={
                <DialogActions sx={{ ...classes.dialogActions, ...classes.marginBottom }}>
                    <Button onClick={onClose} variant="outlined">
                        Abbrechen
                    </Button>
                    {exportButtonType === UserRole.TEF && (
                        <TEFExportTotalsButton date={date} totalsType={totalsType}></TEFExportTotalsButton>
                    )}
                    {exportButtonType === UserRole.OSP && (
                        <OSPExportTotalsButton
                            date={date}
                            location={location}
                            totalsType={totalsType}
                        ></OSPExportTotalsButton>
                    )}
                </DialogActions>
            }
        >
            <DialogContent>
                <Typography sx={{ marginBottom: '1rem' }}>Welche Totals möchten Sie exportieren?</Typography>
                <CustomSelect selectedValue={totalsType} onChange={setTotalsType} label="Totals Type">
                    {totalsTypeOptions.map(({ value, name }) => (
                        <MenuItem value={value} key={value}>
                            <span className="notranslate">{name}</span>
                        </MenuItem>
                    ))}
                </CustomSelect>
            </DialogContent>
        </ResponsiveDialog>
    );
};

export default ExportTotalsDialog;
