import { useQuery, useMutation } from '@tanstack/react-query';
import { deleteReduction, getReductions, postReduction } from '../../services/reduction';
import { QueryKey } from '../../enums/QueryKey';
import { queryClient } from '../../services/queryClient';
import dayjs from 'dayjs';
import { useDeleteRecord } from './crudOperations';
import { useDispatchErrorNotification } from '../useDispatchErrorNotification';
import { useDispatchSuccessfullySavedRecord } from '../useDispatchSuccessfullySavedRecord';
import { validateAndFormatStartAndEndDates } from '../../utils/dateUtils';

const getQueryKey = (filterParams) => {
    const { location, worktype, startDate, endDate } = filterParams;

    const start = dayjs(startDate).startOf('month').format('YYYY-MM-DD 00:00:00');
    const end = dayjs(endDate).endOf('month').format('YYYY-MM-DD 23:59:59');

    return [
        QueryKey.Reductions,
        {
            start,
            end,
            ...(location && { location }),
            ...(worktype && { worktype }),
        },
    ];
};

export function useReductions(params) {
    const { location, worktype, startDate, endDate } = params;
    const { start, end } = validateAndFormatStartAndEndDates(startDate, endDate, 'month');

    const { data } = useQuery({
        queryKey: getQueryKey(params),
        queryFn: () =>
            getReductions({
                location,
                worktype,
                start,
                end,
            }),
        initialData: [],
        enabled:
            !!start &&
            !!end &&
            location !== '' &&
            location !== undefined &&
            location !== null &&
            worktype !== '' &&
            worktype !== undefined &&
            worktype !== null,
    });

    const { reductions, durationTotal, reductionTotal } = data;
    return { reductions, durationTotal, reductionTotal };
}

export function useDeleteReduction(filterParams) {
    return useDeleteRecord({
        deleteRecordFn: deleteReduction,
        fetchRecordsQueryKey: getQueryKey(filterParams),
        recordName: 'Abschlag',
    });
}

export function usePostReduction({ filterParams, onSuccess }) {
    const { dispatchErrorNotification } = useDispatchErrorNotification();
    const { dispatchSuccessfullySavedRecord } = useDispatchSuccessfullySavedRecord();

    return useMutation({
        mutationFn: postReduction,
        onSuccess: () => {
            queryClient.invalidateQueries(getQueryKey(filterParams));
            onSuccess();
            dispatchSuccessfullySavedRecord('Abschlag');
        },
        onError: (error) => {
            dispatchErrorNotification({ message: error.message });
        },
    });
}
