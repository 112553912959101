import { useContext, useState } from 'react';
import UserContext from '../../store/User/UserContext';
import { orderBy } from 'lodash';
import dayjs from 'dayjs';
import StrategicVolumeExportDialog from './StrategicVolumeExportDialog';
import { ReactComponent as FileExport } from '../../assets/file-export.svg';
import { getStrategicVolumeReports } from '../../services/strategicVolumeReport';
import { StrategicVolumeReportRelease } from '../../enums/StrategicVolumeReportRelease';
import { Button } from '@mui/material';
import ExportToExcelButton from '../Buttons/ExportToExcelButton';

const exportColumnNames = [
    'Stadt',
    'Worktype',
    'Kanal',
    'Forecaster',
    'Kontaktart',
    'Anmerkungen',
    'Einheit',
    'Datum',
    'Strategic Volume Value',
];

const StrategicVolumeExportButton = ({ locations }) => {
    const loggedInUser = useContext(UserContext);
    const isTEFUser = loggedInUser?.isTEFUser;
    const numOfNextMonths = isTEFUser ? 12 : 6;

    const [openExportDialog, setOpenExportDialog] = useState(false);
    const [startDate, setStartDate] = useState(!isTEFUser ? dayjs().format('YYYY-MM-DD') : '');
    const [endDate, setEndDate] = useState(
        !isTEFUser ? dayjs().add(numOfNextMonths, 'months').format('YYYY-MM-DD') : ''
    );

    const handleExportDialogClose = () => {
        setOpenExportDialog(false);
    };

    const getDataFromDb = async () => {
        const dateFrom = dayjs(startDate).startOf('month').format('YYYY-MM-DD 00:00:00');
        const dateTo = dayjs(endDate).endOf('month').format('YYYY-MM-DD 23:59:59');

        const location = isTEFUser ? null : locations.map((location) => location.id);
        const releaseType = isTEFUser ? null : StrategicVolumeReportRelease.RELEASED;

        return await getStrategicVolumeReports(location, dateFrom, dateTo, loggedInUser.id, releaseType);
    };

    const getExportData = (data) => {
        let exportData = [];
        data.forEach((strategicVolume) => {
            const location = locations.find(
                (location) => location.id === strategicVolume.workingUnitLocation.worktypeLocation.locationId
            );

            const reports = orderBy(strategicVolume.strategicVolumeReports, ['date'], ['asc']);
            reports.forEach((strategicVolumeReport) => {
                exportData.push({
                    strategicVolumeId: strategicVolume.id,
                    forecasterNames: strategicVolume.forecasterNames.join(','),
                    capacityTypeUnit: strategicVolume.capacityType.unit,
                    reportValue: strategicVolumeReport?.value ?? null,
                    code: location?.code ?? null,
                    city: location?.city ?? null,
                    worktype: strategicVolume?.workingUnitLocation?.workingUnit?.worktype?.worktype,
                    channel: strategicVolume?.workingUnitLocation?.workingUnit?.channel?.name,
                    date: strategicVolumeReport.date,
                });
            });
        });

        return exportData;
    };

    const createExcelRowForExport = (exportDataRow) => {
        let excelRow = [];

        excelRow = [
            exportDataRow.code + '-' + exportDataRow.city,
            exportDataRow.worktype,
            exportDataRow.channel,
            exportDataRow.forecasterNames,
            exportDataRow.contact,
            exportDataRow.comment,
            exportDataRow.capacityTypeUnit,
            dayjs(exportDataRow.date).startOf('month').format('DD.MM.YYYY'),
            exportDataRow.reportValue,
        ];

        return excelRow;
    };

    const getExportFileName = () => {
        const start = dayjs(startDate).format('MM.YYYY');
        const end = dayjs(endDate).format('MM.YYYY');

        const fileName = `Strategischen Forecasts_${start}_${end}.xlsx`;

        return fileName;
    };

    return isTEFUser ? (
        <>
            <Button onClick={() => setOpenExportDialog(true)} variant="contained">
                <FileExport />
            </Button>
            {openExportDialog && (
                <StrategicVolumeExportDialog
                    open={openExportDialog}
                    onClose={handleExportDialogClose}
                    locations={locations}
                    createExcelRowForExport={createExcelRowForExport}
                    getExportData={getExportData}
                    getDataFromDb={getDataFromDb}
                    startDate={startDate}
                    onStartDateChange={setStartDate}
                    endDate={endDate}
                    onEndDateChange={setEndDate}
                    exportColumnNames={exportColumnNames}
                ></StrategicVolumeExportDialog>
            )}
        </>
    ) : (
        <ExportToExcelButton
            columnNames={exportColumnNames}
            sheetName="Report"
            getFileName={getExportFileName}
            wbTitle=""
            wbSubject="Export Report"
            createRowFromExportData={createExcelRowForExport}
            getDataFromDb={getDataFromDb}
            getExportData={getExportData}
        />
    );
};

export default StrategicVolumeExportButton;
