import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../enums/QueryKey';
import { getDateRangeFromStartDateByAddingMonths, formatLastUploadDate } from '../../utils/dateUtils';
import {
    getLastCapacityReportUploadDate,
    getLastCapacityReportUploadUserAndDate,
    getLatestCapacityReportByWorktypes,
    getCapacityReport,
    getLastCapacityReportUploadDatePerLocation,
} from '../../services/capacityReport';

export function getCapacityReportQueryKey({ date, location, plausibilityCheck, numOfMonthColumns }) {
    const { dateFrom, dateTo } = getDateRangeFromStartDateByAddingMonths(date, numOfMonthColumns);

    return [
        QueryKey.CapacityReport,
        {
            dateFrom: dateFrom.format('YYYY-MM-DD HH:mm:ss'),
            dateTo: dateTo.format('YYYY-MM-DD HH:mm:ss'),
            location,
            plausibilityCheck,
        },
    ];
}

export function useCapacityReport({ date, location, plausibilityCheck, numOfMonthColumns }) {
    const { dateFrom, dateTo } = getDateRangeFromStartDateByAddingMonths(date, numOfMonthColumns);
    const queryKey = getCapacityReportQueryKey({ date, location, plausibilityCheck, numOfMonthColumns });

    const { data, refetch } = useQuery({
        queryKey,
        queryFn: () => getCapacityReport({ location, dateFrom, dateTo, plausibilityCheck }),
        enabled: !!date && !!location,
        initialData: [],
    });

    return { data, refetch };
}

export function useLastCapacityReportUploadDate(location) {
    const { data } = useQuery({
        queryKey: [QueryKey.LastUploadDate + '-capacityReport', { location }],
        queryFn: () => getLastCapacityReportUploadDate(location),
        enabled: !!location,
    });

    return formatLastUploadDate(data);
}

export function useLastCapacityReportUploadUserAndDate(location) {
    const { data } = useQuery({
        queryKey: [QueryKey.LastUploadUserAndDate + '-capacityReport', { location }],
        queryFn: () => getLastCapacityReportUploadUserAndDate(location),
        enabled: !!location,
    });

    const { user, date } = data || {};
    return { user, date: date ? formatLastUploadDate(date) : '' };
}

export function useLatestCapacityReportsByWorktypes({ location, user, showLastUploadInfo }) {
    const { data } = useQuery({
        queryKey: [QueryKey.LatestCapacityReportsByWorktypes, { location, user }],
        queryFn: () => getLatestCapacityReportByWorktypes({ location, user }),
        enabled: !!showLastUploadInfo && !!location && !!user,
    });

    return data || [];
}

export function useLastCapacityReportUploadDatePerLocation(location) {
    const { data } = useQuery({
        queryKey: [QueryKey.LastUploadDate + '-capacityReport-per-location', { location }],
        queryFn: () => getLastCapacityReportUploadDatePerLocation(location),
        initialData: [],
    });

    return [data];
}
