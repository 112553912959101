import Container from '../components/Layout/Container';
import { Grid, Button } from '@mui/material';
import UserContext from '../store/User/UserContext';
import { useState, useContext, useCallback, useRef } from 'react';
import {
    useAlertMessage,
    useLocationsByLoggedInUserRole,
    useCookieLocation,
    useHEMTLocations,
    useCustomStyles,
} from '../hooks';
import {
    createCapacityReport,
    createOneCapacityReport,
    checkCapacityReportPlausibility,
    uploadCapacityReports,
} from '../services/capacityReport';
import { createTableRows } from '../utils/agGridUtils';
import SelectExportCapacitiesDateRangeDialog from '../components/CapacityReport/SelectExportCapacitiesDateRangeDialog';
import { NUM_OF_MONTH_COLUMNS_FOR_CAPACITY_REPORT } from '../components/CapacityReport/helpers/constants';
import {
    setCommonStateAfterDataUpload,
    showSuccessfulUploadNotification,
    showErrorUploadNotification,
    parseUploadResponse,
} from '../utils/componentUtils';
import LastUpload from '../components/General/LastUpload';
import LastUploadCapacityReportPopup from '../components/CapacityReport/LastUploadCapacityReportPopup';
import { ReactComponent as FileExport } from '../assets/file-export.svg';
import { getMonthColumns, createMapWithTableRows } from '../components/CapacityReport/helpers/utils';
import { plausibilityCheckOptions } from '../components/CapacityReport/helpers/constants';
import CapacityReportTable from '../components/CapacityReport/CapacityReportTable';
import PlausibilityCheckSelect from '../components/Selects/PlausibilityCheckSelect';
import { CapacityReportType } from '../enums/CapacityReportType';
import dayjs from 'dayjs';
import UserRoleLocation from '../components/General/UserRoleLocation';
import CapacityReportEmptyTemplateDownloadButton from '../components/CapacityReport/CapacityReportEmptyTemplateDownloadButton';
import { parseXLSXData } from '../utils/xlsxUtils';
import UploadXlsxFileButton from '../components/Buttons/UploadXlsxFileButton';
import PercentageLoadingOverlay from '../components/Loaders/PercentageLoadingOverlay';
import CapacityReportCurrentDataDownloadButton from '../components/CapacityReport/CapacityReportCurrentDataDownloadButton';
import * as Sentry from '@sentry/react';
import GridContainer from '../components/Layout/GridContainer';
import {
    useLastCapacityReportUploadDate,
    useLastCapacityReportUploadUserAndDate,
    useLatestCapacityReportsByWorktypes,
    useCapacityReport,
    getCapacityReportQueryKey,
} from '../hooks/api/capacityReports';
import { queryClient } from '../services/queryClient';
import { useMutation } from '@tanstack/react-query';

const createLastUploadTableRows = (capacityReports) => {
    const rowsMap = new Map();
    for (const report of capacityReports) {
        const key = report.worktype;
        const month = [dayjs(report.date).format('YYYY-MM')];
        const existingEntry = rowsMap.get(key) || {};

        rowsMap.set(key, {
            ...existingEntry,
            [month]: report.sum,
        });
    }

    return createTableRows(rowsMap, 'worktype');
};

const createLastUploadTableColumns = (allMonths) => {
    const columns = [
        {
            field: 'worktype',
            headerName: '',
            minWidth: 120,
        },
    ];

    allMonths.forEach((month) => {
        columns.push({
            field: `${dayjs(month).format('YYYY-MM')}`,
            headerName: `${dayjs(month).format('MMM YY')}`,
            cellClass: 'grid-cell-centered',
        });
    });

    return columns;
};

function CapacityReport() {
    const classes = useCustomStyles();

    const { dispatchAlert } = useAlertMessage();
    const loggedInUser = useContext(UserContext);

    const gridRef = useRef(null);
    const [firstMonth, setFirstMonth] = useState(dayjs());
    const [monthForUploading, setMonthForUploading] = useState(dayjs().format('YYYY-MM'));
    const [loading, setLoading] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [expandedRows, setExpandedRows] = useState([]);
    const [openExportDialog, setOpenExportDialog] = useState(false);

    const allLocationsFromHEMT = useHEMTLocations();
    const [locationOptions] = useLocationsByLoggedInUserRole();
    const [selectedLocation, setSelectedLocation] = useCookieLocation(locationOptions);
    const [selectedPlausibilityCheck, setSelectedPlausibilityCheck] = useState(plausibilityCheckOptions[0].value);

    const [lastUploadPopoverAnchorEl, setLastUploadPopoverAnchorEl] = useState(null);
    const lastCapacityReportUploadDate = useLastCapacityReportUploadDate(selectedLocation);
    const { user: lastUploadUser, date: lastUploadUserDate } = useLastCapacityReportUploadUserAndDate(selectedLocation);
    const lastUploadData = useLatestCapacityReportsByWorktypes({
        location: selectedLocation,
        user: loggedInUser?.id,
        showLastUploadInfo: lastUploadPopoverAnchorEl,
    });
    const lastUploadTableRows = lastUploadData.capacityReport
        ? createLastUploadTableRows(lastUploadData.capacityReport)
        : [];
    const lastUploadColumnDefs = lastUploadData.allMonths ? createLastUploadTableColumns(lastUploadData.allMonths) : [];
    const lastUploadDate = lastUploadUserDate || lastCapacityReportUploadDate;

    const capacityReportQueryKey = getCapacityReportQueryKey({
        date: firstMonth,
        location: selectedLocation,
        plausibilityCheck: selectedPlausibilityCheck,
        numOfMonthColumns: NUM_OF_MONTH_COLUMNS_FOR_CAPACITY_REPORT,
    });

    const monthColumns = getMonthColumns(firstMonth);

    const { data: capacityReports } = useCapacityReport({
        date: firstMonth,
        location: selectedLocation,
        plausibilityCheck: selectedPlausibilityCheck,
        numOfMonthColumns: NUM_OF_MONTH_COLUMNS_FOR_CAPACITY_REPORT,
    });
    const tableRows =
        capacityReports.length > 0
            ? createTableRows(createMapWithTableRows(capacityReports, monthColumns, 'capacityReports'), 'id')
            : [];

    const getExpandedRowsInTable = () => {
        const expandedRows = [];
        gridRef.current.api.forEachNode((node) => {
            if (node.displayed && node.expanded) {
                expandedRows.push(node);
            }
        });

        return expandedRows;
    };

    const { mutateAsync: handlePlausibilityCheck } = useMutation({
        mutationFn: checkCapacityReportPlausibility,
        onSuccess: () => {
            queryClient.invalidateQueries(capacityReportQueryKey);
        },
    });

    const { mutateAsync: handleCreateCapacityReport } = useMutation({
        mutationFn: createCapacityReport,
    });
    const { mutateAsync: handleCreateOneCapacityReport } = useMutation({
        mutationFn: createOneCapacityReport,
    });
    const { mutateAsync: handleUploadCapacityReports } = useMutation({
        mutationFn: uploadCapacityReports,
    });

    const saveCapacityReport = useCallback(
        async ({ tableRows, loggedInUserId, cellParams }) => {
            try {
                setExpandedRows(getExpandedRowsInTable());

                let numOfNewReports = 0;
                if (cellParams) {
                    const capacityReportValue = cellParams.newValue;
                    let data = {
                        changedCapacity: {
                            capacityId: parseInt(cellParams.data.id),
                            capacityTypeName: cellParams.data.capacityType.name,
                            date: cellParams.column.colId,
                            value: capacityReportValue,
                            capacityType: cellParams.data.capacityType,
                        },
                        userId: loggedInUserId,
                    };

                    await handleCreateOneCapacityReport(data);
                    numOfNewReports = 1;
                } else {
                    const result = await handleCreateCapacityReport(tableRows, loggedInUserId);
                    numOfNewReports = result.numOfNewReports;
                }

                if (numOfNewReports === 0) {
                    dispatchAlert({
                        message: 'Geben Sie gültige Daten ein',
                        type: 'error',
                    });
                } else {
                    dispatchAlert({
                        message: 'Potenzialmeldung erfolgreich erstellt',
                        type: 'success',
                    });
                }
            } catch (error) {
                dispatchAlert({
                    message: 'Error occured',
                    type: 'error',
                });
            } finally {
                queryClient.invalidateQueries(capacityReportQueryKey);
            }
        },
        [dispatchAlert, capacityReportQueryKey, handleCreateCapacityReport, handleCreateOneCapacityReport]
    );

    const handleExportDialogClose = () => {
        setOpenExportDialog(false);
    };

    const handleExportDialogOpen = () => {
        setOpenExportDialog(true);
    };

    const uploadFiles = (files, closeDropzoneDialog) => {
        setLoading(true);

        const file = files[0];

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = parseXLSXData(e);

            handleUploadCapacityReports({ data, user: loggedInUser?.id, setPercentage })
                .then(async (res) => {
                    const resData = parseUploadResponse(res, 'Capacity report');
                    const { success, errorMessage } = resData;

                    if (success) {
                        showSuccessfulUploadNotification(dispatchAlert);
                        queryClient.invalidateQueries(capacityReportQueryKey);
                    } else {
                        showErrorUploadNotification({
                            dispatchAlert,
                            errorMessage,
                        });
                    }
                })
                .catch(async (error) => {
                    Sentry.captureException(error);
                    showErrorUploadNotification({
                        dispatchAlert,
                        errorMessage: error.message,
                    });
                })
                .finally(() => {
                    setCommonStateAfterDataUpload(setLoading, setPercentage, closeDropzoneDialog);
                });
        };
        reader.readAsArrayBuffer(file);
    };

    const showLastUploadData = (event) => {
        setLastUploadPopoverAnchorEl(event.currentTarget);
    };

    const hideLastUploadData = () => {
        setLastUploadPopoverAnchorEl(null);
    };

    return (
        <Container>
            <GridContainer>
                {loggedInUser && (
                    <UserRoleLocation
                        loggedInUser={loggedInUser}
                        selectedLocation={selectedLocation}
                        locationOptions={locationOptions}
                        onChange={setSelectedLocation}
                    ></UserRoleLocation>
                )}
                <Grid item xs={12} md={3} lg={2}>
                    <PlausibilityCheckSelect
                        selectedValue={selectedPlausibilityCheck}
                        onChange={setSelectedPlausibilityCheck}
                    ></PlausibilityCheckSelect>
                </Grid>
                <Grid item xs={10} md={4} lg={3}>
                    <LastUpload
                        showInfoIcon={true}
                        onClick={(e) => showLastUploadData(e)}
                        date={lastUploadDate}
                        setLastUploadPopoverAnchorEl={setLastUploadPopoverAnchorEl}
                    ></LastUpload>
                </Grid>
                <Grid item xs={2} md={1} lg={4} sx={classes.btnRightAligned}>
                    <Button onClick={handleExportDialogOpen} variant="contained">
                        <FileExport />
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        onClick={() => handlePlausibilityCheck({ data: tableRows, locationId: selectedLocation })}
                        variant="outlined"
                        style={{ marginRight: '5px' }}
                    >
                        Plausibilitäts-Check
                    </Button>
                    <CapacityReportEmptyTemplateDownloadButton
                        months={getMonthColumns(firstMonth)}
                        location={selectedLocation}
                    ></CapacityReportEmptyTemplateDownloadButton>
                    <CapacityReportCurrentDataDownloadButton
                        startDate={firstMonth}
                        selectedLocation={selectedLocation}
                        selectedPlausibilityCheck={selectedPlausibilityCheck}
                        allLocationsFromHEMT={allLocationsFromHEMT}
                    ></CapacityReportCurrentDataDownloadButton>
                    <UploadXlsxFileButton
                        buttonName="Upload"
                        monthForUploading={monthForUploading}
                        onChangeMonthForUploading={setMonthForUploading}
                        uploadFiles={uploadFiles}
                        dialogTitle="Potenziale hochladen"
                    ></UploadXlsxFileButton>
                </Grid>
                <Grid item xs={12}>
                    <CapacityReportTable
                        ref={gridRef}
                        firstMonth={firstMonth}
                        onFirstMonthChanged={setFirstMonth}
                        tableRows={tableRows}
                        expandedRows={expandedRows}
                        onExpandedRowsChanged={setExpandedRows}
                        getExpandedRowsInTable={getExpandedRowsInTable}
                        saveCapacityReport={(params) =>
                            saveCapacityReport({
                                tableRows,
                                loggedInUserId: loggedInUser?.id,
                                firstMonth,
                                selectedLocation,
                                ...params,
                            })
                        }
                    ></CapacityReportTable>
                </Grid>
                <Grid item sm={6} md={8} lg={9}></Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} sx={classes.btnRightAligned}>
                    <Button
                        variant="outlined"
                        onClick={() =>
                            saveCapacityReport({
                                tableRows,
                                loggedInUserId: loggedInUser?.id,
                                firstMonth,
                                selectedLocation,
                            })
                        }
                    >
                        Speichern
                    </Button>
                </Grid>

                {openExportDialog && (
                    <SelectExportCapacitiesDateRangeDialog
                        open={openExportDialog}
                        onClose={handleExportDialogClose}
                        locations={locationOptions}
                        reportType={CapacityReportType.CAPACITY_REPORT}
                    ></SelectExportCapacitiesDateRangeDialog>
                )}
                {lastUploadPopoverAnchorEl && (
                    <LastUploadCapacityReportPopup
                        anchorEl={lastUploadPopoverAnchorEl}
                        onPopoverClose={hideLastUploadData}
                        location={selectedLocation}
                        lastUploadDate={lastUploadUserDate}
                        lastUploadUser={lastUploadUser}
                        tableRows={lastUploadTableRows}
                        columnDefs={lastUploadColumnDefs}
                    ></LastUploadCapacityReportPopup>
                )}
                <PercentageLoadingOverlay loading={loading} percentage={percentage}></PercentageLoadingOverlay>
            </GridContainer>
        </Container>
    );
}

export default CapacityReport;
