import { useContext } from 'react';
import UserContext from '../../store/User/UserContext';
import { getCallCenterPotentialsByDateRangeLocationAndWorktype } from '../../services/callCenterPotential';
import { getFirstDayOfMonth, getLastDayOfMonth } from '../../utils/dateUtils';
import DownloadExcelButton from '../Buttons/DownloadExcelButton';
import dayjs from 'dayjs';
import { usePreferredUserLocation } from '../../hooks';

const columnNames = ['START_TIME', 'HOUR', 'MINUTE', 'Potential', 'Stadt', 'Standort Detail', 'Worktype', 'Kanal'];

function PotentialsCurrentDataDownloadButton({ date, selectedLocation, worktypes }) {
    const loggedInUser = useContext(UserContext);
    const [location] = usePreferredUserLocation(selectedLocation);

    const getXLSXSheets = async (worktypes) => {
        const sheetsData = [];
        for (let i = 0; i < worktypes.length; i++) {
            const sheetData = [];

            const firstDayOfMonth = getFirstDayOfMonth(date);
            const lastDayOfMonth = getLastDayOfMonth(date);

            const potentials = await getCallCenterPotentialsByDateRangeLocationAndWorktype(
                firstDayOfMonth,
                lastDayOfMonth,
                loggedInUser.primaryLocation.id,
                worktypes[i].id
            );

            for (let j = 0; j < potentials.length; j++) {
                const start_time = dayjs(potentials[j].slotDate).format('DD.MM.YYYY');
                const hour = parseInt(dayjs(potentials[j].slotDate).format('HH'));
                const minute = parseInt(dayjs(potentials[j].slotDate).format('mm'));
                const potentialValue = potentials[j].potentialValue;
                const channel = potentials[j].channelName ? potentials[j].channelName : '';
                const row = [
                    start_time,
                    hour,
                    minute,
                    potentialValue,
                    loggedInUser.primaryLocation.city,
                    loggedInUser.primaryLocation.code,
                    worktypes[i].worktype,
                    channel,
                ];
                sheetData.push(row);
            }

            sheetsData.push({
                sheetName: worktypes[i].worktype,
                sheetData: sheetData,
            });
        }

        return sheetsData;
    };

    return (
        <DownloadExcelButton
            title="Potenziale Template"
            fileName={`potenziale_${location?.city}_${dayjs(date).format('MM_YYYY')}.xlsx`}
            location={selectedLocation}
            columnNames={columnNames}
            getSheetsData={() => getXLSXSheets(worktypes)}
            buttonText="Aktuell"
        ></DownloadExcelButton>
    );
}

export default PotentialsCurrentDataDownloadButton;
