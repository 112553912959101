import React, { createContext, useContext, useEffect, useMemo } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext();

export const useSocket = () => {
    return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
    const socket = useMemo(() => io(process.env.REACT_APP_URL), []);

    useEffect(() => {
        socket.connect();

        return () => {
            socket.disconnect();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
