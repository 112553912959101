import {
    NUM_OF_MONTH_COLUMNS_FOR_CAPACITY_REPORT,
    COLUMNS_FOR_CAPACITY_REPORT_EXCEL_TEMPLATE,
} from './helpers/constants';
import { getDateRangeFromStartDateByAddingMonths } from '../../utils/dateUtils';
import { groupBy, orderBy } from 'lodash';
import dayjs from 'dayjs';
import { getCapacityReport } from '../../services/capacityReport';
import { getMonthColumns } from './helpers/utils';
import DownloadExcelButton from '../Buttons/DownloadExcelButton';
import { usePreferredUserLocation } from '../../hooks';

const CapacityReportCurrentDataDownloadButton = ({
    startDate,
    selectedLocation,
    selectedPlausibilityCheck,
    allLocationsFromHEMT,
}) => {
    const [location] = usePreferredUserLocation(selectedLocation);

    const getXLSXSheets = async () => {
        const { dateFrom, dateTo } = getDateRangeFromStartDateByAddingMonths(
            startDate,
            NUM_OF_MONTH_COLUMNS_FOR_CAPACITY_REPORT
        );

        const report = await getCapacityReport({
            location: selectedLocation,
            dateFrom,
            dateTo,
            plausibilityCheck: selectedPlausibilityCheck,
        });

        const capacitiesByWorktype = groupBy(report, 'workingUnit.worktypeLocation.worktype.worktype');
        const sheetsData = [];
        for (const [worktype, capacities] of Object.entries(capacitiesByWorktype)) {
            let sheetData = [];

            for (const capacity of capacities) {
                const location = allLocationsFromHEMT.find(
                    (loc) => loc.id === capacity.workingUnitLocation.worktypeLocation.locationId
                );
                const channel = capacity.workingUnitLocation.workingUnit.channel.name;
                const task = capacity.workingUnitLocation.workingUnit.task.name;
                const capacityType = capacity.capacityType.name;

                for (const capacityReport of capacity.capacityReports) {
                    sheetData.push({
                        date: dayjs(capacityReport.date).format('01.MM.YYYY'),
                        city: location.city,
                        code: location.code,
                        worktype,
                        channel,
                        task,
                        capacityType,
                        value: capacityReport.value,
                    });
                }
            }

            sheetData = orderBy(sheetData, 'date', 'asc').map((sheet) => Object.values(sheet));
            sheetsData.push({
                sheetName: worktype,
                sheetData,
            });
        }

        return sheetsData;
    };

    const getFileNameSuffix = () => {
        const months = getMonthColumns(startDate);
        const fileNameSuffix = `${dayjs(months[0]).format('MM_YYYY')} - ${dayjs(months[months.length - 1]).format(
            'MM_YYYY'
        )}`;

        return fileNameSuffix;
    };

    return (
        <DownloadExcelButton
            title="Capacity Report Template"
            fileName={`capacityReport_${location?.city}_${getFileNameSuffix()}.xlsx`}
            location={selectedLocation}
            columnNames={COLUMNS_FOR_CAPACITY_REPORT_EXCEL_TEMPLATE}
            getSheetsData={() => getXLSXSheets()}
            buttonText="Aktuell"
        ></DownloadExcelButton>
    );
};

export default CapacityReportCurrentDataDownloadButton;
