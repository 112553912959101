import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { QueryKey } from '../../enums/QueryKey';
import { useDispatchErrorNotification, useDispatchSuccessNotification, useWorkingUnitLocations } from '../../hooks';
import { queryClient } from '../../services/queryClient';
import { releaseCorrectedTotals } from '../../services/correctedTotals';
import { useCorrectedTotalsForLocation } from '../../hooks/api/correctedTotals';
import { groupBy } from 'lodash';
import ReleaseTotalsDialog from './ReleaseTotalsDialog';
import { Alert } from '@mui/material';

const ReleaseCorrectedTotalsDialog = ({ open, onClose, date, workingUnitLocation }) => {
    const { dispatchSuccessNotification } = useDispatchSuccessNotification();
    const { dispatchErrorNotification } = useDispatchErrorNotification();

    const [selectedLocations, setSelectedLocations] = useState(null);

    const [workingUnitLocations] = useWorkingUnitLocations({
        location: selectedLocations?.id,
        hasMatchingOfferedCluster: true,
    });
    const [selectedWorkingUnits, setSelectedWorkingUnits] = useState([]);
    const [correctedTotals] = useCorrectedTotalsForLocation({ date, location: selectedLocations?.id });
    const correctedTotalsByWorkingUnit = groupBy(correctedTotals, 'workingUnitLocationId');
    const workingUnitOptions = workingUnitLocations.map((workingUnitLocation) => {
        const totals = correctedTotalsByWorkingUnit[workingUnitLocation.id];
        const unreleasedTotal = totals?.find((total) => !total.released);
        return {
            workingUnitLocationId: workingUnitLocation.id,
            ...workingUnitLocation.workingUnit,
            disabled: totals?.length > 0 && !unreleasedTotal,
        };
    });

    const { mutateAsync: handleReleaseCorrectedTotals } = useMutation({
        mutationFn: releaseCorrectedTotals,
        onSuccess: (res) => {
            if (res === 0) {
                return dispatchErrorNotification({ message: 'Keine Daten gefunden. Bitte lade deine Korrektur hoch.' });
            }

            dispatchSuccessNotification({ message: 'Daten erfolgreich gespeichert' });
            onClose();
        },
        onError: (error) => {
            dispatchErrorNotification({ message: error.message || 'Fehler beim Speichern der Daten' });
        },
        onSettled: () => {
            queryClient.invalidateQueries([QueryKey.LastCorrectedTotalsReleaseDate, { workingUnitLocation }]);
        },
    });

    return (
        <ReleaseTotalsDialog
            open={open}
            onClose={onClose}
            date={date}
            onRelease={() =>
                handleReleaseCorrectedTotals({
                    dateFrom: dayjs(date).startOf('month'),
                    dateTo: dayjs(date).endOf('month'),
                    workingUnitLocation: selectedWorkingUnits.map((wu) => wu.workingUnitLocationId),
                })
            }
            selectedLocations={selectedLocations}
            onSelectedLocationsChange={setSelectedLocations}
            workingUnitOptions={workingUnitOptions}
            selectedWorkingUnits={selectedWorkingUnits}
            onSelectedWorkingUnitsChange={setSelectedWorkingUnits}
            isLocationMultiSelect={false}
            releaseButtonText="Senden"
        >
            <Alert severity="info" sx={{ mt: 2 }} color="primary">
                Die korrigierten Totals können für jeden Monat nur einmal gesendet werden. Bereits gesendete Daten
                werden deaktiviert und können nicht erneut freigegeben werden.
            </Alert>
        </ReleaseTotalsDialog>
    );
};

export default ReleaseCorrectedTotalsDialog;
