import { useContext, useState } from 'react';
import Container from '../components/Layout/Container';
import { Grid } from '@mui/material';
import DashboardDateRangeWorktypeSelect from '../components/Dashboard/DashboardDateRangeWorktypeSelect';
import UserContext from '../store/User/UserContext';
import DashboardTEF from '../components/Dashboard/DashboardTEF';
import DashboardOSP from '../components/Dashboard/DashboardOSP';
import { useCookieWorktype, useWorktypes } from '../hooks';
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

function Dashboard() {
    const loggedInUser = useContext(UserContext);

    const [selectedStartDate, setSelectedStartDate] = useState(dayjs.utc().startOf('week').add(1, 'day'));
    const [selectedEndDate, setSelectedEndDate] = useState(dayjs.utc().endOf('week').add(1, 'day'));

    const [worktypeOptions] = useWorktypes({
        location: loggedInUser?.role.callmart_tef ? null : loggedInUser?.primaryLocation?.id,
        prependAllWorktypesOption: loggedInUser?.role.callmart_tef,
    });
    const [selectedWorktype, setSelectedWorktype] = useCookieWorktype(worktypeOptions);

    const handleWorktypeChange = (newValue) => {
        setSelectedWorktype(newValue);
    };

    const diffBetweenDateRangeInMs = selectedEndDate.diff(selectedStartDate);
    const diffBetweenDateRangeInDays = diffBetweenDateRangeInMs / (1000 * 3600 * 24);
    const showSlotIntervalOnChart = diffBetweenDateRangeInDays < 5 ? true : false;

    return loggedInUser?.isCallmartUser ? (
        <Container>
            <Grid container spacing={1} alignItems="stretch">
                <Grid item xs={12} sm={12} md={3} lg={2} sx={{ minWidth: '350px' }}>
                    <DashboardDateRangeWorktypeSelect
                        selectedStartDate={selectedStartDate}
                        selectedEndDate={selectedEndDate}
                        onStartDateChange={setSelectedStartDate}
                        onEndDateChange={setSelectedEndDate}
                        worktype={selectedWorktype}
                        worktypeOptions={worktypeOptions}
                        onWorktypeChange={handleWorktypeChange}
                    ></DashboardDateRangeWorktypeSelect>
                </Grid>
                {loggedInUser && loggedInUser.role.callmart_osp && !loggedInUser.role.callmart_tef && (
                    <DashboardOSP
                        dateFrom={selectedStartDate}
                        dateTo={selectedEndDate}
                        worktype={selectedWorktype}
                        worktypes={worktypeOptions}
                        loggedInUser={loggedInUser}
                        showSlotIntervalOnChart={showSlotIntervalOnChart}
                    ></DashboardOSP>
                )}
                {loggedInUser && loggedInUser.role.callmart_tef && (
                    <DashboardTEF
                        dateFrom={selectedStartDate}
                        dateTo={selectedEndDate}
                        worktype={selectedWorktype}
                        showSlotIntervalOnChart={showSlotIntervalOnChart}
                    ></DashboardTEF>
                )}
            </Grid>
        </Container>
    ) : null;
}

export default Dashboard;
