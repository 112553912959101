import { Grid } from '@mui/material';

const GridContainer = ({ children, sx }) => {
    return (
        <Grid container spacing={1} rowGap={2} sx={sx}>
            {children}
        </Grid>
    );
};

export default GridContainer;
