import { useQuery } from '@tanstack/react-query';
import { createStrategicVolume, getStrategicVolumes, updateStrategicVolume } from '../../services/strategicVolume';
import { QueryKey } from '../../enums/QueryKey';
import { useUpdateRecord, useCreateRecord } from './crudOperations';
import { queryClient } from '../../services/queryClient';

export function useStrategicVolumes({ workingUnitLocation }) {
    const { data } = useQuery({
        queryKey: [QueryKey.StrategicVolumes, { workingUnitLocation }],
        queryFn: () => getStrategicVolumes({ workingUnitLocation }),
        initialData: [],
    });

    return [data];
}

export function useCreateStrategicVolume({ onSettled, workingUnitLocation }) {
    return useCreateRecord({
        createRecordFn: createStrategicVolume,
        fetchRecordsQueryKey: QueryKey.StrategicVolumes,
        recordName: 'Strategic Volume',
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.StrategicVolumes, { workingUnitLocation }]);
        },
        onSettled,
    });
}

export function useUpdateStrategicVolume({ onSettled, workingUnitLocation }) {
    return useUpdateRecord({
        updateRecordFn: updateStrategicVolume,
        fetchRecordsQueryKey: [QueryKey.StrategicVolumes, { workingUnitLocation }],
        recordName: 'Strategic Volume',
        onSettled,
    });
}
