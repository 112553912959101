import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import { removeStorage, setCookie } from '../../utils/index';

const LogoutButton = () => {
    const { logout } = useAuth0();

    const handleLogout = () => {
        removeStorage('user');
        setCookie('worktype', 0, 0);
        setCookie('location', 0, 0);
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    return (
        <Button color="inherit" variant="outlined" onClick={handleLogout}>
            Log Out
        </Button>
    );
};

export default LogoutButton;
