import { CardContent, CardActions, Card, Popover, Avatar } from '@mui/material';
import callmartLogo from '../assets/Callmart logo.png';
import { Link } from 'react-router-dom';
import packageJson from '../../package.json';
import { styled } from '@mui/system';
const StyledLink = styled(Link)({
    color: 'var(--darkGrey)',
    fontSize: '0.8em',
    marginRight: '2px',
});

const CustomLink = ({ to, target, children }) => (
    <StyledLink component={Link} to={to} target={target}>
        {children}
    </StyledLink>
);

function VersionNotesImpressumPopover({ anchorEl, onClose }) {
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const env = process.env.REACT_APP_NODE_ENV;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Card sx={{ overflow: 'auto', minWidth: '360px !important', textAlign: 'left' }}>
                <CardContent>
                    <Avatar
                        alt="Callmart"
                        src={callmartLogo}
                        style={{ display: 'inline-block', verticalAlign: 'middle' }}
                    />
                    <span
                        style={{
                            fontSize: '1.5em',
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            paddingLeft: '8px',
                        }}
                    >
                        {' '}
                        Telefonica Callmart
                    </span>
                    <h3 style={{ marginBottom: '0px' }}>Neue Funktionen dieser Version:</h3>
                    <li>WFM Upload</li>
                    <li>Settings für Tasks, Capacities, Channels</li>
                    <li>Strategisches Volumen + Freigabe</li>
                    <li>Excel Upload für Potenziale</li>
                    <li>Archivierung für Deltas älter 6 Monate</li>
                    <li>Archivierung für Potenziale älter 12 Monate</li>

                    <span style={{ color: 'var(--darkGrey)' }}>
                        <p style={{ marginBottom: '0' }}>
                            Version: {packageJson.version}:{env}
                        </p>
                        <span>Problems? Contact: </span>
                        <a href="mailto:support.tef@unetiq.com" style={{ color: 'var(--primary)' }}>
                            support.tef@unetiq.com
                        </a>
                        <br />
                    </span>
                </CardContent>
                <CardActions>
                    <span style={{ color: 'var(--darkGrey)', fontSize: '1.2em' }}>
                        {new Date().getFullYear()} — <strong>&#169;Unetiq</strong>
                    </span>
                    <span style={{ textAlign: 'right !important', float: 'right' }}>
                        <CustomLink to="/impressum" target="_blank">
                            Impressum
                        </CustomLink>
                        <CustomLink to="/dataprivacy" target="_blank">
                            Datenschutzerklärung
                        </CustomLink>
                    </span>
                </CardActions>
            </Card>
        </Popover>
    );
}

export default VersionNotesImpressumPopover;
