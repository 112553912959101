import PhoneIcon from '@mui/icons-material/Phone';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import { Typography } from '@mui/material';

export const createMapWithTableRows = (bookingsList, field) => {
    let rowsMap = new Map();
    rowsMap.set('latestBookings', {});
    rowsMap.set('difference', {});
    rowsMap = addBookingsInMap(bookingsList, rowsMap, field);

    return rowsMap;
};

export const addBookingsInMap = (list, rowsMap, field) => {
    list.forEach((item) => {
        rowsMap.set('latestBookings', {
            ...rowsMap.get('latestBookings'),
            [`${[field]}${item[field]}`]: item.latestBookings,
        });
        rowsMap.set('difference', {
            ...rowsMap.get('difference'),
            [`${[field]}${item[field]}`]: item.difference,
        });
    });

    return rowsMap;
};

export const combineLatestAndOldestBookings = (latestBookedBookingsList, oldestBookedBookingsList, field) => {
    let combinedResult = [];
    if (latestBookedBookingsList.length > 0) {
        latestBookedBookingsList.map((latestBooking) => {
            const oldestBookedBooking = oldestBookedBookingsList.find(
                (oldestBooking) => oldestBooking[field] === latestBooking[field]
            );

            const latestBookingValue = parseInt(latestBooking?.bookedBookings) || 0;
            const oldestBookingValue = parseInt(oldestBookedBooking?.bookedBookings) || 0;
            const difference = latestBookingValue - oldestBookingValue;

            combinedResult.push({
                latestBookings: latestBookingValue,
                oldestBookings: oldestBookingValue,
                difference,
                ...(latestBooking && { [field]: latestBooking[field] }),
            });

            return combinedResult;
        });
    }

    return combinedResult;
};

export const createColumnDefs = (items, options) => {
    const columns = [
        {
            field: 'bookingType',
            headerName: '',
            maxWidth: 80,
            cellClass: 'grid-cell-centered',
            pinned: 'left',
            cellRenderer: (params) => {
                if (params.value === 'latestBookings') {
                    return (
                        <span>
                            <PhoneIcon style={{ verticalAlign: 'middle' }}></PhoneIcon>
                        </span>
                    );
                } else if (params.value === 'difference') {
                    return (
                        <span>
                            <PhoneDisabledIcon
                                style={{ transform: 'rotate(90deg)', verticalAlign: 'middle' }}
                            ></PhoneDisabledIcon>
                        </span>
                    );
                }
            },
        },
    ];

    items.forEach((item) => {
        columns.push({
            field: options.fieldPrefix + item[options.idField],
            headerName: options.getHeaderName(item),
            minWidth: 120,
            cellClass: 'grid-cell-centered',
            cellStyle: {
                justifyContent: 'center',
            },
            cellRenderer: (params) => {
                let result =
                    params.data.bookingType === 'latestBookings' ? (
                        <>
                            <Typography
                                align="left"
                                variant="h5"
                                style={{ color: 'var(--primary)', verticalAlign: 'middle' }}
                            >
                                {params.value}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography align="left" variant="subtitle2" style={{ color: 'gray' }}>
                                Änderungen
                            </Typography>
                            <Typography align="left" variant="h5" style={{ color: 'gray', verticalAlign: 'middle' }}>
                                {params.value}
                            </Typography>
                        </>
                    );
                return result;
            },
        });
    });

    return columns;
};
