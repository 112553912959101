import { useQuery } from '@tanstack/react-query';
import { getWorkingUnitLocations } from '../../services/workingUnitLocation';
import { QueryKey } from '../../enums/QueryKey';

export function useWorkingUnitLocations({ location, hasMatchingOfferedCluster = false } = {}) {
    const { data: workingUnitLocations } = useQuery({
        queryKey: [QueryKey.WorkingUnitLocations, { ...(location && { location }), hasMatchingOfferedCluster }],
        queryFn: () => getWorkingUnitLocations({ location, hasMatchingOfferedCluster }),
        initialData: [],
        enabled: !!location,
    });

    return [workingUnitLocations];
}
