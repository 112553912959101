import DownloadExcelButton from '../Buttons/DownloadExcelButton';
import { usePreferredUserLocation } from '../../hooks';
import dayjs from 'dayjs';
import { getAllDatesInAMonth, getAllTimeSlotsInADay } from '../../utils/dateUtils';
import { getTotalsWithWorkingUnits } from '../../services/totals';
import { groupBy } from 'lodash';
import { STATIC_EXCEL_COLUMN_NAMES } from './helpers/utils';
import { getTotalsTypeByValue } from '../../enums/TotalsType';

const getColumnNames = (daysInMonth) => {
    const columnNames = [...STATIC_EXCEL_COLUMN_NAMES];

    for (const day of daysInMonth) {
        columnNames.push(dayjs(day).format('DD.MM.YYYY'));
    }
    return columnNames;
};

const OSPExportTotalsButton = ({ date, location: selectedLocation, totalsType }) => {
    const [location] = usePreferredUserLocation(selectedLocation);

    const daysInMonth = getAllDatesInAMonth(date);
    const columnNames = getColumnNames(daysInMonth);
    const timeSlotsInADay = getAllTimeSlotsInADay();

    const fileName = `Totals (${getTotalsTypeByValue(totalsType).nameOSP})_${location?.city}_${dayjs(date).format(
        'MM.YYYY'
    )}.xlsx`;

    const getXLSXSheets = async () => {
        const { workingUnits, totals } = await getTotalsWithWorkingUnits({
            dateFrom: dayjs(date).startOf('month'),
            dateTo: dayjs(date).endOf('month'),
            location: selectedLocation,
            totalsType,
        });

        const totalsByWorkingUnit = groupBy(totals, 'workingUnitLocationId');
        const sheetsData = [];
        for (let i = 0; i < workingUnits.length; i++) {
            const workingUnit = workingUnits[i];
            const sheetData = [];
            const totals = totalsByWorkingUnit[workingUnit.workingUnitLocationId];
            for (const timeSlot of timeSlotsInADay) {
                const row = [
                    workingUnit.id,
                    workingUnit.workingUnitLocationId,
                    workingUnit.worktypeId,
                    workingUnit.channelId,
                    workingUnit.taskId,
                    location?.code,
                    location?.city,
                    workingUnit.worktype,
                    workingUnit.channel,
                    workingUnit.task,
                    timeSlot,
                ];
                const totalsInTimeSlot = totals
                    ? totals.filter((t) => t.offeredReport.slotId.split('-')[1] === timeSlot + ':00')
                    : [];

                for (const day of daysInMonth) {
                    const total = totalsInTimeSlot.find(
                        (t) => t.offeredReport.slotId.split('-')[0] === day.format('DD.MM.YYYY')
                    );

                    row.push(total ? total.total : '');
                }
                sheetData.push(row);
            }

            sheetsData.push({
                sheetName: `${i}-${workingUnit.worktype}-${workingUnit.channel?.slice(0, 2)}-${workingUnit.task?.slice(
                    0,
                    2
                )}`,
                sheetData,
            });
        }

        return sheetsData;
    };

    return (
        <DownloadExcelButton
            title="Totals"
            fileName={fileName}
            location={selectedLocation}
            columnNames={columnNames}
            getSheetsData={() => getXLSXSheets()}
            buttonText=""
            showFileIcon={true}
            columnsToHide={[0, 1, 2, 3, 4]}
        ></DownloadExcelButton>
    );
};

export default OSPExportTotalsButton;
