export const QueryKey = {
    AllLocationsFromHEMT: 'all-locations-from-hemt',
    Worktypes: 'worktypes',
    DistinctLocations: 'distinct-locations',
    Channels: 'channels',
    Tasks: 'tasks',
    CapacityTypes: 'capacity-types',
    Capacities: 'capacities',
    AllHEMTLocationsWithWorktypes: 'all-HEMT-locations-with-worktypes',
    WorktypeLocations: 'worktype-locations',
    LastUploadDate: 'last-upload-date',
    AllSlotDaysInMonthAndYear: 'all-slot-days-in-month-and-year',
    AllAvailableBookingsByDateAndWorktype: 'all-available-bookings-by-date-and-worktype',
    LatestLocationDeltas: 'latest-location-deltas',
    AdditionalWorktypeChannelsForLocation: 'additional-worktype-channels-for-location',
    User: 'user',
    AvailableAndBookedBookings: 'available-and-booked-bookings',
    WFMs: 'wfms',
    OfferedClusters: 'offered-clusters',
    LatestOfferedReports: 'latest-offered-reports',
    WorkingHours: 'working-hours',
    OfferedReportsSummary: 'offered-reports-summary',
    Reductions: 'reductions',
    WorkingUnits: 'working-units',
    WorkingUnitLocations: 'working-unit-locations',
    BiddingCalculated: 'bidding-calculated',
    BiddingCalculatedResults: 'bidding-calculated-results',
    LastBiddingCalculationDate: 'last-bidding-calculation-date',
    StrategicVolumeReport: 'strategic-volume-report',
    StrategicVolumeAndCapacityReportComparison: 'strategic-volume-and-capacity-report-comparison',
    LastUploadUserAndDate: 'last-upload-user-and-date',
    LatestCapacityReportsByWorktypes: 'latest-capacity-reports-by-worktypes',
    CapacityReport: 'capacity-report',
    PerformedCapacityReport: 'performed-capacity-report',
    TotalForecastDeltasByDateRangeAndWorktype: 'total-forecast-deltas-by-daterange-and-worktype',
    TotalBookedBookingsByDateRangeAndWorktype: 'total-booked-bookings-by-daterange-and-worktype',
    AllDistinctLocations: 'all-distinct-locations',
    LocationsByWorktype: 'locations-by-worktype',
    AllBookedBookingsByDateRangeGroupByDateAndLocation: 'all-booked-bookings-by-daterange-groupby-date-and-location',
    OldestAndLatestBookedBookingsByDateAndWorktype: 'oldest-and-latest-booked-bookings-by-date-and-worktype',
    AllBookedBookingsByDateRangeWorktypeAndLocation: 'all-booked-bookings-by-daterange-worktype-and-location',
    AllForecastDeltasByDateRangeWorktypeAndLocation: 'all-forecast-deltas-by-daterange-worktype-and-location',
    TotalBookedBookingsByDateAndLocationGroupByWorktype: 'total-booked-bookings-by-date-and-location-groupby-worktype',
    TotalBookedBookingsByDateRangeAndWorktypeAndLocation:
        'total-booked-bookings-by-daterange-and-worktype-and-location',
    TotalForecastDeltasByDateRangeAndWorktypeAndLocation:
        'total-forecast-deltas-by-daterange-and-worktype-and-location',
    AllBookedBookingsByDateRangeAndWorktype: 'all-booked-bookings-by-daterange-and-worktype',
    AllForecastDeltasByDateRangeAndWorktype: 'all-forecast-deltas-by-daterange-and-worktype',
    SumOfBookedBookingsByDateAndWorktypeGroupByLocation: 'sum-of-booked-bookings-by-date-and-worktype-groupby-location',
    TotalEventsForAllLocations: 'total-events-for-all-locations',
    LastBiddingCalculatedTotalsReleaseDate: 'last-bidding-calculated-totals-release-date',
    StrategicVolumeReports: 'strategic-volume-reports',
    CorrectedTotalsSum: 'corrected-totals-sum',
    LastCorrectedTotalsUploadDate: 'last-corrected-totals-upload-date',
    CorrectedTotals: 'corrected-totals',
    Totals: 'totals',
    CorrectedResults: 'corrected-results',
    ReleasedBiddingCalculatedTotalsSum: 'released-bidding-calculated-totals-sum',
    LastCorrectedTotalsReleaseDate: 'last-corrected-totals-release-date',
    OfferedReportsExport: 'offered-reports-export',
    TotalsExport: 'totals-export',
    StrategicVolumes: 'strategic-volumes',
    HEMTUsers: 'hemt-users',
    CompletedCalculations: 'completed-calculations',
    PublicHolidays: 'public-holidays',
};
