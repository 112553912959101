import './App.css';
import NavBar from './components/Layout/NavBar';
import UserContext from './store/User/UserContext';
import { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { setStorage } from './utils/index';
import Loader from './components/Loaders/Loader';
import MessageContextProvider from './store/Message/MessageContextProvider';
import AlertNotification from './components/UI/Dialogs/AlertNotification';
import { isOSPUser } from './utils/authUtils';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/de';
import { SocketProvider } from './store/SocketContext';

dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.locale('de');

const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#0066FF',
        },
        secondary: {
            main: '#3f51b5',
        },
        info: {
            main: '#17183B',
        },
        error: {
            main: '#960200',
        },
        warning: {
            main: '#FFD046',
        },
    },
});

function App() {
    const [loggedInUser, setLoggedInUser] = useState(null);
    const { user: authUser, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const user = useContext(UserContext);

    const { isLoading } = useAuth0();
    useEffect(() => {
        async function getUserData() {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            });

            const user = await axios.get('https://telefonica-hemt.unetiq.com/api/user/mail', {
                params: { email: authUser.email },
                headers: { Authorization: `Bearer ${token}` },
            });

            return {
                ...user.data,
                token,
            };
        }

        if (isAuthenticated) {
            getUserData().then((response) => {
                const user = response;
                user.isCallmartUser = user.role.callmart_tef || user.role.callmart_osp;
                user.isOSPUser = isOSPUser(user);
                user.isTEFUser = user.role.callmart_tef;
                setStorage('user', JSON.stringify(user));
                setLoggedInUser(user);
            });
        } else {
            setLoggedInUser(null);
        }
    }, [authUser, isAuthenticated, setLoggedInUser, getAccessTokenSilently]);

    if (isLoading) {
        return <Loader />;
    }

    if (user === undefined) {
        return null;
    }

    return (
        <ThemeProvider theme={theme}>
            <MessageContextProvider>
                <SocketProvider>
                    <UserContext.Provider value={loggedInUser}>
                        <NavBar />
                        <AlertNotification anchorOrigin={{ vertical: 'top', horizontal: 'center' }}></AlertNotification>
                    </UserContext.Provider>
                </SocketProvider>
            </MessageContextProvider>
        </ThemeProvider>
    );
}

export default App;
