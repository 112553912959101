import { Button } from '@mui/material';
import CompleteCalculationDialog from './CompleteCalculationDialog';
import { useState } from 'react';

const CompleteCalculationButton = ({ dateFilter, workingUnitLocationFilter }) => {
    const [showCompleteCalculationDialog, setShowCompleteCalculationDialog] = useState(false);
    return (
        <>
            <Button onClick={() => setShowCompleteCalculationDialog(true)} variant="contained">
                Plannung abschließen
            </Button>
            {showCompleteCalculationDialog && (
                <CompleteCalculationDialog
                    open={showCompleteCalculationDialog}
                    onClose={() => setShowCompleteCalculationDialog(false)}
                    dateFilter={dateFilter}
                    workingUnitLocationFilter={workingUnitLocationFilter}
                ></CompleteCalculationDialog>
            )}
        </>
    );
};

export default CompleteCalculationButton;
