import { Grid } from '@mui/material';
import LocationSelect from './LocationSelect';
import WorkingUnitSelect from './WorkingUnitSelect';
import { useWorkingUnitLocations } from '../../hooks';
import { useEffect } from 'react';

const WorkingUnitLocationSelect = ({
    locationOptions,
    selectedLocation,
    onSelectedLocationChange,
    selectedWorkingUnitLocation,
    onSelectedWorkingUnitLocationChange,
    workingUnitHasMatchingOfferedCluster = false,
}) => {
    const [workingUnitLocations] = useWorkingUnitLocations({
        location: selectedLocation,
        hasMatchingOfferedCluster: workingUnitHasMatchingOfferedCluster,
    });
    const workingUnitOptions = workingUnitLocations.map((workingUnitLocation) => ({
        id: workingUnitLocation.workingUnit.id,
        workingUnitLocationId: workingUnitLocation.id,
        worktype: workingUnitLocation.workingUnit.worktype,
        channel: workingUnitLocation.workingUnit.channel,
        task: workingUnitLocation.workingUnit.task,
    }));

    useEffect(() => {
        if (
            workingUnitOptions.length &&
            (!selectedWorkingUnitLocation ||
                !workingUnitOptions.find((wu) => wu.workingUnitLocationId === selectedWorkingUnitLocation))
        ) {
            onSelectedWorkingUnitLocationChange(workingUnitOptions[0].workingUnitLocationId);
        }
    }, [selectedWorkingUnitLocation, workingUnitOptions, onSelectedWorkingUnitLocationChange]);

    return (
        <>
            <Grid item xs={3} xl={2}>
                <LocationSelect
                    selectedLocation={selectedLocation}
                    locationOptions={locationOptions}
                    onChange={onSelectedLocationChange}
                ></LocationSelect>
            </Grid>
            <Grid item xs={3} xl={2}>
                <WorkingUnitSelect
                    valueProp="workingUnitLocationId"
                    selectedWorkingUnit={selectedWorkingUnitLocation}
                    onChange={onSelectedWorkingUnitLocationChange}
                    workingUnitOptions={workingUnitOptions}
                ></WorkingUnitSelect>
            </Grid>
        </>
    );
};

export default WorkingUnitLocationSelect;
