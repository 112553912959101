import { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import Container from '../components/Layout/Container';
import { getAllBookingDeltaByBookingId } from '../services/bookingDelta';
import DailyOSPBookingsTotals from '../components/DailyOSPBookings/DailyOSPBookingsTotals';
import CustomDatePicker from '../components/UI/CustomDatePicker';
import DailyOSPBookingsHistory from '../components/DailyOSPBookings/DailyOSPBookingsHistory';
import { createTableRows } from '../utils/agGridUtils';
import CardPopover from '../components/UI/CardPopover';
import { useCellPopover } from '../hooks/useCellPopover';
import {
    useCookieWorktype,
    useWorktypes,
    useHEMTLocations,
    useTotalForecastDeltasByDateRangeAndWorktype,
    useTotalBookedBookingsByDateRangeAndWorktype,
    useAllDistinctLocations,
    useLocationsByWorktype,
    useAllAvailableBookingsByDateAndWorktype,
    useOldestAndLatestBookedBookingsByDateAndWorktype,
    useAllBookedBookingsByDateRangeGroupByDateAndLocation,
} from '../hooks';
import { WorktypeSelectOptions } from '../enums/WorktypeSelectOptions';
import WorktypeSelect from '../components/Selects/WorktypeSelect';
import { setUploadedMonthAndYearToDate } from '../utils/dateUtils';
import dayjs from 'dayjs';
import TableGridItem from '../components/UI/TableGridItem';
import { createColumnDefs, createMapWithTableRows } from '../components/DailyOSPBookings/helpers/utils';
import { useSocket } from '../store/SocketContext';
import { SocketEvents } from '../enums/SocketEvents';

const DailyOSPBookings = () => {
    const socket = useSocket();
    const gridRef = useRef(null);

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const dateFrom = dayjs.utc(selectedDate).startOf('day');
    const dateTo = dayjs.utc(selectedDate).endOf('day');

    const [worktypeOptions] = useWorktypes({ prependAllWorktypesOption: true });
    const [selectedWorktype, setSelectedWorktype] = useCookieWorktype(worktypeOptions);

    const { showPopover, hoveredCell, hoveredCellHistoryList, popoverOpen, popoverClose } = useCellPopover();

    const forecastDeltasTotal = useTotalForecastDeltasByDateRangeAndWorktype({
        dateFrom,
        dateTo,
        worktype: selectedWorktype,
    });
    const totalForecastDeltas = forecastDeltasTotal?.total || 0;
    const bookedBookingsTotal = useTotalBookedBookingsByDateRangeAndWorktype({
        dateFrom,
        dateTo,
        worktype: selectedWorktype,
    });
    const totalBookedBookings = bookedBookingsTotal?.total || 0;
    const totalAvailableBookings = totalForecastDeltas - totalBookedBookings;

    const allLocationsFromHEMT = useHEMTLocations();
    const allDistinctLocations = useAllDistinctLocations();
    const locationsByWorktype = useLocationsByWorktype(selectedWorktype);
    const locationsForColumnDefs =
        selectedWorktype === WorktypeSelectOptions.ALL_WORKTYPES.value ? allDistinctLocations : locationsByWorktype;
    const columnDefs =
        allLocationsFromHEMT && locationsForColumnDefs?.length > 0
            ? createColumnDefs(locationsForColumnDefs, allLocationsFromHEMT)
            : [];

    const { data: availableBookings } = useAllAvailableBookingsByDateAndWorktype({
        dateFrom: dayjs.utc(selectedDate).startOf('day'),
        dateTo: dayjs.utc(selectedDate).endOf('day'),
        worktype: selectedWorktype,
    });

    const bookedBookingsForAllWorktypes = useAllBookedBookingsByDateRangeGroupByDateAndLocation({
        dateFrom,
        dateTo,
    });
    const bookedBookingsForSelectedWorktype = useOldestAndLatestBookedBookingsByDateAndWorktype({
        dateFrom,
        dateTo,
        worktype: selectedWorktype,
    });
    const bookedBookings =
        selectedWorktype === WorktypeSelectOptions.ALL_WORKTYPES.value
            ? bookedBookingsForAllWorktypes
            : bookedBookingsForSelectedWorktype;
    const tableRows =
        availableBookings?.length > 0 || bookedBookings?.length > 0
            ? createTableRows(createMapWithTableRows(availableBookings, bookedBookings), 'slotTime')
            : [];

    const cellHovered = async (params) => {
        if (params.column.colId.startsWith('oldestBookedBookingValueLocationId')) {
            const locationId = params.column.colId.replace('oldestBookedBookingValueLocationId', '');
            if (params.data[`oldestBookedBookingValueLocationId${locationId}`]) {
                const bookingDeltasList = await getAllBookingDeltaByBookingId(
                    params.data[`bookingIdLocationId${locationId}`]
                );

                popoverOpen(true, params.event.target, bookingDeltasList);
            }
        }
    };

    const handleDatePickerChange = (newValue) => {
        setSelectedDate(newValue);
    };

    useEffect(() => {
        socket.on(SocketEvents.uploadedForecastDelta, ({ monthForUploading }) => {
            const uploadedDate = setUploadedMonthAndYearToDate(dayjs(), monthForUploading);
            setSelectedDate(uploadedDate);
            setSelectedWorktype(-1);
        });

        socket.on(SocketEvents.createdBookingDelta, ({ monthForCreatedBookings, worktypeForCreatedBookings }) => {
            const uploadedDate = dayjs().month(monthForCreatedBookings - 1);
            setSelectedDate(uploadedDate);
            setSelectedWorktype(worktypeForCreatedBookings);
        });

        return () => {
            socket.removeAllListeners();
        };
    }, [setSelectedWorktype, socket]);

    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={12} md={3} lg={2}>
                    <CustomDatePicker
                        date={selectedDate}
                        inputFormat="DD.MM.YYYY"
                        mask="__.__.____"
                        onChange={handleDatePickerChange}
                    ></CustomDatePicker>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <WorktypeSelect
                        selectedWorktype={selectedWorktype}
                        worktypeOptions={worktypeOptions}
                        onChange={setSelectedWorktype}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={7}>
                    <DailyOSPBookingsTotals
                        bookedCalls={totalBookedBookings}
                        availableCalls={totalAvailableBookings}
                        forecastCalls={totalForecastDeltas}
                    />
                </Grid>
                <TableGridItem
                    ref={gridRef}
                    headerHeight={'0'}
                    groupHeaderHeight={'50'}
                    rowData={tableRows}
                    defaultColDef={{
                        editable: false,
                        filter: false,
                        sortable: false,
                        suppressHeaderMenuButton: true,
                    }}
                    columnDefs={columnDefs}
                    onCellMouseOver={cellHovered}
                ></TableGridItem>
            </Grid>
            {showPopover && (
                <CardPopover anchorEl={hoveredCell} onClose={popoverClose}>
                    <DailyOSPBookingsHistory bookingDeltasHistory={hoveredCellHistoryList} />
                </CardPopover>
            )}
        </Container>
    );
};

export default DailyOSPBookings;
