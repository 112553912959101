import Table from '../AgGrid';

function DashboardTable({ tableRows, columnDefs }) {
    return (
        <Table
            rowData={tableRows}
            defaultColDef={{
                editable: false,
                filter: false,
                sortable: true,
                suppressHeaderMenuButton: true,
            }}
            columnDefs={columnDefs}
            rowHeight={60}
            height={'200px'}
        ></Table>
    );
}

export default DashboardTable;
