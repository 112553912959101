import { Stack, Typography } from '@mui/material';

const styles = {
    container: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
    textCenter: {
        textAlign: 'center !important',
    },
};

const DailyOSPBookingsTotals = ({ bookedCalls, availableCalls, forecastCalls }) => {
    return (
        <Stack direction="row">
            <div style={styles.container}>
                <Typography variant="body1">Gebuchte Calls</Typography>
                <Typography variant="h5" sx={styles.textCenter}>
                    {bookedCalls}
                </Typography>
            </div>
            <div style={styles.container}>
                <Typography variant="body1">Verbleibende Calls</Typography>
                <Typography variant="h5" sx={styles.textCenter}>
                    {availableCalls}
                </Typography>
            </div>
            <div style={styles.container}>
                <Typography variant="body1">Forecast Calls</Typography>
                <Typography variant="h5" sx={styles.textCenter}>
                    {forecastCalls}
                </Typography>
            </div>
        </Stack>
    );
};

export default DailyOSPBookingsTotals;
