import { MenuItem } from '@mui/material';
import CustomSelect from '../UI/CustomSelect';

const WorkingUnitSelect = ({ valueProp, selectedWorkingUnit, onChange, workingUnitOptions, ...props }) => {
    return (
        <CustomSelect
            selectedValue={selectedWorkingUnit}
            options={workingUnitOptions}
            onChange={onChange}
            label={'Working Unit'}
            {...props}
        >
            {workingUnitOptions &&
                workingUnitOptions.map((workingUnit) => (
                    <MenuItem value={workingUnit[valueProp]} key={workingUnit[valueProp]}>
                        <span className="notranslate">
                            {workingUnit?.worktype?.worktype} - {workingUnit?.channel?.name} -{workingUnit?.task?.name}
                        </span>
                    </MenuItem>
                ))}
        </CustomSelect>
    );
};

export default WorkingUnitSelect;
