import { RecordCreatedAt } from '../../enums/RecordCreatedAt';
import {
    useLastLocationDeltaUploadDate,
    useTotalBookedBookingsByDateRangeAndWorktypeAndLocation,
    useTotalForecastDeltasByDateRangeAndWorktypeAndLocation,
    useAllBookedBookingsByDateRangeWorktypeAndLocation,
    useAllForecastDeltasByDateRangeWorktypeAndLocation,
    useTotalBookedBookingsByDateAndLocationGroupByWorktype,
} from '../../hooks';
import { createTableRows, combineChartDataInOneArray } from '../../utils/agGridUtils';
import { Grid, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import DashboardTotals from './DashboardTotals';
import DashboardChart from './DashboardChart';
import DashboardTable from './DashboardTable';
import dayjs from 'dayjs';
import CircleIcon from '@mui/icons-material/Circle';
import { useLastCapacityReportUploadDatePerLocation } from '../../hooks/api/capacityReports';
import { createMapWithTableRows, combineLatestAndOldestBookings, createColumnDefs } from './helpers/utils';

function DashboardOSP({ dateFrom, dateTo, worktype, worktypes, loggedInUser, showSlotIntervalOnChart }) {
    const location = loggedInUser?.primaryLocation?.id;
    const loggedInUserLocations = loggedInUser?.id
        ? [loggedInUser.primaryLocation, ...loggedInUser.additionalLocations]
        : [];
    const loggedInUserLocationsIds = loggedInUserLocations.map((location) => location.id);

    // Last upload dates
    const latestLocationDeltaUploadDate = useLastLocationDeltaUploadDate(loggedInUser?.primaryLocation?.id);
    const [latestCapacityReportUploadDatesPerLocation] =
        useLastCapacityReportUploadDatePerLocation(loggedInUserLocationsIds);
    const latestCapacityReportUploadDates = latestCapacityReportUploadDatesPerLocation
        .map((capacityReport) => {
            const location = loggedInUserLocations.find((loc) => loc.id === capacityReport.locationId);
            return {
                city: location?.city,
                latestUpload: capacityReport.value ? dayjs(capacityReport.value).format('DD.MM.YYYY HH:mm') : '',
            };
        })
        .filter((location) => location.city);

    // Totals
    const totalForecastDeltas = useTotalForecastDeltasByDateRangeAndWorktypeAndLocation({
        dateFrom,
        dateTo,
        worktype,
        location,
    });
    const totalBookedBookings = useTotalBookedBookingsByDateRangeAndWorktypeAndLocation({
        dateFrom,
        dateTo,
        worktype,
        location,
        recordCreatedAt: RecordCreatedAt.LATEST,
    });
    const totalAvailableBookings = {
        positive: totalForecastDeltas.positive - totalBookedBookings.positive,
        negative: totalForecastDeltas.negative - totalBookedBookings.negative,
    };
    const totals = {
        totalForecastDeltas,
        totalBookedBookings,
        totalAvailableBookings,
    };

    // Chart data
    const [bookedCalls] = useAllBookedBookingsByDateRangeWorktypeAndLocation({ dateFrom, dateTo, worktype, location });
    const [forecastDeltaCalls] = useAllForecastDeltasByDateRangeWorktypeAndLocation({
        dateFrom,
        dateTo,
        worktype,
        location,
    });
    const chartData = combineChartDataInOneArray(bookedCalls, forecastDeltaCalls);

    // Column defs
    const columnDefs = createColumnDefs(worktypes, {
        fieldPrefix: 'worktypeId',
        idField: 'id',
        getHeaderName: (worktype) => worktype.worktype,
    });

    // Table data
    const [latesBookedBookings] = useTotalBookedBookingsByDateAndLocationGroupByWorktype({
        dateFrom,
        dateTo,
        location,
        recordCreatedAt: RecordCreatedAt.LATEST,
    });
    const [oldestBookedBookings] = useTotalBookedBookingsByDateAndLocationGroupByWorktype({
        dateFrom,
        dateTo,
        location,
        recordCreatedAt: RecordCreatedAt.OLDEST,
    });
    const bookings = combineLatestAndOldestBookings(latesBookedBookings, oldestBookedBookings, 'worktypeId');
    const tableRows = createTableRows(createMapWithTableRows(bookings, 'worktypeId'), 'bookingType');

    return (
        <>
            <Grid item xs={12} sm={12} md={7} lg={3}>
                <DashboardTotals totals={totals}></DashboardTotals>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5}>
                <DashboardChart chartData={chartData} showSlotInterval={showSlotIntervalOnChart}></DashboardChart>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2, mb: 2 }}>
                <DashboardTable tableRows={tableRows} columnDefs={columnDefs}></DashboardTable>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography>Letzte Delta Meldung: {latestLocationDeltaUploadDate}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography>Letzte Potenzial Meldung: </Typography>
                <List>
                    {latestCapacityReportUploadDates.map((latesData) => (
                        <ListItem key={latesData.city}>
                            <ListItemIcon>
                                <CircleIcon style={{ color: 'var(--primary)' }} />
                            </ListItemIcon>
                            <ListItemText primary={`${latesData.city}: ${latesData.latestUpload}`} />
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </>
    );
}

export default DashboardOSP;
