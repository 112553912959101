import { useQuery } from '@tanstack/react-query';
import {
    getAllDistinctLocations,
    getWorktypeLocations,
    getAllLocationsByWorktype,
} from '../../services/worktypeLocation';
import { QueryKey } from '../../enums/QueryKey';

export function useWorktypeLocations() {
    const { data } = useQuery({
        queryKey: [QueryKey.WorktypeLocations],
        queryFn: getWorktypeLocations,
        initialData: [],
    });

    return [data];
}

export function useAllDistinctLocations() {
    const { data } = useQuery({
        queryKey: [QueryKey.AllDistinctLocations],
        queryFn: getAllDistinctLocations,
        initialData: [],
    });

    return data;
}

export function useLocationsByWorktype(worktype) {
    const { data } = useQuery({
        queryKey: [QueryKey.LocationsByWorktype, { worktype }],
        queryFn: () => getAllLocationsByWorktype(worktype),
        enabled: !!worktype,
        initialData: [],
    });

    return data;
}
