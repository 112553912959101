import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';

const BASE = '/completed-calculation';

export const getCompletedCalculations = async ({ dateFrom, dateTo, workingUnitLocation } = {}) => {
    let url = `${BASE}`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo, workingUnitLocation });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const completeCalculation = async ({ dateFrom, dateTo, locations, workingUnits }) => {
    let url = `${BASE}`;
    return api.post(url, { dateFrom, dateTo, locations, workingUnits }, await setToken()).then((res) => res.data);
};
