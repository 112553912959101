import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useCustomStyles } from '../../../hooks';

function ResponsiveDialog({
    open,
    onClose,
    title,
    children,
    isConfirmationDialog,
    onConfirm,
    actionButtons,
    confirmButtonText,
    closeButtonText,
    ...props
}) {
    const theme = useTheme();
    const classes = useCustomStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        onClose();
    };

    return (
        open && (
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                {...props}
            >
                <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
                <DialogContent>{children}</DialogContent>
                {actionButtons ? (
                    actionButtons
                ) : (
                    <DialogActions sx={isConfirmationDialog ? classes.dialogActions : {}}>
                        {isConfirmationDialog && (
                            <>
                                <Button onClick={handleClose} variant="outlined">
                                    {closeButtonText || 'Abbrechen'}
                                </Button>
                                <Button onClick={onConfirm} variant="contained">
                                    {confirmButtonText || 'Bestätigen'}
                                </Button>
                            </>
                        )}
                        {!isConfirmationDialog && (
                            <Button onClick={handleClose} autoFocus>
                                Schließen
                            </Button>
                        )}
                    </DialogActions>
                )}
            </Dialog>
        )
    );
}

export default ResponsiveDialog;
