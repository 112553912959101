import { Grid } from '@mui/material';
import LocationAutocomplete from '../Inputs/Autocomplete/LocationAutocomplete';
import WorkingUnitAutocomplete from '../Inputs/Autocomplete/WorkingUnitAutocomplete';
import GridContainer from '../Layout/GridContainer';

const WorkingUnitLocationAutocomplete = ({
    locationOptions,
    selectedLocations,
    onSelectedLocationsChange,
    workingUnitOptions,
    selectedWorkingUnits,
    onSelectedWorkingUnitsChange,
    isLocationMultiSelect = false,
    isWorkingUnitMultiSelect = false,
}) => {
    return (
        <GridContainer>
            <Grid item xs={6}>
                <LocationAutocomplete
                    isMultiSelect={isLocationMultiSelect}
                    selectedLocation={selectedLocations}
                    locationOptions={locationOptions}
                    onChange={onSelectedLocationsChange}
                ></LocationAutocomplete>
            </Grid>
            <Grid item xs={6}>
                <WorkingUnitAutocomplete
                    isMultiSelect={isWorkingUnitMultiSelect}
                    selectedWorkingUnits={selectedWorkingUnits}
                    onChange={onSelectedWorkingUnitsChange}
                    workingUnitOptions={workingUnitOptions}
                ></WorkingUnitAutocomplete>
            </Grid>
        </GridContainer>
    );
};

export default WorkingUnitLocationAutocomplete;
