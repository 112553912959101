import { WorktypeSelectOptions } from '../../enums/WorktypeSelectOptions';
import { RecordCreatedAt } from '../../enums/RecordCreatedAt';
import {
    useTotalForecastDeltasByDateRangeAndWorktype,
    useTotalBookedBookingsByDateRangeAndWorktype,
    useAllBookedBookingsByDateRangeAndWorktype,
    useAllForecastDeltasByDateRangeAndWorktype,
    useSumOfBookedBookingsByDateAndWorktypeGroupByLocation,
    useHEMTLocations,
    useAllDistinctLocations,
    useLocationsByWorktype,
    useLatestLocationDeltaUploadDateForAllLocations,
} from '../../hooks';
import { createTableRows, combineChartDataInOneArray } from '../../utils/agGridUtils';
import { Grid } from '@mui/material';
import DashboardTotals from './DashboardTotals';
import DashboardChart from './DashboardChart';
import DashboardTable from './DashboardTable';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import DashboardTEFTableCard from './DashboardTEFTableCard';
import { useLastCapacityReportUploadDatePerLocation } from '../../hooks/api/capacityReports';
import { createMapWithTableRows, combineLatestAndOldestBookings, createColumnDefs } from './helpers/utils';
import { useTotalEventsForAllLocations } from '../../hooks/api/events';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const getTableRowsForCommonTables = (allLocationsFromHEMT, records, valueType = 'latestUpload') => {
    let rows = [];
    for (const hemtLocation of allLocationsFromHEMT) {
        const data = records.find((loc) => loc.locationId === hemtLocation.id);
        rows.push({
            cityCode: hemtLocation.code,
            cityName: hemtLocation.city,
            ...(valueType === 'latestUpload' && { latestUpload: data?.value ? dayjs(data.value) : null }),
            value: !data?.value
                ? ''
                : valueType === 'totalEvents'
                ? data.value
                : dayjs(data?.value).format('DD.MM.YYYY HH:mm'),
        });
    }

    rows = orderBy(
        rows,
        [
            (row) => {
                let valueToOrderBy = valueType === 'latestUpload' ? row.latestUpload : row.value;
                return valueToOrderBy || '';
            },
        ],
        ['desc']
    );

    return rows;
};

function DashboardTEF({ dateFrom, dateTo, worktype, showSlotIntervalOnChart }) {
    // Column defs
    const allLocationsFromHEMT = useHEMTLocations();
    const allDistinctLocations = useAllDistinctLocations();
    const locationsByWorktype = useLocationsByWorktype(worktype);
    const locationsForColumnDefs =
        worktype === WorktypeSelectOptions.ALL_WORKTYPES.value ? allDistinctLocations : locationsByWorktype;
    const columnDefs =
        allLocationsFromHEMT && locationsForColumnDefs?.length > 0
            ? createColumnDefs(locationsForColumnDefs, {
                  fieldPrefix: 'locationId',
                  idField: 'locationId',
                  getHeaderName: (location) => {
                      let locationsFromHEMT = allLocationsFromHEMT.find((loc) => loc.id === location.locationId);
                      return locationsFromHEMT ? locationsFromHEMT.city : location.id;
                  },
              })
            : [];

    // Totals
    const totalForecastDeltas = useTotalForecastDeltasByDateRangeAndWorktype({
        dateFrom,
        dateTo,
        worktype,
    });
    const totalBookedBookings = useTotalBookedBookingsByDateRangeAndWorktype({
        dateFrom,
        dateTo,
        worktype,
    });
    const totals = {
        totalForecastDeltas,
        totalBookedBookings,
        totalAvailableBookings: {
            positive: (totalForecastDeltas?.positive || 0) - (totalBookedBookings?.positive || 0),
            negative: (totalForecastDeltas?.negative || 0) - (totalBookedBookings?.negative || 0),
        },
    };

    // Chart data
    const [bookedCalls] = useAllBookedBookingsByDateRangeAndWorktype({ dateFrom, dateTo, worktype });
    const [forecastDeltaCalls] = useAllForecastDeltasByDateRangeAndWorktype({
        dateFrom,
        dateTo,
        worktype,
    });
    const chartData = combineChartDataInOneArray(bookedCalls, forecastDeltaCalls);

    // Table data
    const [latestBookedBookings] = useSumOfBookedBookingsByDateAndWorktypeGroupByLocation({
        dateFrom,
        dateTo,
        worktype,
        recordCreatedAt: RecordCreatedAt.LATEST,
    });
    const [oldestBookedBooking] = useSumOfBookedBookingsByDateAndWorktypeGroupByLocation({
        dateFrom,
        dateTo,
        worktype,
        recordCreatedAt: RecordCreatedAt.OLDEST,
    });
    const bookings = combineLatestAndOldestBookings(latestBookedBookings, oldestBookedBooking, 'locationId');
    const tableRows = createTableRows(createMapWithTableRows(bookings, 'locationId'), 'bookingType');

    // Last upload and total events
    const tefTablesCommonColumnDefs = [
        {
            field: 'city',
            headerName: 'Standort',
            valueGetter: (params) => `${params.data.cityName} - ${params.data.cityCode}`,
        },
        {
            field: 'value',
            headerName: 'Letzte Meldung',
        },
    ];
    const [latestCapacityReportUploadDatesPerLocation] = useLastCapacityReportUploadDatePerLocation();
    const latestCapacityReportUploadRows = allLocationsFromHEMT
        ? getTableRowsForCommonTables(allLocationsFromHEMT, latestCapacityReportUploadDatesPerLocation, 'latestUpload')
        : [];

    const [latestLocationDeltaUploadDatesPerLocation] = useLatestLocationDeltaUploadDateForAllLocations();
    const latestLocationDeltaUploadRows = allLocationsFromHEMT
        ? getTableRowsForCommonTables(allLocationsFromHEMT, latestLocationDeltaUploadDatesPerLocation, 'latestUpload')
        : [];
    const [totalEventsPerLocation] = useTotalEventsForAllLocations();
    const totalEventsRows = allLocationsFromHEMT
        ? getTableRowsForCommonTables(allLocationsFromHEMT, totalEventsPerLocation, 'totalEvents')
        : [];

    return (
        <>
            <Grid item xs={12} sm={12} md={7} lg={3}>
                <DashboardTotals totals={totals}></DashboardTotals>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5}>
                <DashboardChart chartData={chartData} showSlotInterval={showSlotIntervalOnChart}></DashboardChart>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2, mb: 2 }}>
                <DashboardTable tableRows={tableRows} columnDefs={columnDefs}></DashboardTable>
            </Grid>
            <DashboardTEFTableCard
                title="Deltameldungen"
                tableRows={latestLocationDeltaUploadRows}
                columnDefs={tefTablesCommonColumnDefs}
            ></DashboardTEFTableCard>
            <DashboardTEFTableCard
                title="Potenzialmeldungen"
                tableRows={latestCapacityReportUploadRows}
                columnDefs={tefTablesCommonColumnDefs}
            ></DashboardTEFTableCard>
            <DashboardTEFTableCard
                title="Events"
                tableRows={totalEventsRows}
                columnDefs={[tefTablesCommonColumnDefs[0], { field: 'value', headerName: 'Total' }]}
            ></DashboardTEFTableCard>
        </>
    );
}

export default DashboardTEF;
