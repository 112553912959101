export const useCustomStyles = () => {
    return {
        btnRightAligned: {
            textAlign: 'right',
        },
        tableGridItem: {
            marginTop: 1,
        },
        containerOpacity: {
            opacity: 0.5,
        },
        availableBookingsHeader: {
            color: 'var(--primary)',
            opacity: 0.5,
            justifyContent: 'center',
            width: '15px !important',
            height: '15px !important',
            fontWeight: 'bold',
        },
        bookedBookingsHeader: {
            color: 'var(--primary)',
            justifyContent: 'center',
            width: '15px !important',
            height: '15px !important',
            fontWeight: 'bold',
        },
        contentEnd: {
            justifyContent: 'end',
        },
        dialogActions: {
            justifyContent: 'space-between',
            paddingLeft: '24px',
            paddingRight: '24px',
        },
        marginBottom: {
            marginBottom: 3,
        },
        error: {
            color: 'var(--error) !important',
        },
        marginRight: {
            marginRight: 1,
        },
        marginTop: {
            marginTop: 1,
        },
    };
};
