import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../enums/QueryKey';
import dayjs from 'dayjs';
import { getCompletedCalculations } from '../../services/completedCalculation';

export const getQueryKey = (filterParams) => {
    const { date, workingUnitLocation } = filterParams;

    const dateFrom = dayjs.utc(date).startOf('month');
    const dateTo = dayjs.utc(date).endOf('month');

    return [
        QueryKey.CompletedCalculations,
        {
            dateFrom,
            dateTo,
            workingUnitLocation,
        },
    ];
};

export function useCompletedCalculations({ date, workingUnitLocation }) {
    const dateFrom = dayjs.utc(date).startOf('month');
    const dateTo = dayjs.utc(date).endOf('month');

    const { data } = useQuery({
        queryKey: getQueryKey({ date, workingUnitLocation }),
        queryFn: () => getCompletedCalculations({ dateFrom, dateTo, workingUnitLocation }),
        initialData: [],
        enabled: !!date,
    });

    return [data];
}
