import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../enums/QueryKey';
import dayjs from 'dayjs';
import { getTotals, getTotalsExportData } from '../../services/totals';
import { getStartAndEndOfAMonth } from '../../utils/dateUtils';

export function useTotals({ date, workingUnitLocation, onSuccess }) {
    const dateFrom = dayjs(date).startOf('month');
    const dateTo = dayjs(date).endOf('month');

    const { data, isSuccess } = useQuery({
        queryKey: [QueryKey.Totals, { date, workingUnitLocation }],
        queryFn: () => getTotals({ dateFrom, dateTo, workingUnitLocation }),
        enabled: !!workingUnitLocation && !!date,
        onSuccess,
        initialData: [],
    });

    return { totals: data, isSuccess };
}

export function useTotalsExportData({ date, totalsType, fetchImmediately = false }) {
    const { dateFrom, dateTo } = getStartAndEndOfAMonth(date);

    const { data, refetch } = useQuery({
        queryKey: [
            QueryKey.TotalsExport,
            {
                dateFrom: dateFrom.format('YYYY-MM-DD HH:mm:ss'),
                dateTo: dateTo.format('YYYY-MM-DD HH:mm:ss'),

                totalsType,
            },
        ],
        queryFn: () => getTotalsExportData({ dateFrom, dateTo, totalsType }),
        enabled: !!date && !!totalsType && fetchImmediately,
    });

    return { data, refetch };
}
