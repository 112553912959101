import api from './index';
import { setToken } from '../utils/authUtils';
import { HEMT_API_PRODUCTION } from '../utils/constants';

export const getAllLocationFromHEMT = async () => {
    return api
        .get(`${HEMT_API_PRODUCTION}/location`, await setToken())
        .then((res) => res.data)
        .catch((error) => error.message);
};

export const getUserById = async (userId) => {
    return api
        .get(`${HEMT_API_PRODUCTION}/user/${userId}`, await setToken())
        .then((res) => res.data)
        .catch((error) => error.message);
};

export const getAllUsersFromHEMT = async () => {
    return api
        .get(`${HEMT_API_PRODUCTION}/user`, await setToken())
        .then((res) => res.data)
        .catch((error) => error.message);
};
