import ResponsiveDialog from '../UI/Dialogs/ResponsiveDialog';
import { DialogContent, DialogActions, Button } from '@mui/material';
import Dropzone from './Dropzone';
import { useCustomStyles } from '../../hooks';

const DropzoneDialog = ({
    open,
    multiple,
    accept,
    dialogTitle,
    onClose,
    onUpload,
    uploadedFiles,
    onUploadedFilesChange,
}) => {
    const classes = useCustomStyles();

    const handleFileUpload = () => {
        onUpload(uploadedFiles);
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <ResponsiveDialog
            fullScreen={false}
            fullWidth={true}
            title={dialogTitle}
            onClose={handleClose}
            open={open}
            actionButtons={
                <DialogActions sx={{ ...classes.dialogActions, ...classes.marginBottom }}>
                    <Button onClick={handleClose} variant="outlined">
                        Abbrechen
                    </Button>
                    <Button onClick={handleFileUpload} variant="contained" disabled={uploadedFiles.length === 0}>
                        Upload
                    </Button>
                </DialogActions>
            }
        >
            <DialogContent sx={{ padding: 0 }}>
                <Dropzone
                    uploadedFiles={uploadedFiles}
                    accept={accept}
                    multiple={multiple}
                    maxSizeInMB={100}
                    onFileUpload={onUploadedFilesChange}
                />
            </DialogContent>
        </ResponsiveDialog>
    );
};

export default DropzoneDialog;
