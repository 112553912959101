import { useQuery } from '@tanstack/react-query';
import { getAllUsersFromHEMT } from '../../services/telefonica';
import { QueryKey } from '../../enums/QueryKey';

export function useHEMTUsers() {
    const { data } = useQuery({
        queryKey: [QueryKey.HEMTUsers],
        queryFn: getAllUsersFromHEMT,
        initialData: [],
    });

    return [data];
}
