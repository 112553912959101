import CustomAutoComplete from '../../UI/CustomAutoComplete';

const WorkingUnitAutocomplete = ({ workingUnitOptions, selectedWorkingUnits, onChange, isMultiSelect }) => {
    const handleChange = (workingUnits) => {
        if (Array.isArray(workingUnits)) {
            let allWorkingUnitsObject = workingUnits.find((workingUnit) => workingUnit.id === -1);
            if (workingUnits.length > 1 && allWorkingUnitsObject) {
                if (workingUnits.indexOf(allWorkingUnitsObject) === workingUnits.length - 1) {
                    onChange([allWorkingUnitsObject]);
                    return;
                } else {
                    workingUnits = workingUnits.filter((workingUnit) => workingUnit.id !== allWorkingUnitsObject.id);
                }
            }
        }

        onChange(workingUnits);
    };

    return (
        <CustomAutoComplete
            isMultiSelect={isMultiSelect}
            options={workingUnitOptions}
            labelKeys={['worktype.worktype', 'channel.name', 'task.name']}
            value={selectedWorkingUnits}
            label="Working units"
            onChange={handleChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    );
};

export default WorkingUnitAutocomplete;
