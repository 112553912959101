import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Button } from '@mui/material';
import { useCustomStyles } from '../../hooks';

const FormDialog = ({ id, showDialog, title, form, onSubmit, onClose, children }) => {
    const classes = useCustomStyles();

    const { handleSubmit } = form;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={showDialog} onClose={handleClose} PaperProps={{ style: { minWidth: '600px' } }}>
            <form id={id} onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent style={{ paddingTop: '1rem' }}>
                    <Grid container spacing={3}>
                        {children}
                    </Grid>
                </DialogContent>
                <DialogActions sx={classes.dialogActions}>
                    <Button onClick={handleClose} variant="outlined">
                        Abbrechen
                    </Button>
                    <Button type="submit" variant="contained">
                        Anlegen
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default FormDialog;
