import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

function MenuItem({ item, isNestedItem, isMenuOpen, showMenuItemExpandIcon, onClick, currentLocation, ...props }) {
    return (
        <ListItemButton
            onClick={onClick}
            sx={currentLocation?.pathname === item?.path ? { backgroundColor: 'var(--lightGrey) !important' } : {}}
            component={!item?.nestedItems ? Link : 'div'}
            {...(!item?.nestedItems && { to: item.path })}
            {...props}
        >
            <ListItemIcon>{(isMenuOpen && !isNestedItem) || !isMenuOpen ? item.icon : null}</ListItemIcon>
            <ListItemText
                primary={item.text}
                primaryTypographyProps={isMenuOpen ? { whiteSpace: 'normal' } : { whiteSpace: 'nowrap' }}
            />
            {showMenuItemExpandIcon(item)}
        </ListItemButton>
    );
}

export default MenuItem;
