import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loader from '../Loaders/Loader';
import { useContext } from 'react';
import UserContext from '../../store/User/UserContext';
import { useNavigate } from 'react-router-dom';

function ProtectedRoute({ component, allowedRole, ...props }) {
    const navigate = useNavigate();
    const loggedInUser = useContext(UserContext);

    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => <Loader />,
    });

    if (!loggedInUser) {
        return <Component {...props} />;
    }

    if (
        !loggedInUser?.isCallmartUser ||
        (allowedRole && !loggedInUser?.role[`callmart_${allowedRole.toLowerCase()}`])
    ) {
        navigate('/');
        return null;
    }

    return <Component {...props} />;
}

export default ProtectedRoute;
