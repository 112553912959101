import { Box, Backdrop, CircularProgress } from '@mui/material';

const SimpleBackdrop = ({ open }) => {
    return (
        <Box>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default function CustomBackdrop({ open }) {
    return <SimpleBackdrop open={open}></SimpleBackdrop>;
}
