import IconButton from '@mui/material/IconButton';
import { CheckOutlined as CheckOutlinedIcon, Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/system';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const StyledCheckOutlinedIcon = styled(CheckOutlinedIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
    width: '15px',
    height: '15px',
    fontWeight: 'bold',
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
    width: '15px',
    height: '15px',
    fontWeight: 'bold',
}));

function NewBookingsCustomHeader({ onSaveBtnClick, onCancelBtnClick, ...params }) {
    const handleSaveBtnClick = (event) => {
        onSaveBtnClick(params);
    };

    const handleCancelBtnClick = (event) => {
        onCancelBtnClick(params);
    };

    return (
        <div>
            <StyledIconButton aria-label="cancel" onClick={handleCancelBtnClick}>
                <StyledCloseIcon />
            </StyledIconButton>
            <StyledIconButton aria-label="save" onClick={handleSaveBtnClick}>
                <StyledCheckOutlinedIcon />
            </StyledIconButton>
        </div>
    );
}

export default NewBookingsCustomHeader;
