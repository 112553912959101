import { InputLabel, FormControl, Select } from '@mui/material';
import NoOptionsItem from './NoOptionsItem';

function CustomSelect({ children, selectedValue, options, onChange, label, ...props }) {
    const handleChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <FormControl fullWidth={true}>
            <InputLabel id="demo-simple-select-autowidth-label">{label}</InputLabel>
            <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={selectedValue}
                onChange={handleChange}
                autoWidth
                label={label}
                {...props}
            >
                {options?.length === 0 && <NoOptionsItem />}
                {children}
            </Select>
        </FormControl>
    );
}

export default CustomSelect;
