import api from './index';
import { setToken } from '../utils/authUtils';

const BASE = '/bookingDelta';

export const getAvailableAndBookedBookings = async ({ dateFrom, dateTo, location, worktype }) => {
    let apiEndPoint = `${BASE}/availableAndBooked?dateFrom=${dateFrom}&dateTo=${dateTo}&location=${location}`;
    if ((worktype && worktype > -1) || worktype === 0) {
        apiEndPoint += `&worktype=${worktype}`;
    }
    return api.get(apiEndPoint, await setToken()).then((res) => res.data);
};

export const createBookingAndBookingDelta = async (data) => {
    return api.post(`${BASE}/createBookingAndBookingDelta`, { data }, await setToken());
};

export const createBookingsAndBookingDeltas = async (data) => {
    return api.post(`${BASE}/createBookingsAndBookingDeltas`, { data }, await setToken()).then((res) => res.data);
};

export const getAllAvailableBookingsByDateAndWorktype = async (dateFrom, dateTo, worktype) => {
    return api
        .get(
            `${BASE}/getAllAvailableBookingsByDateAndWorktype?dateFrom=${dateFrom}&dateTo=${dateTo}&worktype=${worktype}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getOldestAndLatestBookedBookingsByDateAndWorktype = async (dateFrom, dateTo, worktype) => {
    return api
        .get(
            `${BASE}/getOldestAndLatestBookedBookingsByDateAndWorktype?dateFrom=${dateFrom}&dateTo=${dateTo}&worktype=${worktype}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getTotalBookedBookingsByDateRangeAndWorktype = async (dateFrom, dateTo, worktype) => {
    return api
        .get(
            `${BASE}/getTotalBookedBookingsByDateRangeAndWorktype?dateFrom=${dateFrom}&dateTo=${dateTo}&worktype=${worktype}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getAllBookedBookingsByDateRangeAndWorktype = async (dateFrom, dateTo, worktype) => {
    return api
        .get(
            `${BASE}/getAllBookedBookingsByDateRangeAndWorktype?dateFrom=${dateFrom}&dateTo=${dateTo}&worktype=${worktype}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getAllBookingDeltaByBookingId = async (bookingId) => {
    return api
        .get(`${BASE}/getAllBookingDeltaByBookingId?bookingId=${bookingId}`, await setToken())
        .then((res) => res.data);
};

export const getAllBookedBookingsByDateRangeGroupByDateAndLocation = async (dateFrom, dateTo) => {
    return api
        .get(
            `${BASE}/getAllBookedBookingsByDateRangeGroupByDateAndLocation?dateFrom=${dateFrom}&dateTo=${dateTo}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getSumOfBookedBookingsByDateAndWorktypeGroupByLocation = async (
    dateFrom,
    dateTo,
    worktype,
    bookingsCreatedAt
) => {
    return api
        .get(
            `${BASE}/getSumOfBookedBookingsByDateAndWorktypeGroupByLocation?dateFrom=${dateFrom}&dateTo=${dateTo}&worktype=${worktype}&bookingsCreatedAt=${bookingsCreatedAt}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getSumOfAllBookedBookingsByDateRangeWorktypeAndLocation = async (
    dateFrom,
    dateTo,
    worktype,
    location,
    bookingsCreatedAt
) => {
    return api
        .get(
            `${BASE}/getSumOfAllBookedBookingsByDateRangeWorktypeAndLocation?dateFrom=${dateFrom}&dateTo=${dateTo}&worktype=${worktype}&location=${location}&bookingsCreatedAt=${bookingsCreatedAt}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getAllBookedBookingsByDateRangeWorktypeAndLocation = async (dateFrom, dateTo, worktype, location) => {
    return api
        .get(
            `${BASE}/getAllBookedBookingsByDateRangeWorktypeAndLocation?dateFrom=${dateFrom}&dateTo=${dateTo}&worktype=${worktype}&location=${location}`,
            await setToken()
        )
        .then((res) => res.data);
};

export const getSumOfBookedBookingsByDateAndLocationGroupByWorktype = async (
    dateFrom,
    dateTo,
    location,
    bookingsCreatedAt
) => {
    return api
        .get(
            `${BASE}/getSumOfBookedBookingsByDateAndLocationGroupByWorktype?dateFrom=${dateFrom}&dateTo=${dateTo}&location=${location}&bookingsCreatedAt=${bookingsCreatedAt}`,
            await setToken()
        )
        .then((res) => res.data);
};
