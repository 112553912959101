import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../enums/QueryKey';
import dayjs from 'dayjs';
import {
    getSumOfCorrectedTotals,
    getLastCorrectedTotalsUploadDate,
    getCorrectedTotals,
    getLastCorrectedTotalsReleaseDate,
    getCorrectedTotalsForLocation,
} from '../../services/correctedTotals';
import { formatLastUploadDate } from '../../utils/dateUtils';

export function useSumOfCorrectedTotals({ date, workingUnitLocation, enabled = true }) {
    const dateFrom = dayjs(date).startOf('month');
    const dateTo = dayjs(date).endOf('month');

    const { data: sumOfCorrectedTotals } = useQuery({
        queryKey: [QueryKey.CorrectedTotalsSum, { date, workingUnitLocation }],
        queryFn: () => getSumOfCorrectedTotals({ dateFrom, dateTo, workingUnitLocation }),
        initialData: [],
        enabled: !!date && !!workingUnitLocation && enabled,
    });

    return { sumOfCorrectedTotals };
}

export function useLastCorrectedTotalsUploadDate(workingUnitLocation) {
    const { data } = useQuery({
        queryKey: [QueryKey.LastCorrectedTotalsUploadDate, { workingUnitLocation }],
        queryFn: () => getLastCorrectedTotalsUploadDate({ workingUnitLocation }),
        enabled: !!workingUnitLocation,
    });

    return data ? formatLastUploadDate(data) : null;
}

export const useCorrectedTotals = ({ date, workingUnitLocation, released }) => {
    const dateFrom = dayjs(date).startOf('month');
    const dateTo = dayjs(date).endOf('month');

    const { data, isSuccess } = useQuery({
        queryKey: [QueryKey.CorrectedTotals, { date, workingUnitLocation }],
        queryFn: () => getCorrectedTotals({ dateFrom, dateTo, workingUnitLocation, released }),
        enabled: !!date && !!workingUnitLocation,
        initialData: [],
    });

    return { correctedTotals: data, isSuccess };
};

export const useCorrectedTotalsForLocation = ({ date, location }) => {
    const dateFrom = dayjs(date).startOf('month');
    const dateTo = dayjs(date).endOf('month');

    const { data } = useQuery({
        queryKey: [QueryKey.CorrectedTotals, { date, location }],
        queryFn: () => getCorrectedTotalsForLocation({ dateFrom, dateTo, location }),
        enabled: !!date && !!location,
        initialData: [],
    });

    return [data];
};

export function useLastCorrectedTotalsReleaseDate(workingUnitLocation) {
    const { data } = useQuery({
        queryKey: [QueryKey.LastCorrectedTotalsReleaseDate, { workingUnitLocation }],
        queryFn: () => getLastCorrectedTotalsReleaseDate({ workingUnitLocation }),
        enabled: !!workingUnitLocation,
    });

    return data ? formatLastUploadDate(data) : null;
}
