import React from 'react';
import { Typography, FormGroup, FormControlLabel, Checkbox, Box, Divider } from '@mui/material';
import ResponsiveDialog from '../UI/Dialogs/ResponsiveDialog';

const Title = ({ worktype, channel, task, textColor }) => (
    <Typography variant="h6" color={textColor}>
        {worktype} - {channel} - {task}
    </Typography>
);

const DisplayMessage = ({ type, errors, differenceFromStrategicVolume }) => {
    switch (type) {
        case 'errors':
            return (
                <>
                    <Typography>Dein Upload konnte leider nicht verarbeitet werden da er</Typography>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={errors.negativeValues} />}
                            label="negative Werte enthält"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={errors.decimalValues} />}
                            label="Dezimalzahlen enthält (bitte nur ganze Zahlen uploaden)"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={errors.valuesOutsideOpeningHours} />}
                            label="Werte außerhalb eurer Öffnungszeiten enthält"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={errors.diffFromStrategicVolumeGreaterThan100} />}
                            label="Unterschied zu strategischem Volumen > 100 enthält"
                        />
                    </FormGroup>
                </>
            );
        case 'warning':
            const comparisonText = differenceFromStrategicVolume > 0 ? 'höher' : 'tiefer';
            const absoluteDifferenceRounded = Math.abs(differenceFromStrategicVolume).toFixed(2);
            return (
                <>
                    <Typography>Upload erfolgreich</Typography>
                    <Typography>
                        Warnungen: das korrigierte Volumen ist {absoluteDifferenceRounded}% {comparisonText} als das
                        strategische Volumen
                    </Typography>
                </>
            );
        case 'success':
            return <Typography>Upload erfolgreich</Typography>;
        case 'noNewTotals':
            return <Typography>Keine neuen Werte gefunden</Typography>;
        case 'alreadyReleased':
            return <Typography>Die Werte sind bereits gesendet</Typography>;
        case 'completed':
            return (
                <Typography>
                    Die Planung für diesen Zeitraum und Work Type ist bereits abgeschlossen. Es können keine Änderungen
                    mehr vorgenommen werden.
                </Typography>
            );
        default:
            return null;
    }
};

const getTextColor = ({
    displayErrors,
    displayWarning,
    displaySuccess,
    displayNoNewTotals,
    alreadyReleasedTotals,
    completedCalculation,
}) => {
    if (completedCalculation) return 'red';
    if (displayErrors) return 'red';
    if (displayWarning || alreadyReleasedTotals) return 'orange';
    if (displaySuccess) return 'green';
    if (displayNoNewTotals) return 'primary';
};

const CorrectedTotalsUploadResDialog = ({ open, onClose, response }) => {
    return (
        <ResponsiveDialog open={open} onClose={onClose} isConfirmationDialog={false}>
            {response.map((workingUnitLoc, index) => {
                const {
                    errors,
                    differenceFromStrategicVolume,
                    alreadyReleasedTotals,
                    numOfNewTotals,
                    worktype,
                    channel,
                    task,
                    completedCalculation,
                } = workingUnitLoc;

                const absDiffFromStrategicVolume = Math.abs(differenceFromStrategicVolume);

                const displayErrors =
                    errors.negativeValues ||
                    errors.decimalValues ||
                    errors.valuesOutsideOpeningHours ||
                    errors.diffFromStrategicVolumeGreaterThan100;
                const displayWarning =
                    numOfNewTotals > 0 && absDiffFromStrategicVolume > 5 && absDiffFromStrategicVolume < 100;
                const displaySuccess = !displayErrors && !displayWarning && numOfNewTotals > 0;
                const displayNoNewTotals =
                    !displayErrors && !displayWarning && !alreadyReleasedTotals && numOfNewTotals === 0;
                const textColor = getTextColor({
                    displayErrors,
                    displayWarning,
                    displaySuccess,
                    displayNoNewTotals,
                    alreadyReleasedTotals,
                    completedCalculation,
                });

                let messageType;
                if (completedCalculation) {
                    messageType = 'completed';
                } else if (displayErrors) {
                    messageType = 'errors';
                } else if (displayWarning) {
                    messageType = 'warning';
                } else if (displaySuccess) {
                    messageType = 'success';
                } else if (displayNoNewTotals) {
                    messageType = 'noNewTotals';
                } else if (alreadyReleasedTotals) {
                    messageType = 'alreadyReleased';
                }

                return (
                    <Box key={index} sx={{ mb: 2 }}>
                        <Title worktype={worktype} channel={channel} task={task} textColor={textColor} />
                        <Box sx={{ p: 2 }}>
                            <DisplayMessage
                                type={messageType}
                                errors={errors}
                                differenceFromStrategicVolume={differenceFromStrategicVolume}
                            />
                        </Box>
                        <Divider sx={{ mt: 2 }} />
                    </Box>
                );
            })}
        </ResponsiveDialog>
    );
};

export default CorrectedTotalsUploadResDialog;
