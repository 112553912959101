import { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, MenuItem } from '@mui/material';
import {
    useAlertMessage,
    useCookieWorktype,
    useHEMTLocations,
    useWorktypes,
    useChannels,
    useSelectedChannel,
} from '../../hooks';
import CustomDatePicker from '../UI/CustomDatePicker';
import Container from '../Layout/Container';
import WorktypeSelect from '../Selects/WorktypeSelect';
import CustomSelect from '../UI/CustomSelect';
import { ChannelOptions } from '../../enums/ChannelOptions';
import { getFirstDayOfMonth, getLastDayOfMonth } from '../../utils/dateUtils';
import { onMonthPickerChange } from '../../utils/componentUtils';
import { getAllTefOfferedPotentialByFilters } from '../../services/tefOfferedPotential';
import { getSumOfCallCenterPotentialsBySlotAndChannel } from '../../services/callCenterPotential';
import { createColumnDefsFromSlotDays, createTableRows, getSlotTimeRowKey } from '../../utils/agGridUtils';
import { getAllSlotDaysInMonthAndYear } from '../../services/slot';
import dayjs from 'dayjs';
import TableGridItem from '../UI/TableGridItem';

const createMapWithTableRows = (groupedPotentials) => {
    let rowsMap = new Map();
    for (let [key, potential] of groupedPotentials) {
        const slotTime = getSlotTimeRowKey(key, true);
        const day = dayjs(key).format('DD');

        const tefOfferedPotential = potential?.tefOfferedPotential ?? 0;
        const callCenterPotential = potential?.callCenterPotential ?? 0;
        const potentialsDifference = tefOfferedPotential - callCenterPotential;

        if (rowsMap.has(slotTime)) {
            rowsMap.set(slotTime, {
                ...rowsMap.get(slotTime),
                [`day${day}`]: potentialsDifference.toString(),
            });
        } else {
            rowsMap.set(slotTime, {
                [`day${day}`]: potentialsDifference.toString(),
            });
        }
    }
    return rowsMap;
};

const groupTefAndCallCenterPotentials = (tefOfferedPotentials, callCenterPotentials) => {
    const evaluatedPotentials = new Map();

    for (let i = 0; i < tefOfferedPotentials.length; i++) {
        evaluatedPotentials.set(tefOfferedPotentials[i].slotDate, {
            tefOfferedPotential: tefOfferedPotentials[i].potentialValue,
        });
    }

    for (let i = 0; i < callCenterPotentials.length; i++) {
        evaluatedPotentials.set(callCenterPotentials[i].slotDate, {
            ...evaluatedPotentials.get(callCenterPotentials[i].slotDate),
            callCenterPotential: callCenterPotentials[i].sumOfCallCenterPotentials ?? 0,
        });
    }

    return evaluatedPotentials;
};

function Auswertung({ locationPriority }) {
    const { dispatchAlert } = useAlertMessage();

    const gridRef = useRef(null);

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [worktypeOptions] = useWorktypes();
    const [selectedWorktype, setSelectedWorktype] = useCookieWorktype(worktypeOptions);
    const [channelOptions] = useChannels({ name: [ChannelOptions.INBOUND_VOICE.name, ChannelOptions.CHAT.name] });
    const [selectedChannel, setSelectedChannel] = useSelectedChannel(channelOptions);
    const allLocationsFromHEMT = useHEMTLocations();

    const [tableRows, setTableRows] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);

    const createTableCols = useCallback(async (dateFrom, dateTo) => {
        const slotDaysInMonth = await getAllSlotDaysInMonthAndYear(dateFrom, dateTo);
        const columnDefinitions = createColumnDefsFromSlotDays(slotDaysInMonth);

        setColumnDefs(columnDefinitions);
    }, []);

    const getData = useCallback(
        async (dateFrom, dateTo, locationPriority, worktype, channel) => {
            gridRef?.current?.api?.showLoadingOverlay();

            const tefOfferedPotentials = await getAllTefOfferedPotentialByFilters(dateFrom, dateTo, worktype, channel);
            const callCenterPotentials = await getSumOfCallCenterPotentialsBySlotAndChannel(
                dateFrom,
                dateTo,
                locationPriority,
                worktype,
                channel
            );

            const evaluatedPotentials = groupTefAndCallCenterPotentials(tefOfferedPotentials, callCenterPotentials);
            if (evaluatedPotentials.size > 0) {
                const rowsMap = createMapWithTableRows(evaluatedPotentials);
                const rows = createTableRows(rowsMap, 'slotTime');
                setTableRows(rows);

                gridRef?.current?.api?.hideOverlay();
            } else {
                setTableRows([]);

                gridRef?.current?.api?.showNoRowsOverlay();

                dispatchAlert({
                    message: 'No data available',
                    type: 'error',
                });
            }
        },
        [dispatchAlert]
    );

    useEffect(() => {
        if (selectedDate) {
            const dateFrom = getFirstDayOfMonth(selectedDate);
            const dateTo = getLastDayOfMonth(selectedDate);

            createTableCols(dateFrom, dateTo);
        }
    }, [selectedDate, createTableCols]);

    useEffect(() => {
        if (selectedDate && selectedWorktype && selectedChannel && allLocationsFromHEMT.length) {
            const dateFrom = getFirstDayOfMonth(selectedDate);
            const dateTo = getLastDayOfMonth(selectedDate);

            getData(dateFrom, dateTo, locationPriority, selectedWorktype, selectedChannel);
        }
    }, [selectedDate, selectedWorktype, selectedChannel, allLocationsFromHEMT, getData, locationPriority]);

    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} lg={2}>
                    <CustomDatePicker
                        date={selectedDate}
                        views={['year', 'month']}
                        openTo="month"
                        onChange={(newValue) => onMonthPickerChange(newValue, selectedDate, setSelectedDate)}
                    ></CustomDatePicker>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <WorktypeSelect
                        selectedWorktype={selectedWorktype}
                        worktypeOptions={worktypeOptions}
                        onChange={setSelectedWorktype}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <CustomSelect
                        selectedValue={selectedChannel}
                        options={channelOptions}
                        onChange={setSelectedChannel}
                        label={'Channel'}
                    >
                        {channelOptions &&
                            channelOptions.map((channel) => (
                                <MenuItem value={channel.id} key={channel.id}>
                                    <span className="notranslate">{channel.name}</span>
                                </MenuItem>
                            ))}
                    </CustomSelect>
                </Grid>
                <TableGridItem
                    ref={gridRef}
                    rowData={tableRows}
                    defaultColDef={{
                        editable: false,
                        filter: true,
                        sortable: true,
                    }}
                    columnDefs={columnDefs}
                ></TableGridItem>
            </Grid>
        </Container>
    );
}

export default Auswertung;
