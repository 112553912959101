import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../enums/QueryKey';
import { getWorkingHours, getWorkingHoursByWorkingUnitLocation } from '../../services/workingHours';

export const useWorkingHours = (params) => {
    const workingUnit = params?.workingUnit;

    const { data } = useQuery({
        queryKey: [QueryKey.WorkingHours, { workingUnit }],
        queryFn: () => getWorkingHours({ workingUnit }),
        enabled: !!workingUnit,
    });

    return { data };
};

export const useWorkingHoursByWorkingUnitLocation = (workingUnitLocationId) => {
    const { data } = useQuery({
        queryKey: [QueryKey.WorkingHours, { workingUnitLocationId }],
        queryFn: () => getWorkingHoursByWorkingUnitLocation(workingUnitLocationId),
        enabled: !!workingUnitLocationId,
    });

    return data;
};
