import { useQuery } from '@tanstack/react-query';
import {
    getLastForecastDeltaUploadDate,
    getTotalForecastDeltaValuesByDateRangeAndWorktype,
    getAllForecastDeltasByDateRangeWorktypeAndLocation,
    getSumOfAllForecastDeltaValuesByDateRangeWorktypeAndLocation,
    getAllForecastDeltasByDateRangeAndWorktype,
} from '../../services/forecastDelta';
import { QueryKey } from '../../enums/QueryKey';
import { formatLastUploadDate } from '../../utils/dateUtils';

export function useLastForecastDeltaUploadDate(worktype) {
    const { data } = useQuery({
        queryKey: [QueryKey.LastUploadDate + '-forecastDelta', { worktype }],
        queryFn: () => getLastForecastDeltaUploadDate(worktype),
        enabled: !!worktype,
    });

    return formatLastUploadDate(data);
}

export function useTotalForecastDeltasByDateRangeAndWorktype({ dateFrom, dateTo, worktype }) {
    const { data } = useQuery({
        queryKey: [QueryKey.TotalForecastDeltasByDateRangeAndWorktype, { dateFrom, dateTo, worktype }],
        queryFn: () => getTotalForecastDeltaValuesByDateRangeAndWorktype(dateFrom, dateTo, worktype),
        enabled: !!dateFrom && !!dateTo && !!worktype,
        initialData: {
            total: 0,
            positive: 0,
            negative: 0,
        },
    });

    return data;
}

export function useAllForecastDeltasByDateRangeWorktypeAndLocation({ dateFrom, dateTo, worktype, location }) {
    const { data } = useQuery({
        queryKey: [QueryKey.AllForecastDeltasByDateRangeWorktypeAndLocation, { dateFrom, dateTo, worktype, location }],
        queryFn: () => getAllForecastDeltasByDateRangeWorktypeAndLocation(dateFrom, dateTo, worktype, location),
        enabled: !!dateFrom && !!dateTo && !!worktype,
        initialData: [],
    });

    return [data];
}

export function useTotalForecastDeltasByDateRangeAndWorktypeAndLocation({ dateFrom, dateTo, worktype, location }) {
    const { data } = useQuery({
        queryKey: [QueryKey.TotalForecastDeltasByDateRangeAndWorktypeAndLocation, { dateFrom, dateTo, worktype }],
        queryFn: () =>
            getSumOfAllForecastDeltaValuesByDateRangeWorktypeAndLocation(dateFrom, dateTo, worktype, location),
        enabled: !!dateFrom && !!dateTo && !!worktype && !!location,
        initialData: {
            positive: 0,
            negative: 0,
        },
    });

    return data;
}

export function useAllForecastDeltasByDateRangeAndWorktype({ dateFrom, dateTo, worktype }) {
    const { data } = useQuery({
        queryKey: [QueryKey.AllForecastDeltasByDateRangeAndWorktype, { dateFrom, dateTo }],
        queryFn: () => getAllForecastDeltasByDateRangeAndWorktype(dateFrom, dateTo, worktype),
        enabled: !!dateFrom && !!dateTo && !!worktype,
        initialData: [],
    });

    return [data];
}
