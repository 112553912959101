import { useState } from 'react';
import {
    useLocationsByLoggedInUserRole,
    useDispatchSuccessNotification,
    useDispatchErrorNotification,
} from '../../hooks';
import { useWorkingUnits } from '../../hooks/api/workingUnits';
import { useMutation } from '@tanstack/react-query';
import { releaseTotals } from '../../services/biddingCalculated';
import { QueryKey } from '../../enums/QueryKey';
import { queryClient } from '../../services/queryClient';
import dayjs from 'dayjs';
import ReleaseTotalsDialog from './ReleaseTotalsDialog';
import { Checkbox, FormControlLabel } from '@mui/material';

const getSelectedData = (selectedRecords, allRecords) => {
    if (selectedRecords.find((r) => r.id === -1)) {
        return allRecords;
    }
    return selectedRecords;
};

const ReleaseBiddingTotalsDialog = ({
    open,
    onClose,
    date,
    workingUnitLocation,
    isSubsequentRelease,
    onSubsequentReleaseChange,
}) => {
    const { dispatchSuccessNotification } = useDispatchSuccessNotification();
    const { dispatchErrorNotification } = useDispatchErrorNotification();

    const [locationOptions] = useLocationsByLoggedInUserRole({ prependAllLocationsOption: true });
    const [selectedLocations, setSelectedLocations] = useState([]);

    const [workingUnitOptions] = useWorkingUnits({ hasMatchingOfferedCluster: true, prependAllOption: true });
    const [selectedWorkingUnits, setSelectedWorkingUnits] = useState([]);

    const { mutateAsync: handleReleaseTotals } = useMutation({
        mutationFn: releaseTotals,
        onSuccess: (response) => {
            queryClient.invalidateQueries([QueryKey.LastBiddingCalculatedTotalsReleaseDate, { workingUnitLocation }]);
            onClose();

            const { success, numOfReleasedTotals, message } = response;
            if (success && numOfReleasedTotals > 0) {
                dispatchSuccessNotification({ message: 'Totals erfolgreich freigegeben' });

                queryClient.invalidateQueries([QueryKey.CorrectedTotals, { date, workingUnitLocation }]);
                onClose();
                return;
            }
            const errorMessage = numOfReleasedTotals === 0 ? 'Keine Totals gefunden' : message;
            dispatchErrorNotification({ message: errorMessage });
        },
        onError: (error) => {
            dispatchErrorNotification({ message: error.message || 'Fehler beim Freigeben der Totals' });
        },
    });

    return (
        <ReleaseTotalsDialog
            open={open}
            onClose={onClose}
            date={date}
            onRelease={() =>
                handleReleaseTotals({
                    dateFrom: dayjs(date).startOf('month'),
                    dateTo: dayjs(date).endOf('month'),
                    locations: getSelectedData(selectedLocations, locationOptions),
                    workingUnits: getSelectedData(selectedWorkingUnits, workingUnitOptions),
                    isSubsequentRelease,
                })
            }
            selectedLocations={selectedLocations}
            onSelectedLocationsChange={setSelectedLocations}
            workingUnitOptions={workingUnitOptions}
            selectedWorkingUnits={selectedWorkingUnits}
            onSelectedWorkingUnitsChange={setSelectedWorkingUnits}
            isLocationMultiSelect={true}
            releaseButtonText="Freigeben"
        >
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={() => onSubsequentReleaseChange(!isSubsequentRelease)}
                        checked={isSubsequentRelease}
                    ></Checkbox>
                }
                label="Wiederholungsfreigabe"
            />
        </ReleaseTotalsDialog>
    );
};

export default ReleaseBiddingTotalsDialog;
