import { Grid, Typography } from '@mui/material';
import { useSumOfCorrectedTotals } from '../../hooks/api/correctedTotals';
import { useStrategicVolumeReport } from '../../hooks/api/strategicVolumeReports';
import { useSumOfReleasedBiddingCalculatedTotals } from '../../hooks/api/biddingCalculated';

const getDifference = (strategicVolumeReportValue, sumOfTotals) => {
    let difference = (strategicVolumeReportValue - sumOfTotals) * -1;
    if (Number.isNaN(difference)) {
        return '';
    }

    return difference.toFixed(2);
};
const CompareTotalsWithStrategicVolume = ({ date, workingUnitLocation, compare }) => {
    const { sumOfCorrectedTotals } = useSumOfCorrectedTotals({ date, workingUnitLocation, enabled: compare });
    const { sumOfReleasedBiddingCalculatedTotals } = useSumOfReleasedBiddingCalculatedTotals({
        date,
        workingUnitLocation,
        enabled: compare,
    });

    const sumOfTotals = sumOfCorrectedTotals || sumOfReleasedBiddingCalculatedTotals;
    const { strategicVolumeReport } = useStrategicVolumeReport({
        workingUnitLocation,
        date,
        enabled: compare,
    });

    const strategicVolumeReportValue = parseFloat(strategicVolumeReport?.value?.toFixed(2) || 0);
    const difference = getDifference(strategicVolumeReportValue, sumOfTotals);

    return compare ? (
        <Grid item xs={6} lg={4} xl={3} style={{ display: 'flex', gap: 20 }}>
            <Typography>
                Strategisches Volumen: <span style={{ display: 'block' }}>{strategicVolumeReportValue} </span>
            </Typography>
            <Typography>
                Summe Intervale:{' '}
                <span style={{ display: 'block' }}>
                    {sumOfTotals}
                    <span style={{ color: difference < 0 ? 'red' : 'green', marginLeft: '10px' }}>{difference}</span>
                </span>
            </Typography>
        </Grid>
    ) : null;
};

export default CompareTotalsWithStrategicVolume;
