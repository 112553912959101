import { FormControl, Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { getOptionLabelDependingOnLabelKeys } from '../../utils/componentUtils';
import ReactHookFormErrorLabel from './ReactHookFormErrorLabel';

const ReactHookFormAutoComplete = ({
    name,
    label,
    control,
    options,
    defaultValue,
    labelKeys,
    rules,
    formErrors,
    onChange,
    ...props
}) => {
    return (
        <FormControl {...props} fullWidth={true}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                render={({ field, fieldState }) => (
                    <>
                        <Autocomplete
                            {...field}
                            value={field.value}
                            onChange={(event, newValue) => {
                                onChange && onChange(event, newValue);
                                field.onChange(newValue);
                            }}
                            options={options || []}
                            getOptionLabel={(option) => option && getOptionLabelDependingOnLabelKeys(option, labelKeys)}
                            renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
                            {...props}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            noOptionsText="Keine Optionen"
                        />
                        {fieldState?.error && (
                            <ReactHookFormErrorLabel
                                fieldError={fieldState.error}
                                fieldName={name}
                                formErrors={formErrors}
                            ></ReactHookFormErrorLabel>
                        )}
                    </>
                )}
            />
        </FormControl>
    );
};

export default ReactHookFormAutoComplete;
