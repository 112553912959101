import CookieSelect from './CookieSelect';

function LocationSelect({ selectedLocation, locationOptions, onChange, ...props }) {
    return (
        <CookieSelect
            selectedValue={selectedLocation}
            options={locationOptions}
            onChange={onChange}
            valueKey={'id'}
            labelKeys={['code', 'city']}
            label={'Location'}
            cookieName={'location'}
            {...props}
        ></CookieSelect>
    );
}

export default LocationSelect;
