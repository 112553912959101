import { useCreateStrategicVolume, useStrategicVolumes, useUpdateStrategicVolume } from '../hooks/api/strategicVolumes';
import { useCookieLocation, useLocationsByLoggedInUserRole } from '../hooks';
import CRUDSettings from '../components/General/CRUDSettings';
import { useState } from 'react';
import StrategicVolumeDialog from '../components/StrategicVolume/StrategicVolumeDialog';
import WorkingUnitLocationSelect from '../components/Selects/WorkingUnitLocationSelect';
import { getCellValue } from '../utils/agGridUtils';

const StrategicVolumeSettings = () => {
    const [locationOptions] = useLocationsByLoggedInUserRole();
    const [selectedLocation, setSelectedLocation] = useCookieLocation(locationOptions);
    const [selectedWorkingUnitLocation, setSelectedWorkingUnitLocation] = useState('');

    const [selectedStrategicVolume, setSelectedStrategicVolume] = useState(null);
    const [showCreateStrategicVolumeDialog, setShowCreateStrategicVolumeDialog] = useState(false);

    const [strategicVolumes] = useStrategicVolumes({ workingUnitLocation: selectedWorkingUnitLocation });
    const { mutate: handleCreateStrategicVolume } = useCreateStrategicVolume({
        workingUnitLocation: selectedWorkingUnitLocation,
        onSettled: () => {
            setShowCreateStrategicVolumeDialog(false);
        },
    });
    const { mutate: handleUpdateStrategicVolume } = useUpdateStrategicVolume({
        workingUnitLocation: selectedWorkingUnitLocation,
        onSettled: () => {
            setShowCreateStrategicVolumeDialog(false);
        },
    });

    const handlePost = (data) => {
        const { id, workingUnitLocation, capacityType, forecasters, ...rest } = data;
        const strategicVolume = {
            ...(id && { id }),
            workingUnitLocationId: workingUnitLocation.id,
            capacityTypeId: capacityType,
            forecasterId: forecasters.map((f) => f.id),
            ...rest,
        };

        if (id) {
            handleUpdateStrategicVolume(strategicVolume);
            return;
        }

        handleCreateStrategicVolume(strategicVolume);
    };

    const columnDefs = [
        {
            field: 'location',
            headerName: 'Standort',
            cellRenderer: (params) => getCellValue(params, 'city', 'code'),
            filterValueGetter: (params) => {
                return `${params.data.location.code} - ${params.data.location.city}`;
            },
        },
        {
            field: 'workingUnitLocation.workingUnit.worktype.worktype',
            headerName: 'Worktype',
        },
        {
            field: 'workingUnitLocation.workingUnit.channel.name',
            headerName: 'Kanal',
        },
        {
            field: 'workingUnitLocation.workingUnit.task.name',
            headerName: 'Task',
        },
        {
            field: 'capacityType.name',
            headerName: 'Capacity Type',
        },
        {
            field: 'capacityType.unit',
            headerName: 'Einheit',
        },
        {
            field: 'forecasterNames',
            headerName: 'Forecaster',
            minWidth: 110,
        },
        {
            field: 'contact',
            headerName: 'Kontaktart',
            editable: true,
        },
        {
            field: 'comment',
            headerName: 'Anmerkung',
        },
    ];

    const filters = (
        <WorkingUnitLocationSelect
            locationOptions={locationOptions}
            selectedLocation={selectedLocation}
            onSelectedLocationChange={setSelectedLocation}
            selectedWorkingUnitLocation={selectedWorkingUnitLocation}
            onSelectedWorkingUnitLocationChange={setSelectedWorkingUnitLocation}
        ></WorkingUnitLocationSelect>
    );

    return (
        <>
            <CRUDSettings
                tableRows={strategicVolumes}
                columnDefs={columnDefs}
                columnToFocusOnEdit="location"
                btnText="Neuer Strategic Volume"
                recordType="Strategic Volume"
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={[10, 20, 50, 100]}
                isCreationViaDialog={true}
                isEditViaDialog={true}
                setShowCreationDialog={setShowCreateStrategicVolumeDialog}
                onSelectedRowChange={(selectedRow) => setSelectedStrategicVolume(selectedRow)}
                filters={filters}
            ></CRUDSettings>
            {showCreateStrategicVolumeDialog && (
                <StrategicVolumeDialog
                    showDialog={showCreateStrategicVolumeDialog}
                    strategicVolume={selectedStrategicVolume}
                    onClose={() => setShowCreateStrategicVolumeDialog(false)}
                    onSubmit={handlePost}
                ></StrategicVolumeDialog>
            )}
        </>
    );
};

export default StrategicVolumeSettings;
