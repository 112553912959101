import { useState, useRef } from 'react';
import { Grid, Typography } from '@mui/material';
import Container from '../components/Layout/Container';
import dayjs from 'dayjs';
import { getUpcomingMonths, getDateRangeFromStartDateByAddingMonths } from '../utils/dateUtils';
import { getValueForMonthColumn, stringToNumberFormatter } from '../utils/agGridUtils';
import { createTableRows } from '../utils/agGridUtils';
import { uploadWFMs } from '../services/wfmValue';
import { useAlertMessage, useCustomStyles } from '../hooks';
import UploadXlsxFileButton from '../components/Buttons/UploadXlsxFileButton';
import { parseXLSXData } from '../utils/xlsxUtils';
import PercentageLoadingOverlay from '../components/Loaders/PercentageLoadingOverlay';
import {
    setCommonStateAfterDataUpload,
    showSuccessfulUploadNotification,
    showErrorUploadNotification,
    parseUploadResponse,
} from '../utils/componentUtils';
import HorizontalScrollTable from '../components/UI/HorizontalScrollTable';
import { useCustomerBaseWFMs } from '../hooks/api/wfms';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '../enums/QueryKey';
import { queryClient } from '../services/queryClient';
import * as Sentry from '@sentry/react';

export const addRowsInMap = ({ wfms, monthColumns }) => {
    let rowsMap = new Map();

    for (const wfm of wfms) {
        const key = `${wfm.id}`;
        const wfmValuesRes = {
            wfm,
        };

        monthColumns.forEach((monthColumn) => {
            const month = dayjs(monthColumn).format('YYYY-MM');

            const wfmValues = wfm.WFMValues.find(
                (wfmValue) => dayjs(new Date(wfmValue.month)).format('YYYY-MM') === month
            );

            const wfmValue = wfmValues !== undefined ? wfmValues.value : null;
            wfmValuesRes[month] = {
                value: wfmValue,
                wfmId: wfm?.id,
            };
        });

        if (rowsMap.has(key)) {
            rowsMap.set(key, {
                ...rowsMap.get(key),
                ...wfmValuesRes,
            });
        } else {
            rowsMap.set(key, {
                ...wfmValuesRes,
            });
        }
    }

    return rowsMap;
};

const sideBar = {
    toolPanels: [
        {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: false,
                suppressColumnSelectAll: false,
                suppressColumnExpandAll: true,
                suppressColumnMove: false,
            },
        },
    ],
};

const CustomerBaseKPIs = ['Customer Base', 'Customer Base Postpaid', 'Customer Base Prepaid', 'Gross Ads'];

const WFM = () => {
    const classes = useCustomStyles();
    const { dispatchAlert } = useAlertMessage();
    const gridRef = useRef(null);
    const [firstMonth, setFirstMonth] = useState(dayjs());

    const [loading, setLoading] = useState(false);
    const [percentage, setPercentage] = useState(0);

    const { mutateAsync: handleUploadWFMs } = useMutation({
        mutationFn: uploadWFMs,
    });

    const createColumnDefs = (month) => {
        const monthColumns = getUpcomingMonths(month, 6);

        let columns = [
            {
                field: 'wfm.KPI.name',
                headerName: 'KPI',
                minWidth: 80,
            },
            {
                field: 'wfm.channel.name',
                headerName: 'Kanäle',
                minWidth: 95,
            },
            {
                field: 'wfm.WFMCluster.worktype.worktype',
                headerName: 'Worktype',
                minWidth: 115,
            },
            {
                field: 'wfm.WFMCluster.welt.name',
                headerName: 'Welt',
            },
            {
                field: 'wfm.WFMCluster.B2X.name',
                headerName: 'B2X',
            },
            {
                field: 'wfm.WFMCluster.budgetDirector.name',
                headerName: 'Budget Director',
                minWidth: 110,
            },
            {
                field: 'wfm.WFMCluster.headOf.name',
                headerName: 'Head Of',
            },
            {
                field: 'wfm.WFMCluster.brand.name',
                headerName: 'Brand',
            },
            { field: 'wfm.handled', headerName: 'Handled Detail', minWidth: 105 },
            {
                field: 'wfm.WFMCluster.worktype.allSignOffCluster.name',
                headerName: 'All Sign-Off Cluster ',
                hide: true,
            },
            {
                field: 'wfm.WFMCluster.worktype.allSignOffCluster.relevance',
                headerName: 'Relevanz',
                valueGetter: (params) => {
                    const relevance = params.data.wfm.WFMCluster.worktype?.allSignOffCluster?.relevance;
                    return relevance === true ? 'Ja' : relevance === false ? 'Nein' : null;
                },
                hide: true,
            },
            {
                field: 'wfm.WFMCluster.worktype.allSignOffCluster.wtm',
                headerName: 'WTM',
                hide: true,
            },
            {
                field: 'wfm.WFMCluster.worktype.allSignOffCluster.forecasterName',
                headerName: 'Forecaster',
                hide: true,
            },
            {
                field: 'wfm.WFMCluster.worktype.allSignOffCluster.weightedSf',
                headerName: 'Gewichteter SF',
                hide: true,
            },
            {
                field: 'wfm.WFMCluster.worktype.allSignOffCluster.service.name',
                headerName: 'Service/Sales',
                hide: true,
            },
        ];

        for (const monthColumn of monthColumns) {
            columns.push({
                field: `${monthColumn.format('YYYY-MM')}`,
                headerName: `${monthColumn.format('MMM YY')}`,
                valueGetter: (params) => getValueForMonthColumn(params, monthColumn),
                valueFormatter: (params) => {
                    const value = stringToNumberFormatter(params);
                    return params?.data.wfm.unit === '%' && value ? value + '%' : value;
                },
                suppressMovable: true,
            });
        }

        return columns;
    };

    const monthColumns = getUpcomingMonths(firstMonth, 6);
    const columnDefs = firstMonth ? createColumnDefs(firstMonth) : [];
    const [wfms] = useCustomerBaseWFMs(firstMonth, CustomerBaseKPIs);
    const tableRows = wfms?.length > 0 ? createTableRows(addRowsInMap({ wfms, monthColumns }), 'id') : [];

    const uploadFiles = (files, closeDropzoneDialog) => {
        setLoading(true);

        const file = files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = parseXLSXData(e);

            handleUploadWFMs({ data, setPercentage })
                .then((res) => {
                    const resData = parseUploadResponse(res, 'WFM');
                    const { success, errorMessage } = resData;

                    if (success) {
                        showSuccessfulUploadNotification(dispatchAlert);
                    } else {
                        showErrorUploadNotification({
                            dispatchAlert,
                            errorMessage,
                        });
                    }
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    showErrorUploadNotification({
                        dispatchAlert,
                        errorMessage: error.message,
                    });
                })
                .finally(() => {
                    const { dateFrom, dateTo } = getDateRangeFromStartDateByAddingMonths(firstMonth, 6);
                    queryClient.invalidateQueries([QueryKey.WFMs, { dateFrom, dateTo, kpiNames: CustomerBaseKPIs }]);
                    setCommonStateAfterDataUpload(setLoading, setPercentage, closeDropzoneDialog);
                });
        };
        reader.readAsArrayBuffer(file);
    };

    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={10} lg={11}>
                    <Typography variant="h5">Customer Base Zahlen</Typography>
                </Grid>
                <Grid item xs={2} md={2} lg={1} sx={classes.btnRightAligned}>
                    <UploadXlsxFileButton
                        buttonName="Upload"
                        uploadFiles={uploadFiles}
                        dialogTitle="WFMs hochladen"
                    ></UploadXlsxFileButton>
                </Grid>
                <HorizontalScrollTable
                    firstMonth={firstMonth}
                    changeFirstMonth={setFirstMonth}
                    ref={gridRef}
                    tableRows={tableRows}
                    defaultColDef={{
                        editable: false,
                        filter: true,
                        sortable: true,
                        enablePivot: true,
                        minWidth: 90,
                    }}
                    tableColumnDefs={columnDefs}
                    showOptionToGoOneMonthBackward={false}
                    showOptionToGoMoreMonthsBackward={true}
                    showOptionToGoOneMonthForward={false}
                    showOptionToGoMoreMonthsForward={true}
                    numOfMonthsToMove={6}
                    sideBar={sideBar}
                ></HorizontalScrollTable>
            </Grid>
            <PercentageLoadingOverlay loading={loading} percentage={percentage}></PercentageLoadingOverlay>
        </Container>
    );
};

export default WFM;
