import ResponsiveDialog from '../UI/Dialogs/ResponsiveDialog';
import { Typography } from '@mui/material';

const BiddingCalculationBackgroundProcessDialog = ({ open, onClose, data }) => {
    return (
        <ResponsiveDialog title="" open={open} onClose={onClose} isConfirmationDialog={false}>
            <Typography>
                Die Berechnung wird im Hintergrund durchgeführt. Nach Abschluß erhalten Sie eine Benachrichtigung per
                E-Mail.
            </Typography>
        </ResponsiveDialog>
    );
};

export default BiddingCalculationBackgroundProcessDialog;
