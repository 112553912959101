import { Autocomplete, TextField, Checkbox, Chip } from '@mui/material';
import { getOptionLabelDependingOnLabelKeys } from '../../utils/componentUtils';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function CustomAutoComplete({ options, onChange, value, label, labelKeys, isMultiSelect, ...props }) {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const multiple = isMultiSelect !== undefined ? isMultiSelect : true;

    const handleChange = (event, value) => {
        onChange(value);
    };

    return (
        <Autocomplete
            multiple={multiple}
            options={options}
            getOptionLabel={(option) => option && getOptionLabelDependingOnLabelKeys(option, labelKeys)}
            value={value}
            onChange={handleChange}
            renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                    {isMultiSelect && <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />}
                    <span className="notranslate">{getOptionLabelDependingOnLabelKeys(option, labelKeys)}</span>
                </li>
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        {...getTagProps({ index })}
                        key={option.id}
                        label={getOptionLabelDependingOnLabelKeys(option, labelKeys)}
                        className="notranslate"
                    />
                ))
            }
            renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
            disableCloseOnSelect={isMultiSelect}
            getOptionDisabled={(option) => option.disabled}
            {...props}
            noOptionsText="Keine Optionen"
        />
    );
}

export default CustomAutoComplete;
