import { useQuery } from '@tanstack/react-query';
import { getPublicHolidays } from '../../services/publicHolidays';
import { QueryKey } from '../../enums/QueryKey';

export function usePublicHolidays() {
    const { data: publicHolidays } = useQuery({
        queryKey: [QueryKey.PublicHolidays],
        queryFn: getPublicHolidays,
        initialData: [],
    });

    return publicHolidays;
}
