import { getLocationDeltaByDateRangeLocationAndWorktype } from '../../services/locationDelta';
import { getFirstDayOfMonth, getLastDayOfMonth } from '../../utils/dateUtils';
import dayjs from 'dayjs';
import { usePreferredUserLocation } from '../../hooks';
import DownloadExcelButton from '../Buttons/DownloadExcelButton';
import { getAllDatesInAMonth, getAllHourSlotsInATimeRange } from '../../utils/dateUtils';

const columnNames = [
    'START_TIME',
    'HOUR',
    'MINUTE',
    'Calldelta',
    'Stadt',
    'Standort Detail',
    'Worktype',
    'Kanal',
    'Grund',
];

function LocationDeltaCurrentDataDownloadButton({ date, selectedLocation, worktypes }) {
    const [location] = usePreferredUserLocation(selectedLocation);

    const getXLSXSheets = async (worktypes) => {
        const sheetsData = [];
        for (let i = 0; i < worktypes.length; i++) {
            const sheetData = [];

            const firstDayOfMonth = getFirstDayOfMonth(date);
            const lastDayOfMonth = getLastDayOfMonth(date);
            const locationDeltas = await getLocationDeltaByDateRangeLocationAndWorktype(
                firstDayOfMonth,
                lastDayOfMonth,
                location?.id,
                worktypes[i].id
            );
            for (let j = 0; j < locationDeltas.length; j++) {
                const start_time = dayjs(locationDeltas[j].slotDate).format('DD.MM.YYYY');
                const hour = parseInt(dayjs(locationDeltas[j].slotDate).format('HH'));
                const minute = parseInt(dayjs(locationDeltas[j].slotDate).format('mm'));
                const locationDeltaValue = locationDeltas[j].locationDeltaValue;
                const channel = locationDeltas[j].channel ? locationDeltas[j].channel : '';
                const reason = locationDeltas[j].reason ? locationDeltas[j].reason : '';
                const row = [
                    start_time,
                    hour,
                    minute,
                    locationDeltaValue,
                    location?.city,
                    location?.code,
                    worktypes[i].worktype,
                    channel,
                    reason,
                ];
                sheetData.push(row);
            }

            sheetsData.push({
                sheetName: worktypes[i].worktype,
                sheetData: sheetData,
            });
        }

        return sheetsData;
    };

    return (
        <DownloadExcelButton
            title="Standortdelta Template"
            fileName={`delta_${location?.city}_${dayjs(date).format('MM_YYYY')}.xlsx`}
            location={selectedLocation}
            columnNames={columnNames}
            getSheetsData={() =>
                getXLSXSheets(
                    worktypes,
                    getAllDatesInAMonth(date),
                    getAllHourSlotsInATimeRange({ startTime: 7, endTime: 22 })
                )
            }
            buttonText="Aktuell"
        ></DownloadExcelButton>
    );
}

export default LocationDeltaCurrentDataDownloadButton;
