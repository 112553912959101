import { Button } from '@mui/material';
import { ReactComponent as FileExport } from '../../assets/file-export.svg';
import { useState } from 'react';
import ExportTotalsDialog from './ExportTotalsDialog';

const ExportTotalsButton = ({ date, location, exportButtonType }) => {
    const [showExportTotalsDialog, setShowExportTotalsDialog] = useState(false);

    return (
        <>
            <Button onClick={() => setShowExportTotalsDialog(true)} variant="contained">
                <FileExport />
            </Button>
            {showExportTotalsDialog && (
                <ExportTotalsDialog
                    open={showExportTotalsDialog}
                    onClose={() => setShowExportTotalsDialog(false)}
                    date={date}
                    location={location}
                    exportButtonType={exportButtonType}
                ></ExportTotalsDialog>
            )}
        </>
    );
};

export default ExportTotalsButton;
