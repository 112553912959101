import { useState, useEffect, useCallback, useContext, useRef, useMemo } from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import Container from '../components/Layout/Container';
import {
    useAlertMessage,
    useIsMounted,
    useCookieWorktype,
    useLocationsByLoggedInUserRole,
    useCookieLocation,
    useWorktypes,
    useAllSlotDaysInMonthAndYear,
    useAvailableAndBookedBookings,
    useCustomStyles,
} from '../hooks';
import UserContext from '../store/User/UserContext';
import NewBookingsCustomHeader from '../components/AgGrid/CustomHeader/NewBookingsCustomHeader';
import { createBookingsAndBookingDeltas } from '../services/bookingDelta';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import IconRenderer from '../components/AgGrid/IconRenderer/';
import CustomDatePicker from '../components/UI/CustomDatePicker';
import WorktypeSelect from '../components/Selects/WorktypeSelect';
import { getSlotTimeColumnDef, getSlotTimeRowKey } from '../utils/agGridUtils';
import { getAllHourSlots, handleDatePickerMonthChange } from '../utils/componentUtils';
import { setUploadedMonthAndYearToDate } from '../utils/dateUtils';
import dayjs from 'dayjs';
import UserRoleLocation from '../components/General/UserRoleLocation';
import { queryClient } from '../services/queryClient';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '../enums/QueryKey';
import TableGridItem from '../components/UI/TableGridItem';
import { useSocket } from '../store/SocketContext';
import { SocketEvents } from '../enums/SocketEvents';
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
dayjs.extend(isSameOrAfter);

const createTableRows = (bookings) => {
    let rows = [];
    let rowsMap = new Map();

    if (bookings.length > 0) {
        const hourSlots = getAllHourSlots();
        hourSlots.forEach((slot) => {
            rowsMap.set(slot, []);
        });
    }

    for (let i = 0; i < bookings.length; i++) {
        const key = getSlotTimeRowKey(bookings[i].slotDate);
        const day = dayjs(new Date(bookings[i].slotDate)).format('DD');

        const array = rowsMap.get(key);

        if (array) {
            array.push({
                [`availableBookingsDay${day}`]: bookings[i].availableBookings,
                [`bookedBookingsDay${day}`]: bookings[i].bookedBookings,
                [`newBookingsDay${day}`]: '',
            });
        }
    }

    for (let key of rowsMap.keys()) {
        let row = {
            slotTime: key,
        };
        let allSlotsByHour = rowsMap.get(key);
        for (let i = 0; i < allSlotsByHour.length; i++) {
            let objectDay = Object.keys(allSlotsByHour[i]);

            row = {
                ...row,
                [objectDay[0]]: allSlotsByHour[i][objectDay[0]],
                [objectDay[1]]: allSlotsByHour[i][objectDay[1]],
                [objectDay[2]]: allSlotsByHour[i][objectDay[2]],
            };
        }
        rows.push(row);
    }

    return rows;
};

const OSPBookings = () => {
    const socket = useSocket();
    const classes = useCustomStyles();

    const loggedInUser = useContext(UserContext);

    const isMounted = useIsMounted();
    const { dispatchAlert } = useAlertMessage();

    const gridRef = useRef(null);

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [locationOptions] = useLocationsByLoggedInUserRole();
    const [selectedLocation, setSelectedLocation] = useCookieLocation(locationOptions);
    const [worktypeOptions] = useWorktypes({ location: selectedLocation });
    const [selectedWorktype, setSelectedWorktype] = useCookieWorktype(worktypeOptions);

    const { mutate: handleCreateBookingsAndBookingDeltas } = useMutation({
        mutationFn: createBookingsAndBookingDeltas,
    });

    const saveNewBookings = useCallback(
        async (params) => {
            let columnId = params.column.colId;
            let day = columnId.slice(columnId.length - 2, columnId.length);
            if (selectedWorktype) {
                let rows = params.api.getModel().rowsToDisplay.map((row) => row.data);
                if (!rows) return;
                let newBookingsAndBookingDeltas = [];
                rows.map(async (row) => {
                    let newBookings = row[`newBookingsDay${day}`];
                    let slotTime = row.slotTime;
                    if (newBookings) {
                        let slotId = `${day}.${dayjs(selectedDate).format(
                            'MM.YYYY'
                        )}-${slotTime}:00-${selectedWorktype}`;

                        newBookingsAndBookingDeltas.push({
                            slotId,
                            locationId: selectedLocation,
                            newBookings,
                        });
                    }
                });

                handleCreateBookingsAndBookingDeltas(
                    {
                        newBookingsAndBookingDeltas,
                        userEmail: loggedInUser.email,
                        locationCity: locationOptions.find((item) => item.id === selectedLocation),
                    },
                    {
                        onSuccess: ({
                            success,
                            monthForCreatedBookings,
                            locationForCreatedBookings,
                            worktypeForCreatedBookings,
                        }) => {
                            if (success) {
                                dispatchAlert({
                                    message: 'Successfully saved bookings !',
                                    type: 'success',
                                });

                                params.api.refreshHeader();
                                socket.emit(SocketEvents.createdBookingDelta, {
                                    monthForCreatedBookings,
                                    locationForCreatedBookings,
                                    worktypeForCreatedBookings,
                                });
                            }
                        },
                        onSettled: () => {
                            queryClient.invalidateQueries([
                                QueryKey.AvailableAndBookedBookings,
                                {
                                    dateFrom: dayjs(selectedDate).startOf('month'),
                                    dateTo: dayjs(selectedDate).endOf('month'),
                                    location: selectedLocation,
                                    worktype: selectedWorktype,
                                },
                            ]);
                        },
                    }
                );
            } else {
                dispatchAlert({
                    message: 'Please select worktype !',
                    type: 'error',
                });
            }
        },
        [
            selectedDate,
            dispatchAlert,
            selectedLocation,
            selectedWorktype,
            loggedInUser?.email,
            locationOptions,
            handleCreateBookingsAndBookingDeltas,
            socket,
        ]
    );

    const cancelNewBookings = (params) => {
        let columnId = params.column.colId;
        let day = columnId.slice(columnId.length - 2, columnId.length);

        params.api.forEachNode((node) => {
            node.setDataValue(`newBookingsDay${day}`, '');
        });
    };

    const createColumnDefs = useCallback(
        (slotDays) => {
            let columns = [];

            columns.push(getSlotTimeColumnDef());
            for (let i = 0; i < slotDays.length; i++) {
                const dayName = new Date(slotDays[i].date).toLocaleString('en-us', { weekday: 'short' });

                columns.push({
                    field: `day${dayjs(slotDays[i].date).format('DD')}`,
                    headerName: `${dayjs(slotDays[i].date).format('DD.MM')}\n${dayName}`,
                    cellClass: 'grid-cell-centered ospHeader',
                    resizable: true,
                    marryChildren: true,
                    suppressSizeToFit: true,
                    children: [
                        {
                            field: `availableBookingsDay${dayjs(slotDays[i].date).format('DD')}`,
                            headerComponent: IconRenderer,
                            headerComponentParams: {
                                icon: RadioButtonUncheckedIcon,
                                classes: classes.availableBookingsHeader,
                            },
                            minWidth: 40,
                            suppressSizeToFit: true,
                            editable: false,
                            sortable: false,
                            resizable: true,
                            filter: false,
                            cellStyle: {
                                justifyContent: 'center',
                                color: 'gray',
                            },
                        },
                        {
                            field: `bookedBookingsDay${dayjs(slotDays[i].date).format('DD')}`,
                            headerComponent: IconRenderer,
                            headerComponentParams: {
                                icon: RadioButtonUncheckedIcon,
                                classes: classes.bookedBookingsHeader,
                            },
                            minWidth: 40,
                            suppressSizeToFit: true,
                            editable: false,
                            sortable: false,
                            resizable: true,
                            filter: false,
                            cellStyle: (params) => {
                                let style = {
                                    justifyContent: 'center',
                                };
                                if (params.value !== 0) {
                                    return { ...style, fontWeight: 'bold' };
                                }
                                return style;
                            },
                        },
                        {
                            field: `newBookingsDay${dayjs(slotDays[i].date).format('DD')}`,
                            headerComponentParams: {
                                onSaveBtnClick: saveNewBookings,
                                onCancelBtnClick: cancelNewBookings,
                            },
                            headerComponent: NewBookingsCustomHeader,
                            columnGroupShow: 'open',
                            hide: dayjs(slotDays[i].date).startOf('day').isSameOrAfter(dayjs().startOf('day'))
                                ? false
                                : true,
                            minWidth: 60,
                            editable: true,
                            filter: false,
                            sortable: false,
                            resizable: false,
                            cellClass: 'grid-cell-centered',
                            cellStyle: {
                                border: 'solid rgba(0, 102, 255, 0.7)',
                                borderRadius: '20px',
                                justifyContent: 'center',
                                textAlign: 'center',
                            },
                        },
                    ],
                });
            }

            return columns;
        },
        [saveNewBookings, classes.availableBookingsHeader, classes.bookedBookingsHeader]
    );

    const { data: slotDaysInMonthAndYear } = useAllSlotDaysInMonthAndYear(selectedDate);
    const { data: availableAndBookedBookings } = useAvailableAndBookedBookings({
        date: selectedDate,
        location: selectedLocation,
        worktype: selectedWorktype,
    });
    const columnDefs = useMemo(
        () =>
            slotDaysInMonthAndYear?.length > 0 && availableAndBookedBookings?.length > 0
                ? createColumnDefs(slotDaysInMonthAndYear)
                : [],
        [slotDaysInMonthAndYear, createColumnDefs, availableAndBookedBookings]
    );
    const tableRows =
        availableAndBookedBookings?.length > 0 ? createTableRows(availableAndBookedBookings, 'slotTime') : [];

    function changeNewBookingValue(event) {
        let columnId = event.column.colId;
        let day = columnId.slice(columnId.length - 2, columnId.length);
        let availableBookings = parseInt(event.data[`availableBookingsDay${day}`]);
        let bookedBookings = parseInt(event.data[`bookedBookingsDay${day}`]);
        let newBookings = parseInt(event.data[`newBookingsDay${day}`]);
        let totalBookings = availableBookings + bookedBookings;

        if (
            (totalBookings > 0 && (newBookings > totalBookings || newBookings < 0)) ||
            (totalBookings < 0 && (newBookings < totalBookings || newBookings > 0))
        ) {
            event.node.setDataValue(`newBookingsDay${day}`, '');
            dispatchAlert({
                message: `Für den gewählten Slot können nur ${availableBookings + bookedBookings} Calls gebucht werden`,
                type: 'error',
            });
        }
    }

    useEffect(() => {
        socket.on(
            SocketEvents.createdBookingDelta,
            ({ monthForCreatedBookings, locationForCreatedBookings, worktypeForCreatedBookings }) => {
                if (!selectedLocation) return;

                if (selectedLocation === locationForCreatedBookings) {
                    if (worktypeOptions.length > 0) {
                        let createdBookingsDate = selectedDate;
                        createdBookingsDate.month(monthForCreatedBookings - 1);
                        setSelectedDate(createdBookingsDate);

                        let createdBookingsWorktypeInUserWorktypes = worktypeOptions.find(
                            (worktype) => worktype.id === worktypeForCreatedBookings
                        );
                        if (createdBookingsWorktypeInUserWorktypes) {
                            if (selectedWorktype !== worktypeForCreatedBookings) {
                                setSelectedWorktype(worktypeForCreatedBookings);
                            } else {
                                queryClient.invalidateQueries([
                                    QueryKey.AvailableAndBookedBookings,
                                    {
                                        dateFrom: dayjs(createdBookingsDate).startOf('month'),
                                        dateTo: dayjs(createdBookingsDate).endOf('month'),
                                        location: locationForCreatedBookings,
                                        worktype: worktypeForCreatedBookings,
                                    },
                                ]);
                            }
                        }
                    }
                }
            }
        );

        socket.on(SocketEvents.uploadedForecastDelta, ({ monthForUploading }) => {
            const uploadedDate = setUploadedMonthAndYearToDate(dayjs(), monthForUploading);
            setSelectedDate(uploadedDate);
        });

        return () => {
            socket.removeAllListeners();
        };
    }, [selectedDate, worktypeOptions, selectedLocation, isMounted, selectedWorktype, setSelectedWorktype, socket]);

    return (
        <Container>
            <Grid container spacing={1}>
                {loggedInUser && (
                    <UserRoleLocation
                        loggedInUser={loggedInUser}
                        selectedLocation={selectedLocation}
                        locationOptions={locationOptions}
                        onChange={setSelectedLocation}
                    ></UserRoleLocation>
                )}
                <Grid item xs={12} md={3} lg={2}>
                    <WorktypeSelect
                        selectedWorktype={selectedWorktype}
                        worktypeOptions={worktypeOptions}
                        onChange={setSelectedWorktype}
                    />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                    <CustomDatePicker
                        fullWidth={true}
                        date={selectedDate}
                        views={['year', 'month']}
                        openTo="month"
                        onChange={(newValue) => handleDatePickerMonthChange(selectedDate, newValue, setSelectedDate)}
                    ></CustomDatePicker>
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                    <Stack justifyContent="end">
                        <div style={classes.contentEnd}>
                            <Typography variant="body1">
                                <IconRenderer
                                    icon={RadioButtonUncheckedIcon}
                                    classes={classes.availableBookingsHeader}
                                />{' '}
                                Verfügbar
                            </Typography>
                        </div>
                        <div style={classes.contentEnd}>
                            <Typography variant="body1">
                                <IconRenderer icon={RadioButtonUncheckedIcon} classes={classes.bookedBookingsHeader} />{' '}
                                Gebucht
                            </Typography>
                        </div>
                    </Stack>
                </Grid>
                <TableGridItem
                    ref={gridRef}
                    rowData={tableRows}
                    defaultColDef={{
                        editable: false,
                        filter: true,
                        sortable: true,
                    }}
                    columnDefs={columnDefs}
                    onCellEditingStopped={changeNewBookingValue}
                    className="ospBookingsTable"
                ></TableGridItem>
            </Grid>
        </Container>
    );
};

export default OSPBookings;
