import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import ReactHookFormTextField from '../ReactHookForm/ReactHookFormTextField';
import ReactHookFormAutoComplete from '../ReactHookForm/ReactHookFormAutoComplete';
import FormDialog from '../Dialogs/FormDialog';
import { useEffect } from 'react';
import { useHEMTUsers } from '../../hooks/api/useHEMTUsers';
import { useCapacityTypes } from '../../hooks';
import { fieldIsRequiredMsg } from '../../utils/componentUtils';
import WorkingUnitLocationFormFields from '../General/WorkingUnitLocationFormFields';
import ReactHookFormSelect from '../ReactHookForm/ReactHookFormSelect';
import ReactHookFormCheckbox from '../ReactHookForm/ReactHookFormCheckbox';

const StrategicVolumeDialog = ({ showDialog, strategicVolume, onSubmit, onClose }) => {
    const [forecasterOptions] = useHEMTUsers();

    const [capacityTypes] = useCapacityTypes();
    const form = useForm({
        defaultValues: {
            id: strategicVolume?.id || '',
            location: strategicVolume?.location?.id || '',
            workingUnitLocation: strategicVolume?.workingUnitLocation
                ? {
                      ...strategicVolume?.workingUnitLocation,
                      label: `${strategicVolume?.workingUnitLocation?.workingUnit?.worktype?.worktype} - ${strategicVolume?.workingUnitLocation?.workingUnit?.channel?.name} - ${strategicVolume?.workingUnitLocation?.workingUnit?.task?.name}`,
                  }
                : '',
            forecasters: strategicVolume?.forecasters || [],
            capacityType: strategicVolume?.capacityType?.id || '',
            unit: strategicVolume?.capacityType?.unit || '',
            comment: strategicVolume?.comment || '',
            contact: strategicVolume?.contact || '',
            active: !strategicVolume || strategicVolume?.active === true ? true : false,
        },
    });
    const {
        control,
        formState: { errors },
        watch,
    } = form;
    const selectedCapacityTypeValue = watch('capacityType');
    const selectedCapacityType = capacityTypes.find((ct) => ct.id === selectedCapacityTypeValue);

    useEffect(() => {
        form.setValue('unit', selectedCapacityType?.unit, { shouldValidate: true });
    }, [form, selectedCapacityType]);

    return (
        <FormDialog
            title="Neuer Strategic Volume"
            showDialog={showDialog}
            form={form}
            onSubmit={onSubmit}
            onClose={onClose}
        >
            <WorkingUnitLocationFormFields form={form} isWorkingUnitMultiSelect={false}></WorkingUnitLocationFormFields>
            <Grid item xs={12}>
                <ReactHookFormSelect
                    name="capacityType"
                    options={capacityTypes}
                    mainProp="name"
                    label="Capacity Type"
                    control={control}
                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                    formErrors={errors}
                    isMultiSelect={false}
                ></ReactHookFormSelect>
            </Grid>
            <Grid item xs={12}>
                <ReactHookFormTextField
                    name="unit"
                    control={control}
                    label="Einheit"
                    formErrors={errors}
                    disabled={true}
                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                ></ReactHookFormTextField>
            </Grid>
            <Grid item xs={12}>
                <ReactHookFormAutoComplete
                    name="forecasters"
                    control={control}
                    label="Forecaster *"
                    labelKeys={['name']}
                    options={forecasterOptions}
                    multiple={true}
                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                    formErrors={errors}
                ></ReactHookFormAutoComplete>
            </Grid>
            <Grid item xs={12}>
                <ReactHookFormTextField
                    name="contact"
                    control={control}
                    label="Konaktart"
                    formErrors={errors}
                ></ReactHookFormTextField>
            </Grid>
            <Grid item xs={12}>
                <ReactHookFormTextField
                    name="comment"
                    control={control}
                    label="Anmerkung"
                    formErrors={errors}
                ></ReactHookFormTextField>
            </Grid>
            <Grid item xs={12}>
                <ReactHookFormCheckbox
                    name="active"
                    control={control}
                    label="Aktiv"
                    labelPlacement="end"
                    formErrors={errors}
                ></ReactHookFormCheckbox>
            </Grid>
        </FormDialog>
    );
};

export default StrategicVolumeDialog;
