import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';

const BASE = '/strategicVolume';

export const getStrategicVolumes = async ({ workingUnitLocation }) => {
    const url = appendQueryParamsToUrl(BASE, { workingUnitLocation });
    return api.get(url, await setToken()).then((res) => res.data);
};

export const createStrategicVolume = async (data) => {
    return api.post(`${BASE}`, { data }, await setToken()).then((res) => res.data);
};

export const updateStrategicVolume = async (data) => {
    const { id } = data;
    return api.patch(`${BASE}/${id}`, { data }, await setToken()).then((res) => res.data);
};
