import { Buffer } from 'buffer';

export const setStorage = (key, data) => {
    window.localStorage.setItem(key, Buffer.from(Buffer.from(encodeURI(data).toString('base64')).toString('base64')));
};

export const getStorage = (key, defaultValue = null) => {
    let data = window.localStorage.getItem(key);

    if (data) {
        return JSON.parse(decodeURI(Buffer.from(Buffer.from(data, 'base64'), 'base64')));
    } else {
        return defaultValue;
    }
};

export const removeStorage = (key, shouldGetData = false) => {
    let data = null;

    if (shouldGetData) {
        data = getStorage(key);
    }

    window.localStorage.removeItem(key);
    return data;
};

export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const getCookie = (cname) => {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

export const readAllChunks = (readableStream, setPercentage) => {
    const reader = readableStream.getReader();
    const chunks = [];

    function pump() {
        return reader.read().then(({ value, done }) => {
            if (done) {
                return chunks;
            }
            let decodedString = '';
            for (var i = 0; i < value.byteLength; i++) {
                decodedString += String.fromCharCode(value[i]);
            }

            if (isInteger(decodedString)) {
                setPercentage(decodedString);
            } else {
                chunks.push(decodedString);
            }

            return pump();
        });
    }

    return pump();
};

export const isChecked = (value) => {
    if (value) {
        return true;
    }

    return false;
};

export const isInteger = (num) => /^-?[0-9]+$/.test(num + '');

export const getLocalesNumberFormatter = (options, locales) =>
    new Intl.NumberFormat(locales || 'de-DE', { maximumFractionDigits: 2, ...options });

export const appendQueryParamsToUrl = (url, params) => {
    let shouldAppendAmpersand = false;
    Object.entries(params).forEach(([key, value], index) => {
        if (value !== undefined && value !== null && value !== '' && value !== 'null' && value !== 'undefined') {
            url += !shouldAppendAmpersand ? `?${key}=${value}` : `&${key}=${value}`;
            shouldAppendAmpersand = true;
        }
    });

    return url;
};

export const isStringValidJSON = (str) => {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
};
