import BackgroundImage from '../../assets/welcomeScreenBackground.jpg';
import UnetiqLogo from '../../assets/unetiq_logo_horizontal_white.png';
import TelefonicaLogo from '../../assets/telefonica-logo.png';
import './style.css';
import { useAuth0 } from '@auth0/auth0-react';
import Dashboard from '../Dashboard';

function HomePage() {
    const { isAuthenticated } = useAuth0();

    return isAuthenticated ? (
        <Dashboard />
    ) : (
        <>
            <div
                style={{
                    background: `url(${BackgroundImage}) no-repeat fixed`,
                    paddingTop: '64px',
                    minHeight: 'calc(100vh - 64px)',
                    minWidth: '100vw',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                }}
            >
                <div className="text">
                    <h1>Welcome to Telefonica Callmart</h1>
                    <h2>For login please click on the top right</h2>
                </div>
                <div className="logoDiv">
                    <div className="unetiqLogo">
                        <img src={UnetiqLogo} alt="Unetiq Logo" />
                    </div>
                    <span>
                        <img src={TelefonicaLogo} alt="Telefonica Logo" />
                    </span>
                </div>
            </div>
        </>
    );
}

export default HomePage;
