import { Grid } from '@mui/material';
import ReactHookFormAutoComplete from '../ReactHookForm/ReactHookFormAutoComplete';
import ReactHookFormSelect from '../ReactHookForm/ReactHookFormSelect';
import { useHEMTLocations, useWorkingUnitLocations } from '../../hooks';
import { fieldIsRequiredMsg } from '../../utils/componentUtils';
import { useEffect } from 'react';

const WorkingUnitLocationFormFields = ({ form, isWorkingUnitMultiSelect = false }) => {
    const workingUnitFieldName = isWorkingUnitMultiSelect ? 'workingUnitLocations' : 'workingUnitLocation';
    const { formState, setValue, watch } = form;
    const selectedLocation = watch('location');

    const allLocationsFromHEMT = useHEMTLocations();
    const [workingUnitLocations] = useWorkingUnitLocations({ location: selectedLocation });
    const workingUnitOptions = workingUnitLocations.map((wuLoc) => ({
        ...wuLoc,
        label: `${wuLoc.workingUnit.worktype.worktype} - ${wuLoc.workingUnit.channel?.name} - ${wuLoc.workingUnit.task?.name}`,
    }));

    useEffect(() => {
        if (selectedLocation && selectedLocation !== formState.defaultValues.location) {
            setValue(workingUnitFieldName, isWorkingUnitMultiSelect ? [] : null);
        }
    }, [selectedLocation, workingUnitFieldName, setValue, isWorkingUnitMultiSelect, formState.defaultValues.location]);

    return (
        <>
            <Grid item xs={12}>
                <ReactHookFormSelect
                    name="location"
                    options={allLocationsFromHEMT}
                    mainProp="code"
                    secondProp="city"
                    label="Standort"
                    control={form.control}
                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                    formErrors={formState.errors}
                ></ReactHookFormSelect>
            </Grid>
            <Grid item xs={12}>
                <ReactHookFormAutoComplete
                    name={workingUnitFieldName}
                    control={form.control}
                    label="Working Unit *"
                    labelKeys={['label']}
                    options={workingUnitOptions}
                    multiple={isWorkingUnitMultiSelect}
                    rules={{ required: { value: true, message: fieldIsRequiredMsg } }}
                    formErrors={formState.errors}
                ></ReactHookFormAutoComplete>
            </Grid>
        </>
    );
};

export default WorkingUnitLocationFormFields;
