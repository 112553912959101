import { IconButton } from '@mui/material';

function ButtonIconRenderer({ icon: Icon, onClick, iconStyle, ...params }) {
    const handleClick = () => onClick && onClick(params);

    return (
        <IconButton
            onClick={handleClick}
            sx={{
                padding: 0,
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}
        >
            <Icon sx={{ ...iconStyle }} />
        </IconButton>
    );
}

export default ButtonIconRenderer;
