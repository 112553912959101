import { useQuery } from '@tanstack/react-query';
import {
    compareStrategicVolumeAndCapacityReport,
    getLastStrategicVolumeUploadDate,
    getStrategicVolumeReports,
    getStrategicVolumeReport,
} from '../../services/strategicVolumeReport';
import { QueryKey } from '../../enums/QueryKey';
import { getDateRangeFromStartDateByAddingMonths, formatLastUploadDate } from '../../utils/dateUtils';

export function getStrategicVolumeReportsQueryKey({ date, location, user, releaseType, numOfMonthColumns }) {
    const { dateFrom, dateTo } = getDateRangeFromStartDateByAddingMonths(date, numOfMonthColumns);

    return [
        QueryKey.StrategicVolumeReports,
        {
            dateFrom: dateFrom.format('YYYY-MM-DD HH:mm:ss'),
            dateTo: dateTo.format('YYYY-MM-DD HH:mm:ss'),
            location,
            user,
            releaseType,
        },
    ];
}

export function getStrategicVolumeAndCapacityReportComparisonQueryKey({
    date,
    location,
    user,
    releaseType,
    numOfMonthColumns,
}) {
    const { dateFrom, dateTo } = getDateRangeFromStartDateByAddingMonths(date, numOfMonthColumns);
    return [
        QueryKey.StrategicVolumeAndCapacityReportComparison,
        {
            dateFrom: dateFrom.format('YYYY-MM-DD HH:mm:ss'),
            dateTo: dateTo.format('YYYY-MM-DD HH:mm:ss'),
            location,
            user,
            releaseType,
        },
    ];
}

export function useStrategicVolumeReports({ date, location, user, releaseType, numOfMonthColumns }) {
    const { dateFrom, dateTo } = getDateRangeFromStartDateByAddingMonths(date, numOfMonthColumns);
    const queryKey = getStrategicVolumeReportsQueryKey({ date, location, user, releaseType, numOfMonthColumns });

    const { data, refetch } = useQuery({
        queryKey,
        queryFn: () => getStrategicVolumeReports(location, dateFrom, dateTo, user, releaseType),
        enabled: !!date && !!user,
        initialData: [],
    });

    return { data, refetch };
}

export function useLastStrategicVolumeReportUploadDate(location) {
    const { data } = useQuery({
        queryKey: [QueryKey.LastUploadDate + '-strategicVolumeReport', { location }],
        queryFn: () => getLastStrategicVolumeUploadDate(location),
        enabled: !!location,
    });

    return formatLastUploadDate(data);
}

export function useCompareStrategicVolumeAndCapacityReport({
    date,
    location,
    user,
    releaseType,
    numOfMonthColumns,
    showComparisonWithCapacityReport,
}) {
    const { dateFrom, dateTo } = getDateRangeFromStartDateByAddingMonths(date, numOfMonthColumns);
    const queryKey = getStrategicVolumeAndCapacityReportComparisonQueryKey({
        date,
        location,
        user,
        releaseType,
        numOfMonthColumns,
    });

    const { data, refetch } = useQuery({
        queryKey,
        queryFn: () => compareStrategicVolumeAndCapacityReport(location, dateFrom, dateTo, user, releaseType),
        enabled: showComparisonWithCapacityReport,
        initialData: [],
    });

    return { data, refetch };
}

export function useStrategicVolumeReport({ workingUnitLocation, date, enabled = true }) {
    const queryKey = [QueryKey.StrategicVolumeReport, { workingUnitLocation, date }];

    const { data: strategicVolumeReport } = useQuery({
        queryKey,
        queryFn: () => getStrategicVolumeReport({ workingUnitLocation, date }),
        enabled: !!workingUnitLocation && !!date && enabled,
    });

    return { strategicVolumeReport };
}
