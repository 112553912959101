import CustomAutoComplete from '../../UI/CustomAutoComplete';

const LocationAutocomplete = ({ selectedLocation, onChange, locationOptions, isMultiSelect }) => {
    const handleChangeSelectedLocations = (locations) => {
        if (Array.isArray(locations)) {
            let allLocationsObject = locations.find((location) => location.id === -1);
            if (locations.length > 1 && allLocationsObject) {
                if (locations.indexOf(allLocationsObject) === locations.length - 1) {
                    onChange([allLocationsObject]);
                    return;
                } else {
                    locations = locations.filter((location) => location.id !== allLocationsObject.id);
                }
            }
        }

        onChange(locations);
    };

    return (
        <CustomAutoComplete
            isMultiSelect={isMultiSelect}
            options={locationOptions}
            labelKeys={['code', 'city']}
            value={selectedLocation}
            label="Standorte"
            onChange={handleChangeSelectedLocations}
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    );
};

export default LocationAutocomplete;
