import { useState, useRef, useMemo } from 'react';
import { Grid, Button } from '@mui/material';
import Container from '../components/Layout/Container';
import GridContainer from '../components/Layout/GridContainer';
import {
    useLocationsByLoggedInUserRole,
    useCookieLocation,
    useCustomStyles,
    useDispatchSuccessNotification,
    useDispatchErrorNotification,
} from '../hooks';
import dayjs from 'dayjs';
import {
    useBiddingCalculated,
    useBiddingCalculatedResults,
    useLastBiddingCalculationDate,
} from '../hooks/api/biddingCalculated';

import { getAllDatesInAMonth } from '../utils/dateUtils';
import { createTableRows, getFooterRowStyle } from '../utils/agGridUtils';
import TableGridItem from '../components/UI/TableGridItem';
import Tabs from '../components/UI/Tabs';
import { queryClient } from '../services/queryClient';
import { QueryKey } from '../enums/QueryKey';
import LoadingButton from '@mui/lab/LoadingButton';
import ReleaseBiddingTotalsDialog from '../components/BiddingCalculated/ReleaseBiddingTotalsDialog';
import {
    createColumnDefs,
    createMapWithTableRows,
    defaultColumnDefs,
    filterChangedRows,
} from '../components/BiddingCalculated/helpers/utils';
import BiddingCalculatedFilters from '../components/BiddingCalculated/BiddingCalculatedFilters';
import LastTotalsReleaseDate from '../components/BiddingCalculated/LastTotalsReleaseDate';
import LastUpload from '../components/General/LastUpload';
import { useCorrectedTotals } from '../hooks/api/correctedTotals';
import { useMutation } from '@tanstack/react-query';
import { calculateCorrectedResults } from '../services/correctedResults';
import { useCorrectedResults } from '../hooks/api/correctedResults';
import BiddingCalculationBackgroundProcessDialog from '../components/BiddingCalculated/BiddingCalculationBackgroundProcessDialog';
import ExportTotalsButton from '../components/BiddingCalculated/ExportTotalsButton';
import { UserRole } from '../enums/UserRole';
import { calculateBidding, createBiddingCalculatedTotals } from '../services/biddingCalculated';
import ChangeTotalsButton from '../components/BiddingCalculated/ChangeTotalsButton';
import CompleteCalculationButton from '../components/BiddingCalculated/CompleteCalculationButton';
import { useCompletedCalculations } from '../hooks/api/completedCalculations';
import { usePublicHolidays } from '../hooks/api/publicHolidays';
import { useWorkingHoursByWorkingUnitLocation } from '../hooks/api/workingHours';

const Calculated = () => {
    const classes = useCustomStyles();
    const gridRef = useRef(null);
    const changeTotalsButtonRef = useRef(null);

    const { dispatchSuccessNotification } = useDispatchSuccessNotification();
    const { dispatchErrorNotification } = useDispatchErrorNotification();
    const [isSubsequentRelease, setIsSubsequentRelease] = useState(false);

    const [showReleaseTotalsDialog, setShowReleaseTotalsDialog] = useState(false);
    const [showBiddingCalculationBackgroundProcessDialog, setShowBiddingCalculationBackgroundProcessDialog] =
        useState(false);

    const [biddingCalculationInProgress, setBiddingCalculationInProgress] = useState(false);
    const [loadingCalculateCorrectedResults, setLoadingCalculateCorrectedResults] = useState(false);

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [locationOptions] = useLocationsByLoggedInUserRole();
    const [selectedLocation, setSelectedLocation] = useCookieLocation(locationOptions);
    const [selectedWorkingUnitLocation, setSelectedWorkingUnitLocation] = useState('');
    const allDatesInAMonth = getAllDatesInAMonth(selectedDate);

    const workingHours = useWorkingHoursByWorkingUnitLocation(selectedWorkingUnitLocation);
    const publicHolidays = usePublicHolidays();

    const [completedCalculations] = useCompletedCalculations({
        date: selectedDate,
        workingUnitLocation: selectedWorkingUnitLocation,
    });

    const lastBiddingCalculationDate = useLastBiddingCalculationDate(selectedWorkingUnitLocation);
    const { biddingCalculated } = useBiddingCalculated({
        date: selectedDate,
        workingUnitLocation: selectedWorkingUnitLocation,
    });

    const [biddingCalculatedResults] = useBiddingCalculatedResults({
        date: selectedDate,
        workingUnitLocation: selectedWorkingUnitLocation,
    });

    const { correctedTotals } = useCorrectedTotals({
        date: selectedDate,
        workingUnitLocation: selectedWorkingUnitLocation,
        released: true,
    });

    const { correctedResults } = useCorrectedResults({
        date: selectedDate,
        workingUnitLocation: selectedWorkingUnitLocation,
    });

    const tableRowsBiddingCalculated = useMemo(() => {
        return biddingCalculated.length > 0
            ? createTableRows(
                  createMapWithTableRows({
                      dates: allDatesInAMonth,
                      biddingCalculated,
                      workingHours,
                      publicHolidays,
                  }),
                  'slotTime'
              )
            : [];
    }, [biddingCalculated, allDatesInAMonth, workingHours, publicHolidays]);

    const tableRowsCorrectedTotals = useMemo(() => {
        return correctedTotals.length > 0
            ? createTableRows(
                  createMapWithTableRows({
                      dates: allDatesInAMonth,
                      correctedTotals,
                  }),
                  'slotTime'
              )
            : [];
    }, [correctedTotals, allDatesInAMonth]);

    const tableRowsBiddingCalculatedResults = useMemo(() => {
        return biddingCalculatedResults.length > 0
            ? createTableRows(
                  createMapWithTableRows({
                      dates: allDatesInAMonth,
                      biddingCalculatedResults,
                  }),
                  'slotTime'
              )
            : [];
    }, [biddingCalculatedResults, allDatesInAMonth]);

    const tableRowsCorrectedResults = useMemo(() => {
        return correctedResults.length > 0
            ? createTableRows(
                  createMapWithTableRows({
                      dates: allDatesInAMonth,
                      correctedResults,
                  }),
                  'slotTime'
              )
            : [];
    }, [correctedResults, allDatesInAMonth]);

    const handleCellValueChange = (params) => {
        if (completedCalculations.length > 0) {
            dispatchErrorNotification({
                message:
                    'Die Planung für diesen Zeitraum und Work Type ist bereits abgeschlossen. Es können keine Änderungen mehr vorgenommen werden.',
            });
            return;
        }

        if (changeTotalsButtonRef.current) {
            changeTotalsButtonRef.current.style.display = 'inline-block';
        }
    };

    const tabs = [
        {
            label: 'Percentages',
            tabPanel: (
                <TableGridItem
                    ref={gridRef}
                    rowData={tableRowsBiddingCalculated}
                    defaultColDef={defaultColumnDefs}
                    columnDefs={createColumnDefs({
                        allDatesInAMonth,
                        valuePropertyName: 'percentage',
                        showPercentageSign: true,
                    })}
                    columnHoverHighlight={true}
                />
            ),
        },
        {
            label: 'Totals',
            tabPanel: (
                <TableGridItem
                    ref={gridRef}
                    rowData={tableRowsBiddingCalculated}
                    defaultColDef={{ ...defaultColumnDefs, editable: true }}
                    columnDefs={createColumnDefs({
                        allDatesInAMonth,
                        valuePropertyName: 'total',
                        hasFooter: true,
                        alwaysAllowEdit: true,
                    })}
                    columnHoverHighlight={true}
                    grandTotalRow={true}
                    getRowStyle={getFooterRowStyle}
                    onCellValueChanged={handleCellValueChange}
                />
            ),
        },
        {
            label: 'Results',
            tabPanel: (
                <TableGridItem
                    ref={gridRef}
                    rowData={tableRowsBiddingCalculatedResults}
                    defaultColDef={defaultColumnDefs}
                    columnDefs={createColumnDefs({
                        allDatesInAMonth,
                        valuePropertyName: 'value',
                        showPercentageSign: true,
                    })}
                    columnHoverHighlight={true}
                />
            ),
        },
        {
            label: 'Totals - Korrektur',
            tabPanel: (
                <TableGridItem
                    ref={gridRef}
                    rowData={tableRowsCorrectedTotals}
                    defaultColDef={defaultColumnDefs}
                    columnDefs={createColumnDefs({
                        allDatesInAMonth,
                        valuePropertyName: 'total',
                        hasFooter: true,
                    })}
                    columnHoverHighlight={true}
                    grandTotalRow={true}
                    getRowStyle={getFooterRowStyle}
                />
            ),
            tabProps: {
                disabled: correctedTotals.length === 0,
            },
        },
        {
            label: 'Results - Korrektur',
            tabPanel: (
                <TableGridItem
                    ref={gridRef}
                    rowData={tableRowsCorrectedResults}
                    defaultColDef={defaultColumnDefs}
                    columnDefs={createColumnDefs({
                        allDatesInAMonth,
                        valuePropertyName: 'value',
                        showPercentageSign: true,
                    })}
                    columnHoverHighlight={true}
                />
            ),
        },
    ];

    const { mutate: handleBiddingCalculation } = useMutation({
        mutationFn: calculateBidding,
    });

    const { mutate: handleCorrectedResultsCalculation } = useMutation({
        mutationFn: calculateCorrectedResults,
        onSuccess: () => {
            queryClient.invalidateQueries([
                QueryKey.CorrectedResults,
                { date: selectedDate, workingUnitLocation: selectedWorkingUnitLocation },
            ]);
            queryClient.invalidateQueries([
                QueryKey.BiddingCalculatedResults,
                { date: selectedDate, workingUnitLocation: selectedWorkingUnitLocation },
            ]);
            queryClient.invalidateQueries([
                QueryKey.LastBiddingCalculationDate,
                { location: selectedLocation, workingUnitLocation: selectedWorkingUnitLocation },
            ]);
        },
        onSettled: () => {
            setLoadingCalculateCorrectedResults(false);
        },
    });

    const calculate = () => {
        setBiddingCalculationInProgress(true);
        setShowBiddingCalculationBackgroundProcessDialog(true);
        handleBiddingCalculation({ date: selectedDate });
    };

    const { mutate: handleChangeTotals } = useMutation({
        mutationFn: createBiddingCalculatedTotals,
        onSuccess: () => {
            queryClient.invalidateQueries([
                QueryKey.BiddingCalculated,
                { date: selectedDate, workingUnitLocation: selectedWorkingUnitLocation },
            ]);
            dispatchSuccessNotification({
                message: 'Die Änderungen wurden gespeichert',
            });
        },
        onError: (error) => {
            dispatchErrorNotification({
                message: error.message || 'Fehler beim Speichern der Änderungen',
            });
        },
    });

    const changeTotals = () => {
        const changedRows = filterChangedRows(tableRowsBiddingCalculated);
        const recordsToCreate = changedRows.map((row) => ({
            ...row,
            id: null,
        }));

        handleChangeTotals(recordsToCreate);
    };

    const handleReleaseTotalsDialogClose = () => {
        setShowReleaseTotalsDialog(false);
        setIsSubsequentRelease(false);
    };

    return (
        <Container>
            <GridContainer>
                <BiddingCalculatedFilters
                    locationOptions={locationOptions}
                    selectedLocation={selectedLocation}
                    onSelectedLocationChange={setSelectedLocation}
                    selectedWorkingUnitLocation={selectedWorkingUnitLocation}
                    onSelectedWorkingUnitLocationChange={setSelectedWorkingUnitLocation}
                    selectedDate={selectedDate}
                    onSelectedDateChange={setSelectedDate}
                ></BiddingCalculatedFilters>
                <Grid item xs={6} sm={4} md={2} xl={1}>
                    <LastUpload showInfoIcon={false} date={lastBiddingCalculationDate}></LastUpload>
                </Grid>
                <Grid item xs={6} sm={4} md={2} xl={1}>
                    <LastTotalsReleaseDate
                        text="Freigegeben am"
                        workingUnitLocation={selectedWorkingUnitLocation}
                    ></LastTotalsReleaseDate>
                </Grid>
                <Grid item xs={12} xl={5}>
                    <Button
                        onClick={calculate}
                        variant="contained"
                        disabled={biddingCalculationInProgress}
                        sx={classes.marginRight}
                    >
                        Calculate
                    </Button>
                    <Button
                        onClick={() => setShowReleaseTotalsDialog(true)}
                        variant="contained"
                        sx={classes.marginRight}
                    >
                        Freigeben
                    </Button>
                    <LoadingButton
                        onClick={() => {
                            setLoadingCalculateCorrectedResults(true);
                            handleCorrectedResultsCalculation({
                                dateFrom: dayjs(selectedDate).startOf('month'),
                                dateTo: dayjs(selectedDate).endOf('month'),
                                workingUnitLocation: selectedWorkingUnitLocation,
                            });
                        }}
                        variant="contained"
                        loading={loadingCalculateCorrectedResults}
                        sx={classes.marginRight}
                    >
                        <span>Korrektur Berechnen</span>
                    </LoadingButton>
                    <ChangeTotalsButton
                        ref={changeTotalsButtonRef}
                        onChangeTotals={changeTotals}
                        sx={classes.marginRight}
                    ></ChangeTotalsButton>
                    <CompleteCalculationButton
                        dateFilter={selectedDate}
                        workingUnitLocationFilter={selectedWorkingUnitLocation}
                    ></CompleteCalculationButton>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <ExportTotalsButton date={selectedDate} exportButtonType={UserRole.TEF}></ExportTotalsButton>
                </Grid>
                <Tabs tabs={tabs}></Tabs>
                {showReleaseTotalsDialog && (
                    <ReleaseBiddingTotalsDialog
                        open={showReleaseTotalsDialog}
                        onClose={handleReleaseTotalsDialogClose}
                        date={selectedDate}
                        isSubsequentRelease={isSubsequentRelease}
                        onSubsequentReleaseChange={setIsSubsequentRelease}
                    ></ReleaseBiddingTotalsDialog>
                )}
                {showBiddingCalculationBackgroundProcessDialog && (
                    <BiddingCalculationBackgroundProcessDialog
                        open={showBiddingCalculationBackgroundProcessDialog}
                        onClose={() => setShowBiddingCalculationBackgroundProcessDialog(false)}
                    ></BiddingCalculationBackgroundProcessDialog>
                )}
            </GridContainer>
        </Container>
    );
};

export default Calculated;
