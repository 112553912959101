import { Chip, Grid } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import getFrequencyLabel from './utils/getFrequencyLabel';

const EventRecurringInfo = ({ eventSerie }) => {
    let {
        start,
        end,
        frequency,
        weekDaysRepetition,
        repetitionIntervalDayOfWeek,
        repetitionIntervalDayOrdinalNumber,
        repetitionIntervalDayNumber,
        repetitionIntervalType,
        repetition,
    } = eventSerie;

    start = start && dayjs(start).format('DD.MM.YYYY');
    end = end && dayjs(end).format('DD.MM.YYYY');
    weekDaysRepetition =
        weekDaysRepetition &&
        Array.isArray(weekDaysRepetition) &&
        weekDaysRepetition
            .sort()
            .map((day) => dayjs().day(day).format('dd'))
            .join(', ');

    repetitionIntervalDayOfWeek = repetitionIntervalDayOfWeek && dayjs().day(repetitionIntervalDayOfWeek).format('dd');
    if (frequency === 'year')
        repetition =
            repetition &&
            dayjs()
                .month(repetition - 1)
                .format('MMMM');

    return (
        <Grid container spacing={1} sx={{ mt: 1, ml: 2 }}>
            <Grid item>
                <Chip color="primary" size="small" variant="outlined" label={`von ${start} bis ${end}`} />
            </Grid>

            {frequency !== 'year' && repetition > 1 && (
                <Grid item>
                    <Chip color="primary" size="small" variant="outlined" label={`alle ${repetition}`} />
                </Grid>
            )}

            <Grid item>
                <Chip
                    color="primary"
                    size="small"
                    variant="outlined"
                    label={getFrequencyLabel(frequency, repetition)}
                />
            </Grid>

            {frequency === 'week' && (
                <Grid item>
                    <Chip color="primary" size="small" variant="outlined" label={weekDaysRepetition} />
                </Grid>
            )}

            {frequency === 'year' && (
                <Grid item>
                    <Chip color="primary" size="small" variant="outlined" label={repetition} />
                </Grid>
            )}

            {frequency !== 'week' && repetitionIntervalType === 'DAY_NUMBER_REPETITION' && (
                <Grid item>
                    <Chip
                        color="primary"
                        size="small"
                        variant="outlined"
                        label={`am ${repetitionIntervalDayNumber} Tag (e)`}
                    />
                </Grid>
            )}
            {frequency !== 'week' && repetitionIntervalType === 'DAY_ORDINAL_NUMBER_REPETITION' && (
                <Grid item>
                    <Chip
                        color="primary"
                        size="small"
                        variant="outlined"
                        label={`am ${repetitionIntervalDayOrdinalNumber} ${repetitionIntervalDayOfWeek}`}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default EventRecurringInfo;
