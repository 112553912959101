import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';
import { fetchReadableStream } from './fetchReadableStream';

const BASE = '/corrected-totals';

export const uploadCorrectedTotals = async ({ data, setPercentage }) => {
    return await fetchReadableStream(`${BASE}/upload`, { data }, setPercentage);
};

export const getCorrectedOrReleasedBiddingCalculatedTotals = async ({ date, location, workingUnitLocation } = {}) => {
    let url = `${BASE}`;
    url = appendQueryParamsToUrl(url, { date, location, workingUnitLocation });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const getCorrectedTotals = async ({ dateFrom, dateTo, workingUnitLocation, released }) => {
    let url = `${BASE}`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo, workingUnitLocation, released });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const getSumOfCorrectedTotals = async ({ dateFrom, dateTo, workingUnitLocation } = {}) => {
    let url = `${BASE}/sum`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo, workingUnitLocation });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const getLastCorrectedTotalsUploadDate = async ({ workingUnitLocation } = {}) => {
    let url = `${BASE}/last-upload-date`;
    url = appendQueryParamsToUrl(url, { workingUnitLocation });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const releaseCorrectedTotals = async ({ dateFrom, dateTo, workingUnitLocation }) => {
    let url = `${BASE}/release`;
    return api.post(url, { dateFrom, dateTo, workingUnitLocation }, await setToken()).then((res) => res.data);
};

export const getLastCorrectedTotalsReleaseDate = async ({ workingUnitLocation } = {}) => {
    let url = `${BASE}/last-release-date`;
    url = appendQueryParamsToUrl(url, { workingUnitLocation });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const getCorrectedTotalsForLocation = async ({ dateFrom, dateTo, location }) => {
    let url = `${BASE}/by-location`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo, location });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const createCorrectedTotalsAndResetPreviousToReleased = async (data) => {
    return api.post(`${BASE}`, { data }, await setToken()).then((res) => res.data);
};
