import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../enums/QueryKey';
import { getDateRangeFromStartDateByAddingMonths, formatLastUploadDate } from '../../utils/dateUtils';
import {
    getLastPerformedCapacityReportUploadDate,
    getPerformedCapacityReport,
} from '../../services/performedCapacityReport';

export function getPerformedCapacityReportQueryKey({ date, location, plausibilityCheck, numOfMonthColumns }) {
    const { dateFrom, dateTo } = getDateRangeFromStartDateByAddingMonths(date, numOfMonthColumns);

    return [
        QueryKey.PerformedCapacityReport,
        {
            dateFrom: dateFrom.format('YYYY-MM-DD HH:mm:ss'),
            dateTo: dateTo.format('YYYY-MM-DD HH:mm:ss'),
            location,
            plausibilityCheck,
        },
    ];
}

export function usePerformedCapacityReport({ date, location, plausibilityCheck, numOfMonthColumns }) {
    const { dateFrom, dateTo } = getDateRangeFromStartDateByAddingMonths(date, numOfMonthColumns);
    const queryKey = getPerformedCapacityReportQueryKey({ date, location, plausibilityCheck, numOfMonthColumns });

    const { data, refetch } = useQuery({
        queryKey,
        queryFn: () => getPerformedCapacityReport({ location, dateFrom, dateTo, plausibilityCheck }),
        enabled: !!date && !!location,
        initialData: [],
    });

    return { data, refetch };
}

export function useLastPerformedCapacityReportUploadDate(location) {
    const { data } = useQuery({
        queryKey: [QueryKey.LastUploadDate + '-performedCapacityReport', { location }],
        queryFn: () => getLastPerformedCapacityReportUploadDate(location),
        enabled: !!location,
    });

    return formatLastUploadDate(data);
}
