import { MenuItem } from '@mui/material';

const NoOptionsItem = () => {
    return (
        <MenuItem disabled value="">
            Keine Optionen
        </MenuItem>
    );
};

export default NoOptionsItem;
