import { useContext, useState } from 'react';
import { getAllCapacityReportsForPeriod } from '../../services/capacityReport';
import { orderBy } from 'lodash';
import UserContext from '../../store/User/UserContext';
import { CapacityReportType } from '../../enums/CapacityReportType';
import { getDateOptionsForExportDialog } from './helpers/utils';
import { getPerformedCapacityReport } from '../../services/performedCapacityReport';
import SelectExportDateRangeDialog from '../Dialogs/SelectExportDateRangeDialog';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const exportColumnNames = [
    'Stadt',
    'Worktype',
    'Channel',
    'Task',
    'Monat',
    'Capacity Name',
    'Einheit',
    'Capacity Value',
];

function SelectExportCapacitiesDateRangeDialog({ locations, open, onClose, reportType }) {
    const loggedInUser = useContext(UserContext);

    const dialogTitle = `Export der ${
        reportType === CapacityReportType.CAPACITY_REPORT ? 'Potenzialmeldung' : 'IST-Meldung'
    }`;
    const { previousMonths: startDateOptions, nextMonths: endDateOptions } = getDateOptionsForExportDialog({
        reportType,
    });
    const startDate =
        reportType === CapacityReportType.CAPACITY_REPORT
            ? startDateOptions[0].month
            : startDateOptions[startDateOptions.length - 1].month;
    const endDate =
        reportType === CapacityReportType.CAPACITY_REPORT ? endDateOptions[1].month : endDateOptions[0].month;

    const [selectedStartDate, setSelectedStartDate] = useState(startDate);
    const [selectedEndDate, setSelectedEndDate] = useState(endDate);

    const getDataFromDb = async () => {
        const dateFrom = dayjs.utc(selectedStartDate).startOf('month');
        const dateTo = dayjs.utc(selectedEndDate).endOf('month');

        const location = loggedInUser.role.callmart_tef ? null : locations.map((location) => location.id);

        if (reportType === CapacityReportType.PERFORMED_CAPACITY_REPORT) {
            return await getPerformedCapacityReport({ location, dateFrom, dateTo });
        }

        return await getAllCapacityReportsForPeriod({ dateFrom, dateTo, location });
    };

    const getExportData = (data) => {
        let exportData = [];
        data.forEach((capacity) => {
            const location = locations.find(
                (location) => location.id === capacity.workingUnitLocation.worktypeLocation.locationId
            );

            let reports =
                reportType === CapacityReportType.PERFORMED_CAPACITY_REPORT
                    ? capacity.performedCapacityReports
                    : capacity.capacityReports;
            reports = orderBy(reports, ['date'], ['asc']);
            reports.forEach((capacityReport) => {
                exportData.push({
                    capacityId: capacity.id,
                    capacityTypeId: capacity.capacityTypeId,
                    capacityTypeName: capacity.capacityType.name,
                    capacityTypeUnit: capacity.capacityType.unit,
                    capacityReportValue: capacityReport?.value ?? null,
                    code: location?.code ?? null,
                    city: location?.city ?? null,
                    worktype: capacity?.workingUnitLocation?.workingUnit?.worktype?.worktype,
                    channel: capacity?.workingUnitLocation?.workingUnit?.channel?.name,
                    task: capacity?.workingUnitLocation?.workingUnit?.task?.name,
                    date: capacityReport.date,
                });
            });
        });

        return exportData;
    };

    const createExcelRowForExport = (exportDataRow) => {
        let excelRow = [];

        excelRow = [
            exportDataRow.code + ' - ' + exportDataRow.city,
            exportDataRow.worktype,
            exportDataRow.channel,
            exportDataRow.task,
            dayjs(exportDataRow.date).startOf('month').format('DD.MM.YYYY'),
            exportDataRow.capacityTypeName,
            exportDataRow.capacityTypeUnit,
            exportDataRow.capacityReportValue,
        ];

        return excelRow;
    };

    return (
        <SelectExportDateRangeDialog
            open={open}
            onClose={onClose}
            selectedStartDate={selectedStartDate}
            onStartDateChange={setSelectedStartDate}
            selectedEndDate={selectedEndDate}
            onEndDateChange={setSelectedEndDate}
            startDateOptions={startDateOptions}
            endDateOptions={endDateOptions}
            createExcelRowForExport={createExcelRowForExport}
            getExportData={getExportData}
            getDataFromDb={getDataFromDb}
            createRowFromExportData={createExcelRowForExport}
            exportedDataType={
                reportType === CapacityReportType.PERFORMED_CAPACITY_REPORT
                    ? 'Perfomed_Capacities_Report'
                    : 'Capacities_Report'
            }
            dialogTitle={dialogTitle}
            exportColumnNames={exportColumnNames}
            dialogText="Bitte wähle aus für welchen Zeitraum die Potenzialmeldungen exportiert werden sollen:"
        ></SelectExportDateRangeDialog>
    );
}

export default SelectExportCapacitiesDateRangeDialog;
