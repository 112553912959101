import { Typography } from '@mui/material';
import { useLastBiddingCalculatedTotalsReleaseDate } from '../../hooks/api/biddingCalculated';
import { useLastCorrectedTotalsUploadDate } from '../../hooks/api/correctedTotals';

const LastTotalsReleaseDate = ({ text, workingUnitLocation, showCorrectedTotalsUploadDateIfPresent = false }) => {
    const lastBiddingCalculatedTotalsReleaseDate = useLastBiddingCalculatedTotalsReleaseDate(workingUnitLocation);
    const lastCorrectedTotalsUploadDate = useLastCorrectedTotalsUploadDate(workingUnitLocation);

    const date = showCorrectedTotalsUploadDateIfPresent
        ? lastCorrectedTotalsUploadDate
        : lastBiddingCalculatedTotalsReleaseDate;

    const displayedText = text
        ? text
        : showCorrectedTotalsUploadDateIfPresent && lastCorrectedTotalsUploadDate
        ? 'Letzter Upload'
        : 'Letzte Änderung';

    return (
        <Typography>
            {displayedText}: <span style={{ display: 'block' }}>{date}</span>
        </Typography>
    );
};

export default LastTotalsReleaseDate;
