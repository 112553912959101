import { useState, useRef } from 'react';
import BiddingCalculatedFilters from '../components/BiddingCalculated/BiddingCalculatedFilters';
import Container from '../components/Layout/Container';
import GridContainer from '../components/Layout/GridContainer';
import TableGridItem from '../components/UI/TableGridItem';
import {
    defaultColumnDefs,
    createColumnDefs,
    createMapWithTableRows,
    filterChangedRows,
} from '../components/BiddingCalculated/helpers/utils';
import {
    useLocationsByLoggedInUserRole,
    useCookieLocation,
    useAlertMessage,
    useDispatchSuccessNotification,
    useDispatchErrorNotification,
} from '../hooks';
import dayjs from 'dayjs';
import { getAllDatesInAMonth } from '../utils/dateUtils';
import { createTableRows, getFooterRowStyle } from '../utils/agGridUtils';
import { Grid, Button } from '@mui/material';
import LastTotalsReleaseDate from '../components/BiddingCalculated/LastTotalsReleaseDate';
import { uploadCorrectedTotals, createCorrectedTotalsAndResetPreviousToReleased } from '../services/correctedTotals';
import UploadXlsxFileButton from '../components/Buttons/UploadXlsxFileButton';
import { parseXLSXData } from '../utils/xlsxUtils';
import { useMutation } from '@tanstack/react-query';
import {
    parseUploadResponse,
    setCommonStateAfterDataUpload,
    showErrorUploadNotification,
} from '../utils/componentUtils';
import PercentageLoadingOverlay from '../components/Loaders/PercentageLoadingOverlay';
import * as Sentry from '@sentry/react';
import CompareTotalsWithStrategicVolume from '../components/BiddingCalculated/CompareTotalsWithStrategicVolume';
import { QueryKey } from '../enums/QueryKey';
import { queryClient } from '../services/queryClient';
import LastCorrectedTotalsReleaseDate from '../components/BiddingCalculated/LastCorrectedTotalsReleaseDate';
import Tabs from '../components/UI/Tabs';
import { useCorrectedTotals } from '../hooks/api/correctedTotals';
import { useBiddingCalculated } from '../hooks/api/biddingCalculated';
import ExportTotalsButton from '../components/BiddingCalculated/ExportTotalsButton';
import { UserRole } from '../enums/UserRole';
import CorrectedTotalsUploadResDialog from '../components/BiddingCalculated/CorrectedTotalsUploadResDialog';
import ReleaseCorrectedTotalsDialog from '../components/BiddingCalculated/ReleaseCorrectedTotalsDialog';
import ChangeTotalsButton from '../components/BiddingCalculated/ChangeTotalsButton';
import { useCompletedCalculations } from '../hooks/api/completedCalculations';
import { useWorkingHoursByWorkingUnitLocation } from '../hooks/api/workingHours';
import { usePublicHolidays } from '../hooks/api/publicHolidays';

const OSPBiddingCalculatedTotals = () => {
    const gridRef = useRef(null);
    const changeTotalsButtonRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const { dispatchAlert } = useAlertMessage();
    const { dispatchSuccessNotification } = useDispatchSuccessNotification();
    const { dispatchErrorNotification } = useDispatchErrorNotification();

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [locationOptions] = useLocationsByLoggedInUserRole();
    const [selectedLocation, setSelectedLocation] = useCookieLocation(locationOptions);
    const [selectedWorkingUnitLocation, setSelectedWorkingUnitLocation] = useState('');
    const [compareTotalsWithStrategicVolume, setCompareTotalsWithStrategicVolume] = useState(false);
    const allDatesInAMonth = getAllDatesInAMonth(selectedDate);

    const [showReleaseCorrectedTotalsDialog, setShowReleaseCorrectedTotalsDialog] = useState(false);
    const [showUploadResDialog, setShowUploadResDialog] = useState(false);
    const [uploadResponse, setUploadResponse] = useState({});

    const workingHours = useWorkingHoursByWorkingUnitLocation(selectedWorkingUnitLocation);
    const publicHolidays = usePublicHolidays();

    const { biddingCalculated } = useBiddingCalculated({
        date: selectedDate,
        workingUnitLocation: selectedWorkingUnitLocation,
        released: true,
    });

    const { correctedTotals } = useCorrectedTotals({
        date: selectedDate,
        workingUnitLocation: selectedWorkingUnitLocation,
    });

    const [completedCalculations] = useCompletedCalculations({
        date: selectedDate,
        workingUnitLocation: selectedWorkingUnitLocation,
    });

    const tableRowsBiddingCalculated =
        biddingCalculated.length > 0
            ? createTableRows(
                  createMapWithTableRows({
                      dates: allDatesInAMonth,
                      biddingCalculated: biddingCalculated || [],
                  }),
                  'slotTime'
              )
            : [];

    const tableRowsCorrectedTotals =
        correctedTotals?.length > 0 && workingHours?.length > 0 && publicHolidays?.length > 0
            ? createTableRows(
                  createMapWithTableRows({
                      dates: allDatesInAMonth,
                      correctedTotals: correctedTotals || [],
                      workingHours,
                      publicHolidays,
                  }),
                  'slotTime'
              )
            : [];

    const tableRowsDifference =
        biddingCalculated.length > 0 || correctedTotals.length > 0
            ? createTableRows(
                  createMapWithTableRows({
                      dates: allDatesInAMonth,
                      biddingCalculated: biddingCalculated || [],
                      correctedTotals: correctedTotals || [],
                  }),
                  'slotTime'
              )
            : [];

    const { mutateAsync: handleUploadCorrectedTotals } = useMutation({
        mutationFn: uploadCorrectedTotals,
    });

    const uploadFiles = (files, closeDropzoneDialog) => {
        setLoading(true);

        const file = files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = parseXLSXData(e, 'workingUnit', true);
            handleUploadCorrectedTotals({ data, setPercentage })
                .then((res) => {
                    const resData = parseUploadResponse(res, 'Corrected Totals');

                    if (resData.error) {
                        showErrorUploadNotification({
                            dispatchAlert,
                            errorMessage: resData.message,
                        });
                    } else {
                        setShowUploadResDialog(true);
                        setUploadResponse(resData);
                    }
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    showErrorUploadNotification({
                        dispatchAlert,
                        errorMessage: error.message,
                    });
                })
                .finally(() => {
                    setCommonStateAfterDataUpload(setLoading, setPercentage, closeDropzoneDialog);
                    queryClient.invalidateQueries([
                        QueryKey.CorrectedTotals,
                        { date: selectedDate, workingUnitLocation: selectedWorkingUnitLocation },
                    ]);
                });
        };
        reader.readAsArrayBuffer(file);
    };

    const handleCellValueChange = () => {
        if (completedCalculations.length > 0) {
            dispatchErrorNotification({
                message:
                    'Die Planung für diesen Zeitraum und Work Type ist bereits abgeschlossen. Es können keine Änderungen mehr vorgenommen werden.',
            });
            return;
        }
        if (changeTotalsButtonRef.current) {
            changeTotalsButtonRef.current.style.display = 'inline-block';
        }
    };

    const tabs = [
        {
            label: 'Totals',
            tabPanel: (
                <TableGridItem
                    ref={gridRef}
                    rowData={tableRowsBiddingCalculated}
                    defaultColDef={defaultColumnDefs}
                    columnDefs={createColumnDefs({
                        allDatesInAMonth,
                        valuePropertyName: 'total',
                        hasFooter: true,
                    })}
                    columnHoverHighlight={true}
                    grandTotalRow={true}
                    getRowStyle={getFooterRowStyle}
                />
            ),
        },
        {
            label: 'Totals - Korrektur',
            tabPanel: (
                <TableGridItem
                    ref={gridRef}
                    rowData={tableRowsCorrectedTotals}
                    defaultColDef={{ ...defaultColumnDefs, editable: true }}
                    columnDefs={createColumnDefs({
                        allDatesInAMonth,
                        valuePropertyName: 'total',
                        hasFooter: true,
                        alwaysAllowEdit: false,
                    })}
                    columnHoverHighlight={true}
                    grandTotalRow={true}
                    getRowStyle={getFooterRowStyle}
                    onCellValueChanged={handleCellValueChange}
                />
            ),
        },
        {
            label: 'Differenz',
            tabPanel: (
                <TableGridItem
                    ref={gridRef}
                    rowData={tableRowsDifference}
                    defaultColDef={defaultColumnDefs}
                    columnDefs={createColumnDefs({
                        allDatesInAMonth,
                        valuePropertyName: 'difference',
                        hasFooter: true,
                    })}
                    columnHoverHighlight={true}
                    grandTotalRow={true}
                    getRowStyle={getFooterRowStyle}
                />
            ),
        },
    ];

    const { mutate: handleChangeTotals } = useMutation({
        mutationFn: createCorrectedTotalsAndResetPreviousToReleased,
        onSuccess: () => {
            queryClient.invalidateQueries([
                QueryKey.CorrectedTotals,
                { date: selectedDate, workingUnitLocation: selectedWorkingUnitLocation },
            ]);
            dispatchSuccessNotification({
                message: 'Die Änderungen wurden gespeichert',
            });
        },
        onError: (error) => {
            dispatchErrorNotification({
                message: error.message || 'Fehler beim Speichern der Änderungen',
            });
        },
    });

    const changeTotals = () => {
        const changedRows = filterChangedRows(tableRowsCorrectedTotals);
        const totalsToCreate = changedRows.map((row) => ({ ...row, id: null }));
        const totalIdsToReset = changedRows.map((row) => row.id);

        handleChangeTotals({ totalsToCreate, totalIdsToReset, workingUnitLocation: selectedWorkingUnitLocation });
    };

    return (
        <Container>
            <GridContainer>
                <BiddingCalculatedFilters
                    locationOptions={locationOptions}
                    selectedLocation={selectedLocation}
                    onSelectedLocationChange={setSelectedLocation}
                    selectedWorkingUnitLocation={selectedWorkingUnitLocation}
                    onSelectedWorkingUnitLocationChange={setSelectedWorkingUnitLocation}
                    selectedDate={selectedDate}
                    onSelectedDateChange={setSelectedDate}
                ></BiddingCalculatedFilters>
                <Grid item xs={5} sm={4} md={2} xl={1}>
                    <LastTotalsReleaseDate
                        workingUnitLocation={selectedWorkingUnitLocation}
                        showCorrectedTotalsUploadDateIfPresent={true}
                    ></LastTotalsReleaseDate>
                </Grid>
                <Grid item xs={5} sm={4} md={2} xl={1}>
                    <LastCorrectedTotalsReleaseDate
                        workingUnitLocation={selectedWorkingUnitLocation}
                    ></LastCorrectedTotalsReleaseDate>
                </Grid>
                <CompareTotalsWithStrategicVolume
                    workingUnitLocation={selectedWorkingUnitLocation}
                    date={selectedDate}
                    compare={compareTotalsWithStrategicVolume}
                ></CompareTotalsWithStrategicVolume>
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    <ExportTotalsButton
                        date={selectedDate}
                        location={selectedLocation}
                        exportButtonType={UserRole.OSP}
                    ></ExportTotalsButton>

                    <UploadXlsxFileButton
                        style={{ marginLeft: '5px' }}
                        buttonName="Upload"
                        variant="contained"
                        uploadFiles={uploadFiles}
                        dialogTitle="Vorschläge hochladen"
                    ></UploadXlsxFileButton>
                    <Button
                        variant="contained"
                        style={{ marginLeft: '5px' }}
                        onClick={() => setCompareTotalsWithStrategicVolume(true)}
                    >
                        Abgleich Volumen
                    </Button>
                    <Button
                        variant="contained"
                        style={{ marginLeft: '5px', marginRight: '5px' }}
                        onClick={() => setShowReleaseCorrectedTotalsDialog(true)}
                    >
                        Speichern & Absenden
                    </Button>
                    <ChangeTotalsButton ref={changeTotalsButtonRef} onChangeTotals={changeTotals} />
                </Grid>
                <Tabs tabs={tabs}></Tabs>
            </GridContainer>
            {showUploadResDialog && (
                <CorrectedTotalsUploadResDialog
                    open={showUploadResDialog}
                    onClose={() => setShowUploadResDialog(false)}
                    response={uploadResponse}
                ></CorrectedTotalsUploadResDialog>
            )}
            {showReleaseCorrectedTotalsDialog && (
                <ReleaseCorrectedTotalsDialog
                    open={showReleaseCorrectedTotalsDialog}
                    onClose={() => setShowReleaseCorrectedTotalsDialog(false)}
                    date={selectedDate}
                    workingUnitLocation={selectedWorkingUnitLocation}
                ></ReleaseCorrectedTotalsDialog>
            )}
            <PercentageLoadingOverlay loading={loading} percentage={percentage}></PercentageLoadingOverlay>
        </Container>
    );
};

export default OSPBiddingCalculatedTotals;
