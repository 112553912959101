import { useContext, useEffect } from 'react';
import SelectExportDateRangeDialog from '../Dialogs/SelectExportDateRangeDialog';
import dayjs from 'dayjs';
import UserContext from '../../store/User/UserContext';

const StrategicVolumeExportDialog = ({
    open,
    onClose,
    startDate,
    onStartDateChange,
    endDate,
    onEndDateChange,
    createExcelRowForExport,
    getDataFromDb,
    getExportData,
    exportColumnNames,
}) => {
    const loggedInUser = useContext(UserContext);

    const numOfNextMonths = loggedInUser?.role.callmart_tef ? 12 : 6;
    const startDateOptions = Array.from({ length: 13 }, (_, i) => ({
        month: dayjs().subtract(i, 'months').format('YYYY-MM-DD'),
        monthName: dayjs().subtract(i, 'months').format('MMMM YYYY'),
    }));

    const endDateOptions = Array.from({ length: numOfNextMonths }, (_, i) => ({
        month: dayjs()
            .add(i + 1, 'months')
            .format('YYYY-MM-DD'),
        monthName: dayjs()
            .add(i + 1, 'months')
            .format('MMMM YYYY'),
    }));

    useEffect(() => {
        if (!startDate && startDateOptions.length > 0) {
            onStartDateChange(startDateOptions[0].month);
        }
        if (!endDate && endDateOptions.length > 0) {
            onEndDateChange(endDateOptions[1].month);
        }
    }, [startDate, endDate, startDateOptions, endDateOptions, onStartDateChange, onEndDateChange]);

    return (
        <SelectExportDateRangeDialog
            open={open}
            onClose={onClose}
            selectedStartDate={startDate}
            onStartDateChange={onStartDateChange}
            selectedEndDate={endDate}
            onEndDateChange={onEndDateChange}
            startDateOptions={startDateOptions}
            endDateOptions={endDateOptions}
            createExcelRowForExport={createExcelRowForExport}
            getExportData={getExportData}
            getDataFromDb={getDataFromDb}
            createRowFromExportData={createExcelRowForExport}
            exportedDataType="Strategischen Forecasts"
            dialogTitle="Export des Strategischen Forecasts"
            exportColumnNames={exportColumnNames}
            dialogText="Bitte wähle aus für welchen Zeitraum der strategische Forecast exportiert werden soll:"
        ></SelectExportDateRangeDialog>
    );
};

export default StrategicVolumeExportDialog;
