import CustomSelect from '../UI/CustomSelect';
import MenuItem from '@mui/material/MenuItem';
import { setCookie } from '../../utils';
import { getOptionLabelDependingOnLabelKeys } from '../../utils/componentUtils';

function CookieSelect({ selectedValue, options, onChange, valueKey, labelKeys, label, cookieName, ...props }) {
    const handleChange = (value) => {
        setCookie(cookieName, value, 1000);
        onChange(value);
    };

    return (
        <CustomSelect selectedValue={selectedValue} options={options} onChange={handleChange} label={label} {...props}>
            {options &&
                options.map((option) => (
                    <MenuItem value={option[valueKey]} key={option[valueKey]}>
                        <span className="notranslate">{getOptionLabelDependingOnLabelKeys(option, labelKeys)}</span>
                    </MenuItem>
                ))}
        </CustomSelect>
    );
}

export default CookieSelect;
