import { getSlotTimeColumnDef, addRowsWithStaticFieldNameInMap, getSlotTimeRowKey } from '../../../utils/agGridUtils';

const cellStyle = (params) => {
    if (!params.value || params.value <= 0) return { justifyContent: 'center' };

    return { color: 'red', justifyContent: 'center' };
};

const cellRenderer = (params) => {
    if (!params.value) return '';

    return (
        <span
            style={{
                padding: '0.5em 1em',
                backgroundColor: 'var(--primary)',
                borderRadius: '30px',
                color: 'var(--white)',
            }}
        >
            {params.value}
        </span>
    );
};

export const createColumnDefs = (locations, allLocationsFromHEMT) => {
    const columns = [
        getSlotTimeColumnDef(),
        {
            field: 'availableBookings',
            headerName: 'Verfügbar',
            maxWidth: 80,
            children: [
                {
                    field: 'availableBookings',
                    headerName: '',
                    valueFormatter: (params) => (params.value ? params.value : 0),
                    minWidth: 100,
                    cellClass: 'grid-cell-centered',
                    cellStyle: {
                        justifyContent: 'center',
                        fontWeight: 'bold',
                    },
                },
            ],
        },
    ];

    for (const location of locations) {
        let locationFromTelefonica = allLocationsFromHEMT.find((loc) => loc.id === location.locationId);
        columns.push({
            field: `locationId${location.locationId}`,
            headerName: locationFromTelefonica ? locationFromTelefonica.city : location.id,
            children: [
                {
                    field: `lastBookedBookingValueLocationId${location.locationId}`,
                    headerName: '',
                    valueFormatter: (params) => (params.value ? params.value : 0),
                    minWidth: 30,
                    resizable: true,
                    cellClass: 'grid-cell-centered',
                    cellStyle,
                },
                {
                    field: `oldestBookedBookingValueLocationId${location.locationId}`,
                    headerName: '',
                    resizable: false,
                    minWidth: 30,
                    cellRenderer,
                },
            ],
        });
    }
    return columns;
};

export const createMapWithTableRows = (availableBookingsList, bookedBookingsList) => {
    let rowsMap = new Map();
    rowsMap = addRowsWithStaticFieldNameInMap(availableBookingsList, rowsMap, 'availableBookings', 'availableBookings');
    rowsMap = addBookingsInMap(bookedBookingsList, rowsMap);

    return rowsMap;
};

export const addBookingsInMap = (list, rowsMap) => {
    for (const item of list) {
        const key = getSlotTimeRowKey(item.slotDate);

        if (rowsMap.has(key)) {
            rowsMap.set(key, {
                ...rowsMap.get(key),
                [`lastBookedBookingValueLocationId${item.locationId}`]: item.bookedBookings,
                [`oldestBookedBookingValueLocationId${item.locationId}`]: item.oldestBookedBookings,
                [`bookingIdLocationId${item.locationId}`]: item.bookingId,
            });
        } else {
            rowsMap.set(key, {
                [`lastBookedBookingValueLocationId${item.locationId}`]: item.bookedBookings,
                [`oldestBookedBookingValueLocationId${item.locationId}`]: item.oldestBookedBookings,
                [`bookingIdLocationId${item.locationId}`]: item.bookingId,
            });
        }
    }

    return rowsMap;
};
