import { useState } from 'react';
import {
    Grid,
    Typography,
    Alert,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Box,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';

const Dropzone = ({ accept, multiple, uploadedFiles, onFileUpload, maxSizeInMB }) => {
    const [dragover, setDragover] = useState(false);
    const [multipleFilesError, setMultipleFilesError] = useState(false);
    const [wrongFileTypeError, setWrongFileTypeError] = useState(false);
    const [wrongFileSizeError, setWrongFileSizeError] = useState(false);

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragover(true);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragover(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragover(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragover(false);

        setMultipleFilesError(false);
        setWrongFileTypeError(false);
        setWrongFileSizeError(false);

        const files = e.dataTransfer.files;
        handleFiles(files);
    };

    const handleFileChange = (e) => {
        const files = e.target.files;
        setMultipleFilesError(false);
        handleFiles(files);
    };

    const handleFiles = (files) => {
        if (!multiple && files.length > 1) {
            setMultipleFilesError(true);
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const acceptedFileTypes = accept.split(',').map((type) => type.trim().toLowerCase());
            if (!acceptedFileTypes.includes(file.type)) {
                setWrongFileTypeError(true);
                continue;
            }

            if (file.size > maxSizeInMB * 1024 * 1024) {
                setWrongFileSizeError(true);
                continue;
            }

            onFileUpload((prevFiles) => (multiple ? [...prevFiles, file] : [file]));
        }
    };

    const removeFile = (e, fileName) => {
        e.preventDefault();

        onFileUpload((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };

    return (
        <>
            <label htmlFor="file" style={{ cursor: 'pointer' }}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        border: '2px dashed #ccc',
                        padding: '4rem',
                        backgroundColor: dragover ? '#f5f5f5' : 'transparent',
                    }}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    {uploadedFiles.length === 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <input
                                id="file"
                                type="file"
                                accept={accept}
                                multiple={false}
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <label htmlFor="file">
                                <CloudUploadIcon sx={{ marginBottom: '1rem' }} fontSize="large" />
                            </label>
                            <Typography variant="body2" sx={{ fontSize: '0.8rem', textAlign: 'center' }}>
                                Datei hierhin ziehen oder hochladen
                            </Typography>
                        </Box>
                    )}
                    {uploadedFiles.length > 0 && (
                        <List>
                            {uploadedFiles.map((file) => (
                                <ListItem key={file.name} sx={{ margin: '1rem' }}>
                                    <ListItemText primary={file.name} secondary={`${file.size} bytes`} />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            onClick={(e) => {
                                                removeFile(e, file.name);
                                            }}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Grid>
            </label>
            {multipleFilesError && (
                <Alert severity="error" sx={{ margin: '1rem' }} onClose={() => setMultipleFilesError(false)}>
                    Es kann nur eine Datei hochgeladen werden.
                </Alert>
            )}
            {wrongFileTypeError && (
                <Alert severity="error" sx={{ margin: '1rem' }} onClose={() => setWrongFileTypeError(false)}>
                    Es können nur Dateien vom Typ {accept} hochgeladen werden.
                </Alert>
            )}
            {wrongFileSizeError && (
                <Alert severity="error" sx={{ margin: '1rem' }} onClose={() => setWrongFileSizeError(false)}>
                    Es können nur Dateien mit einer maximalen Größe von {maxSizeInMB} MB hochgeladen werden.
                </Alert>
            )}
        </>
    );
};

export default Dropzone;
