import ResponsiveDialog from '../UI/Dialogs/ResponsiveDialog';
import CustomSelect from '../UI/CustomSelect';
import { Typography, MenuItem, DialogActions, Button, Grid } from '@mui/material';
import dayjs from 'dayjs';
import ExportToExcelButton from '../Buttons/ExportToExcelButton';
import { useCustomStyles } from '../../hooks';
import GridContainer from '../Layout/GridContainer';

function SelectExportDateRangeDialog({
    open,
    onClose,
    startDateOptions,
    selectedStartDate,
    endDateOptions,
    selectedEndDate,
    onStartDateChange,
    onEndDateChange,
    exportedDataType,
    getDataFromDb,
    getExportData,
    createRowFromExportData,
    exportColumnNames,
    dialogTitle,
    dialogText,
}) {
    const classes = useCustomStyles();

    const handleClose = () => {
        onClose();
    };

    const handleSelectedStartDateChange = (month) => {
        onStartDateChange(month);
    };

    const handleSelectedEndDateChange = (month) => {
        onEndDateChange(month);
    };

    const getExportFileName = () => {
        const start = dayjs(selectedStartDate).format('MM.YYYY');
        const end = dayjs(selectedEndDate).format('MM.YYYY');

        const fileName = `${exportedDataType}_${start}_${end}.xlsx`;

        return fileName;
    };

    return (
        <ResponsiveDialog
            fullScreen={false}
            title={dialogTitle}
            onClose={handleClose}
            open={open}
            actionButtons={
                <DialogActions sx={{ ...classes.dialogActions, ...classes.marginBottom }}>
                    <Button onClick={handleClose} variant="outlined">
                        Abbrechen
                    </Button>
                    <ExportToExcelButton
                        columnNames={exportColumnNames}
                        sheetName="Report"
                        getFileName={getExportFileName}
                        wbTitle=""
                        wbSubject="Export Report"
                        createRowFromExportData={createRowFromExportData}
                        getDataFromDb={getDataFromDb}
                        getExportData={getExportData}
                    />
                </DialogActions>
            }
        >
            <GridContainer>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography style={{ marginBottom: '30px' }}>{dialogText}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6}>
                    <CustomSelect
                        selectedValue={selectedStartDate}
                        onChange={handleSelectedStartDateChange}
                        label="Start"
                    >
                        {startDateOptions &&
                            startDateOptions.map((month) => (
                                <MenuItem value={month.month} key={month.month}>
                                    {month.monthName}
                                </MenuItem>
                            ))}
                    </CustomSelect>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6}>
                    <CustomSelect selectedValue={selectedEndDate} onChange={handleSelectedEndDateChange} label="Ende">
                        {endDateOptions &&
                            endDateOptions.map((month) => (
                                <MenuItem value={month.month} key={month.month}>
                                    {month.monthName}
                                </MenuItem>
                            ))}
                    </CustomSelect>
                </Grid>
            </GridContainer>
        </ResponsiveDialog>
    );
}

export default SelectExportDateRangeDialog;
