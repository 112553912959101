import React from 'react';
import ExportToExcelButton from '../Buttons/ExportToExcelButton';

const exportColumnNames = [
    'Standort',
    'Worktype',
    'Kanal',
    'Name',
    'Kommentar',
    'Datum von',
    'Datum bis',
    'Uhrzeit von',
    'Uhrzeit bis',
    'Dauer',
    'Abschlag %',
    'Produktiv',
];

const getReductionValue = (reduction, increase) => (increase === false ? reduction * -1 : reduction);

const convertBooleanToIntegerIfExists = (value) => {
    let result = value;
    if (value) {
        result = 1;
    } else if (value === false) {
        result = 0;
    }

    return result;
};

const EventReductionExportButton = ({ recordType, rows, pinnedBottomRowData, start, end }) => {
    const createExcelRowForExportFile = (exportDataRow) => {
        const {
            location,
            worktype,
            name,
            comment,
            startDate,
            endDate,
            startHour,
            endHour,
            duration,
            reduction,
            channel,
            productive,
        } = exportDataRow;

        return [
            location,
            worktype,
            channel,
            name,
            comment,
            startDate,
            endDate,
            startHour,
            endHour,
            duration,
            reduction,
            productive,
        ];
    };

    const getExportFileName = () => {
        const startDate = start.format('YYYY-MM-DD');
        const endDate = end.format('YYYY-MM-DD');

        const recordName = recordType === 'reduction' ? 'Abschläge' : 'Events';
        return `${recordName}_${startDate}_${endDate}.xlsx`;
    };

    const getExportData = () => {
        let exportData = [];
        if (pinnedBottomRowData?.length && pinnedBottomRowData?.length > 0) {
            for (const row of rows) {
                for (const worktype of row.worktypes) {
                    const {
                        location,
                        channel,
                        name,
                        comment,
                        startDate,
                        endDate,
                        startHour,
                        endHour,
                        duration,
                        reduction,
                        productive,
                        increase,
                    } = row;

                    exportData = [
                        ...exportData,
                        {
                            location: location.code + ' - ' + location.city,
                            worktype: worktype?.worktype,
                            channel: channel.name,
                            name,
                            comment,
                            startDate,
                            endDate,
                            startHour,
                            endHour,
                            duration,
                            reduction: getReductionValue(reduction, increase),
                            productive: convertBooleanToIntegerIfExists(productive),
                        },
                    ];
                }
            }

            exportData = [
                ...exportData,
                {
                    location: pinnedBottomRowData[0].locationCity,
                    duration: pinnedBottomRowData[0].duration,
                    reduction: `${pinnedBottomRowData[0].reduction.toFixed(2)} %`,
                },
            ];
        }
        return exportData;
    };

    return (
        <ExportToExcelButton
            columnNames={exportColumnNames}
            sheetName="Tabelle1"
            getFileName={getExportFileName}
            wbTitle="Data"
            wbSubject="Export Data"
            createRowFromExportData={createExcelRowForExportFile}
            getExportData={getExportData}
        />
    );
};

export default EventReductionExportButton;
