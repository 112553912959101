import { useQuery } from '@tanstack/react-query';
import { getCorrectedResults } from '../../services/correctedResults';
import { QueryKey } from '../../enums/QueryKey';
import dayjs from 'dayjs';

export function useCorrectedResults({ date, workingUnitLocation }) {
    const dateFrom = dayjs(date).startOf('month');
    const dateTo = dayjs(date).endOf('month');

    const { data } = useQuery({
        queryKey: [QueryKey.CorrectedResults, { date, workingUnitLocation }],
        queryFn: () => getCorrectedResults({ dateFrom, dateTo, workingUnitLocation }),
        enabled: !!date && !!workingUnitLocation,
        initialData: [],
    });

    return { correctedResults: data };
}
