export const parseStringToFloat = (value) => {
    if (typeof value === 'string') {
        const parsedValue = parseFloat(value.replace(/\./g, '').replace(',', '.'));
        if (parsedValue !== null && parsedValue !== undefined) return parsedValue;
    }

    return value;
};

export const validateIsNumberNegative = (value) => {
    return parseFloat(value) > 0 || 'Der Wert muss größer als 0 sein';
};

export const isNumberDecimal = (value) => {
    return value % 1 !== 0;
};
