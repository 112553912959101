import CustomSelect from '../UI/CustomSelect';
import MenuItem from '@mui/material/MenuItem';
import { setCookie } from '../../utils';

function WorktypeSelect({ selectedWorktype, worktypeOptions, onChange, ...props }) {
    const handleChange = (value) => {
        onChange(value);
        setCookie('worktype', value, 1000);
    };

    return (
        <CustomSelect
            selectedValue={selectedWorktype}
            options={worktypeOptions}
            onChange={handleChange}
            label={'Worktype'}
            {...props}
        >
            {worktypeOptions &&
                worktypeOptions.map((worktype) => (
                    <MenuItem value={worktype.id} key={worktype.id}>
                        <span className="notranslate">{worktype.worktype}</span>
                    </MenuItem>
                ))}
        </CustomSelect>
    );
}

export default WorktypeSelect;
