import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import CustomSelect from '../UI/CustomSelect';
import dayjs from 'dayjs';
import { getNextSixMonths } from '../../utils/dateUtils';

const getMonths = () => {
    const allMonths = [dayjs(), ...getNextSixMonths()];
    const monthOptions = allMonths.map((month) => {
        return {
            value: month.format('YYYY-MM'),
            label: month.format('MMMM YYYY'),
        };
    });

    return monthOptions;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey[500]',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function SelectMonthDialog({ open, onSave, onClose, onChange, selectedValue }) {
    const monthOptions = getMonths();
    return (
        open && (
            <BootstrapDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                    Monat
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography style={{ marginBottom: '30px' }}>
                        Bitte wähle aus für welchen Monat du die Daten hochlädst:
                    </Typography>
                    <CustomSelect selectedValue={selectedValue} onChange={onChange} label="Month">
                        {monthOptions &&
                            monthOptions.map((month) => (
                                <MenuItem value={month.value} key={month.value}>
                                    {month.label}
                                </MenuItem>
                            ))}
                    </CustomSelect>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={onSave}>
                        OK
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        )
    );
}

export default SelectMonthDialog;
