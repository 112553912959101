import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../enums/QueryKey';
import {
    getAvailableAndBookedBookings,
    getAllAvailableBookingsByDateAndWorktype,
    getTotalBookedBookingsByDateRangeAndWorktype,
    getAllBookedBookingsByDateRangeGroupByDateAndLocation,
    getOldestAndLatestBookedBookingsByDateAndWorktype,
    getAllBookedBookingsByDateRangeWorktypeAndLocation,
    getSumOfBookedBookingsByDateAndLocationGroupByWorktype,
    getSumOfAllBookedBookingsByDateRangeWorktypeAndLocation,
    getAllBookedBookingsByDateRangeAndWorktype,
    getSumOfBookedBookingsByDateAndWorktypeGroupByLocation,
} from '../../services/bookingDelta';
import dayjs from 'dayjs';
import { ifDateLocationAndWorktype } from '../../utils/componentUtils';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export function useAllAvailableBookingsByDateAndWorktype({ dateFrom, dateTo, worktype }) {
    const { data, refetch } = useQuery({
        queryKey: [QueryKey.AllAvailableBookingsByDateAndWorktype, { dateFrom, dateTo, worktype }],
        queryFn: () => getAllAvailableBookingsByDateAndWorktype(dateFrom, dateTo, worktype),
        enabled: !!dateFrom && !!dateTo && !!worktype,
    });

    return { data, refetch };
}

export function useAvailableAndBookedBookings({ date, location, worktype }) {
    const dateFrom = dayjs(date).startOf('month');
    const dateTo = dayjs(date).endOf('month');

    const { data } = useQuery({
        queryKey: [QueryKey.AvailableAndBookedBookings, { dateFrom, dateTo, location, worktype }],
        queryFn: () => getAvailableAndBookedBookings({ dateFrom, dateTo, location, worktype }),
        enabled: ifDateLocationAndWorktype({ date, location, worktype }),
    });

    return { data };
}

export function useTotalBookedBookingsByDateRangeAndWorktype({ dateFrom, dateTo, worktype }) {
    const { data } = useQuery({
        queryKey: [QueryKey.TotalBookedBookingsByDateRangeAndWorktype, { dateFrom, dateTo, worktype }],
        queryFn: () => getTotalBookedBookingsByDateRangeAndWorktype(dateFrom, dateTo, worktype),
        enabled: !!dateFrom && !!dateTo && !!worktype,
        initialData: {
            total: 0,
            positive: 0,
            negative: 0,
        },
    });

    return data;
}

export function useAllBookedBookingsByDateRangeGroupByDateAndLocation({ dateFrom, dateTo }) {
    const { data } = useQuery({
        queryKey: [QueryKey.AllAvailableBookingsByDateAndWorktype, { dateFrom, dateTo }],
        queryFn: () => getAllBookedBookingsByDateRangeGroupByDateAndLocation(dateFrom, dateTo),
        enabled: !!dateFrom && !!dateTo,
        initialData: [],
    });

    return data;
}

export function useOldestAndLatestBookedBookingsByDateAndWorktype({ dateFrom, dateTo, worktype }) {
    const { data } = useQuery({
        queryKey: [QueryKey.OldestAndLatestBookedBookingsByDateAndWorktype, { dateFrom, dateTo }],
        queryFn: () => getOldestAndLatestBookedBookingsByDateAndWorktype(dateFrom, dateTo, worktype),
        enabled: !!dateFrom && !!dateTo && !!worktype,
        initialData: [],
    });

    return data;
}

export function useAllBookedBookingsByDateRangeWorktypeAndLocation({ dateFrom, dateTo, worktype, location }) {
    const { data } = useQuery({
        queryKey: [QueryKey.AllBookedBookingsByDateRangeWorktypeAndLocation, { dateFrom, dateTo }],
        queryFn: () => getAllBookedBookingsByDateRangeWorktypeAndLocation(dateFrom, dateTo, worktype, location),
        enabled: !!dateFrom && !!dateTo && !!worktype && !!location,
        initialData: [],
    });

    return [data];
}

export function useTotalBookedBookingsByDateAndLocationGroupByWorktype({
    dateFrom,
    dateTo,
    location,
    recordCreatedAt,
}) {
    const { data } = useQuery({
        queryKey: [
            QueryKey.TotalBookedBookingsByDateAndLocationGroupByWorktype,
            { dateFrom, dateTo, location, recordCreatedAt },
        ],
        queryFn: () =>
            getSumOfBookedBookingsByDateAndLocationGroupByWorktype(dateFrom, dateTo, location, recordCreatedAt),
        enabled: !!dateFrom && !!dateTo && !!location && !!recordCreatedAt,
        initialData: {
            total: 0,
            positive: 0,
            negative: 0,
        },
    });

    return [data];
}

export function useTotalBookedBookingsByDateRangeAndWorktypeAndLocation({
    dateFrom,
    dateTo,
    worktype,
    location,
    recordCreatedAt,
}) {
    const { data } = useQuery({
        queryKey: [
            QueryKey.TotalBookedBookingsByDateRangeAndWorktypeAndLocation,
            { dateFrom, dateTo, worktype, location, recordCreatedAt },
        ],
        queryFn: () =>
            getSumOfAllBookedBookingsByDateRangeWorktypeAndLocation(
                dateFrom,
                dateTo,
                worktype,
                location,
                recordCreatedAt
            ),
        enabled: !!dateFrom && !!dateTo && !!worktype && !!location,
        initialData: {
            positive: 0,
            negative: 0,
        },
    });

    return data;
}

export function useAllBookedBookingsByDateRangeAndWorktype({ dateFrom, dateTo, worktype }) {
    const { data } = useQuery({
        queryKey: [QueryKey.AllBookedBookingsByDateRangeAndWorktype, { dateFrom, dateTo }],
        queryFn: () => getAllBookedBookingsByDateRangeAndWorktype(dateFrom, dateTo, worktype),
        enabled: !!dateFrom && !!dateTo && !!worktype,
        initialData: [],
    });

    return [data];
}

export function useSumOfBookedBookingsByDateAndWorktypeGroupByLocation({
    dateFrom,
    dateTo,
    worktype,
    recordCreatedAt,
}) {
    const { data } = useQuery({
        queryKey: [
            QueryKey.SumOfBookedBookingsByDateAndWorktypeGroupByLocation,
            { dateFrom, dateTo, worktype, recordCreatedAt },
        ],
        queryFn: () =>
            getSumOfBookedBookingsByDateAndWorktypeGroupByLocation(dateFrom, dateTo, worktype, recordCreatedAt),
        enabled: !!dateFrom && !!dateTo && !!worktype,
        initialData: [],
    });

    return [data];
}
