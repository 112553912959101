import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';

const BASE = '/performedCapacityReport';

export const getPerformedCapacityReport = async ({ location, dateFrom, dateTo, plausibilityCheck }) => {
    let url = `${BASE}/report`;
    url = appendQueryParamsToUrl(url, {
        location,
        dateFrom,
        dateTo,
        plausibilityCheck,
    });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const createPerformedCapacityReport = async (data, userId, locationId) => {
    return api.post(`${BASE}/create`, { data, userId, locationId }, await setToken()).then((res) => res.data);
};

export const createOnePerformedCapacityReport = async (data) => {
    return api.post(`${BASE}/one`, { data }, await setToken()).then((res) => res.data);
};

export const getPerformedCapacityReportAnalysis = async ({
    location,
    worktype,
    channel,
    task,
    capacityType,
    planDate,
    planComparisonDate,
    firstMonthColumnDate,
}) => {
    let url = `${BASE}/performedCapacityReportAnalysis`;
    url = appendQueryParamsToUrl(url, {
        location,
        planDate,
        planComparisonDate,
        firstMonthColumnDate,
        worktype,
        channel,
        task,
        capacityType,
    });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const getPerformedCapacityReportUploadedDatesInMonth = async ({ location, date }) => {
    return api
        .get(`${BASE}/uploadedDatesInMonth?location=${location}&date=${date}`, await setToken())
        .then((res) => res.data);
};

export const checkPerformedCapacityReportPlausibility = async ({ data, locationId }) => {
    return api.post(`${BASE}/checkPlausibility`, { data, locationId }, await setToken()).then((res) => res.data);
};

export const getLastPerformedCapacityReportUploadDate = async (location) => {
    return api.get(`${BASE}/lastUploadDate?location=${location}`, await setToken()).then((res) => res.data);
};
