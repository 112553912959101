import { useState } from 'react';
import { Button } from '@mui/material';
import SelectMonthDialog from '../Dialogs/SelectMonthDialog';
import DropzoneDialog from '../Dropzone/DropzoneDialog';

function UploadXlsxFileButton({
    buttonName,
    monthForUploading,
    onChangeMonthForUploading,
    uploadFiles,
    dialogTitle,
    style,
}) {
    const [openDropzoneDialog, setOpenDropzoneDialog] = useState(false);

    const [openSelectMonthDialog, setOpenSelectMonthDialog] = useState(false);
    const [uploadedExcelFiles, setUploadedExcelFiles] = useState([]);

    const handleDropzoneDialogOpen = () => {
        setOpenDropzoneDialog(true);
    };

    const handleDropzoneDialogClose = () => {
        setUploadedExcelFiles([]);
        setOpenDropzoneDialog(false);
    };

    const handleDropzoneDialogSave = (files) => {
        if (files.length > 0) {
            if (monthForUploading) {
                setOpenSelectMonthDialog(true);
                setUploadedExcelFiles(files);
            } else {
                uploadFiles(files, handleDropzoneDialogClose);
            }
        }
    };

    const handleSelectMonthDialogClose = () => {
        setOpenSelectMonthDialog(false);
    };

    const handleSelectMonthDialogSave = () => {
        setOpenSelectMonthDialog(false);
        if (monthForUploading) {
            uploadFiles(uploadedExcelFiles, handleDropzoneDialogClose);
        }
    };

    const handleSelectMonthDialogChange = (month) => {
        onChangeMonthForUploading(month);
    };

    return (
        <>
            <Button onClick={handleDropzoneDialogOpen} variant="contained" style={style}>
                {buttonName}
            </Button>
            <DropzoneDialog
                open={openDropzoneDialog}
                onClose={handleDropzoneDialogClose}
                onUpload={handleDropzoneDialogSave}
                dialogTitle={dialogTitle}
                multiple={false}
                accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                uploadedFiles={uploadedExcelFiles}
                onUploadedFilesChange={setUploadedExcelFiles}
            ></DropzoneDialog>
            {monthForUploading && (
                <SelectMonthDialog
                    open={openSelectMonthDialog}
                    onSave={handleSelectMonthDialogSave}
                    onClose={handleSelectMonthDialogClose}
                    onChange={handleSelectMonthDialogChange}
                    selectedValue={monthForUploading}
                ></SelectMonthDialog>
            )}
        </>
    );
}

export default UploadXlsxFileButton;
