import { Card, CardContent } from '@mui/material';

function DashboardCard({ children }) {
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <CardContent>{children}</CardContent>
        </Card>
    );
}

export default DashboardCard;
