import { useState, useRef, useContext, useCallback, useEffect } from 'react';
import { Grid, Checkbox, Button } from '@mui/material';
import Container from '../components/Layout/Container';
import UserContext from '../store/User/UserContext';
import {
    useLocationsByLoggedInUserRole,
    useCookieLocation,
    useAlertMessage,
    useIsMounted,
    useCustomStyles,
} from '../hooks';
import ButtonIconRenderer from '../components/AgGrid/ButtonIconRenderer';
import { stringToNumberFormatter } from '../utils/agGridUtils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getUpcomingMonths, isStringInYearMonthFormat } from '../utils/dateUtils';
import { addRowsInMap } from '../components/StrategicVolume/helpers/utils';
import { createTableRows } from '../utils/agGridUtils';
import {
    createOneStrategicVolumeReport,
    createStrategicVolumeReport,
    markStrategicVolumeReportsRelease,
    uploadStrategicVolumes,
} from '../services/strategicVolumeReport';
import { CheckboxCellRenderer } from '../components/AgGrid/CheckboxCellRenderer/CheckboxCellRenderer';
import ConfirmationDialog from '../components/UI/Dialogs/ConfirmationDialog';
import { StrategicVolumeReportRelease } from '../enums/StrategicVolumeReportRelease';
import { updateStrategicVolume } from '../services/strategicVolume';
import MatchingCapacityReportTooltip from '../components/StrategicVolume/MatchingCapacityReportTooltip';
import dayjs from 'dayjs';
import { parseStringToFloat } from '../utils/numberUtils';
import { parseXLSXData } from '../utils/xlsxUtils';
import PercentageLoadingOverlay from '../components/Loaders/PercentageLoadingOverlay';
import UserRoleLocation from '../components/General/UserRoleLocation';
import UploadXlsxFileButton from '../components/Buttons/UploadXlsxFileButton';
import {
    setCommonStateAfterDataUpload,
    showSuccessfulUploadNotification,
    showErrorUploadNotification,
    showErrorNotification,
    parseUploadResponse,
} from '../utils/componentUtils';
import LastUpload from '../components/General/LastUpload';
import * as Sentry from '@sentry/react';
import TableGridItem from '../components/UI/TableGridItem';
import GridContainer from '../components/Layout/GridContainer';
import {
    getStrategicVolumeReportsQueryKey,
    useCompareStrategicVolumeAndCapacityReport,
    useLastStrategicVolumeReportUploadDate,
    useStrategicVolumeReports,
} from '../hooks/api/strategicVolumeReports';
import { queryClient } from '../services/queryClient';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '../enums/QueryKey';
import StrategicVolumeExportButton from '../components/StrategicVolume/StrategicVolumeExportButton';

export const createMapWithTableRows = (monthColumns, strategicVolumeReport, compareWithCapacityReport) => {
    let rowsMap = new Map();
    rowsMap = addRowsInMap({ strategicVolumes: strategicVolumeReport, monthColumns, compareWithCapacityReport });

    return rowsMap;
};

const isRowSelected = (row, selectedRows) => {
    return selectedRows.find((r) => parseInt(r.strategicVolumeId) === parseInt(row.id)) ? true : false;
};

const getStrategicVolumeAndReportsFromRow = (row) => {
    const strategicVolumeReports = [];
    for (const key of Object.keys(row)) {
        if (isStringInYearMonthFormat(key)) {
            const strategicVolumeReportId = row[key].strategicVolumeReportId;

            if (strategicVolumeReportId)
                strategicVolumeReports.push({ id: strategicVolumeReportId, release: row[key].release });
        }
    }

    return { strategicVolumeId: row.id, strategicVolumeReports };
};

const StrategicVolume = () => {
    const classes = useCustomStyles();

    const { dispatchAlert } = useAlertMessage();
    const loggedInUser = useContext(UserContext);
    const isTEFUser = loggedInUser?.isTEFUser;
    const NUM_OF_MONTH_COLUMNS = loggedInUser && loggedInUser.role.callmart_tef ? 18 : 7;

    const isForecasterFilterApplied = useRef(false);

    const isMounted = useIsMounted();
    const gridRef = useRef(null);
    const [locationOptions] = useLocationsByLoggedInUserRole();
    const [selectedLocation, setSelectedLocation] = useCookieLocation(locationOptions);
    const lastUploadDate = useLastStrategicVolumeReportUploadDate(selectedLocation);

    const [firstMonth, setFirstMonth] = useState(dayjs());
    const [showComparisonWithCapacityReport, setShowComparisonWithCapacityReport] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const [loading, setLoading] = useState(false);
    const [percentage, setPercentage] = useState(0);

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const releaseType = loggedInUser?.role?.callmart_tef ? null : StrategicVolumeReportRelease.RELEASED;
    const monthColumns = getUpcomingMonths(firstMonth, NUM_OF_MONTH_COLUMNS);

    const strategicVolumeReportQueryKey = getStrategicVolumeReportsQueryKey({
        date: firstMonth,
        location: selectedLocation,
        user: loggedInUser?.id,
        releaseType: isTEFUser ? null : StrategicVolumeReportRelease.RELEASED,
        numOfMonthColumns: NUM_OF_MONTH_COLUMNS,
    });

    const { data: strategicVolumeReports } = useStrategicVolumeReports({
        date: firstMonth,
        location: selectedLocation,
        user: loggedInUser?.id,
        releaseType,
        numOfMonthColumns: NUM_OF_MONTH_COLUMNS,
    });
    const { data: comparisonWithCapacityReport } = useCompareStrategicVolumeAndCapacityReport({
        date: firstMonth,
        location: selectedLocation,
        user: loggedInUser?.id,
        releaseType,
        numOfMonthColumns: NUM_OF_MONTH_COLUMNS,
        showComparisonWithCapacityReport,
    });

    const tableRowsToShow =
        showComparisonWithCapacityReport && comparisonWithCapacityReport.length > 0
            ? comparisonWithCapacityReport
            : strategicVolumeReports;
    const tableRows =
        tableRowsToShow.length > 0
            ? createTableRows(
                  createMapWithTableRows(monthColumns, tableRowsToShow, showComparisonWithCapacityReport),
                  'id'
              )
            : [];

    const { mutateAsync: handleCreateStrategicVolumeReport } = useMutation({
        mutationFn: createStrategicVolumeReport,
    });
    const { mutateAsync: handleCreateOneStrategicVolumeReport } = useMutation({
        mutationFn: createOneStrategicVolumeReport,
    });
    const { mutateAsync: handleUploadStrategicVolumes } = useMutation({
        mutationFn: uploadStrategicVolumes,
    });

    const { mutateAsync: handleMarkStrategicVolumeReportsRelease } = useMutation({
        mutationFn: markStrategicVolumeReportsRelease,
    });

    const goBackMonth = useCallback(
        (numOfMonths) => {
            const previousMonth = dayjs(firstMonth, 'YYYY-MM-DD').subtract(numOfMonths, 'months');
            setFirstMonth(previousMonth);
        },
        [firstMonth]
    );

    const goForwardMonth = useCallback(
        (numOfMonths) => {
            const nextMonth = dayjs(firstMonth, 'YYYY-MM-DD').add(numOfMonths, 'months');
            setFirstMonth(nextMonth);
        },
        [firstMonth]
    );

    const selectRow = useCallback(
        (params) => {
            const updatedRows = [...selectedRows];
            const rowIndex = updatedRows.findIndex(
                (row) => parseInt(row.strategicVolumeId) === parseInt(params.data.id)
            );
            if (rowIndex === -1) {
                const { strategicVolumeId, strategicVolumeReports } = getStrategicVolumeAndReportsFromRow(params.data);

                updatedRows.push({ strategicVolumeId, strategicVolumeReports });
            } else {
                updatedRows.splice(rowIndex, 1);
            }

            setSelectedRows(updatedRows);
        },
        [selectedRows]
    );

    const selectAllRows = (e) => {
        const checked = e.target.checked;
        const updatedRows = [];
        if (checked) {
            gridRef.current.api.forEachNode((rowNode) => {
                const { strategicVolumeId, strategicVolumeReports } = getStrategicVolumeAndReportsFromRow(rowNode.data);

                updatedRows.push({ strategicVolumeId, strategicVolumeReports });
            });
        }

        setSelectedRows(updatedRows);
    };

    const createColumnDefs = useCallback(() => {
        let columns = [
            {
                field: 'id',
                headerName: 'Freigabe',
                cellClass: 'grid-cell-centered',
                suppressMovable: true,
                minWidth: 62,
                headerComponent: () => {
                    return (
                        <div className="freigabe-cell" style={{ textAlign: 'center' }}>
                            <span>Freigabe</span>
                            <br />
                            <Checkbox
                                onChange={selectAllRows}
                                checked={selectedRows.length === tableRows.length}
                                style={{ padding: 0 }}
                            ></Checkbox>
                        </div>
                    );
                },
                cellRenderer: (params) => {
                    return (
                        <Checkbox
                            checked={isRowSelected(params.data, selectedRows)}
                            disabled={!isTEFUser}
                            onChange={() => selectRow(params)}
                            style={{ padding: 0 }}
                        ></Checkbox>
                    );
                },
                editable: isTEFUser,
                hide: !loggedInUser?.role?.callmart_tef && loggedInUser?.role?.callmart_osp,
            },
            {
                field: 'forecasterNames',
                headerName: 'Forecaster',
                filter: 'agSetColumnFilter',
                minWidth: 120,
                flex: 2,
                valueFormatter: (params) => {
                    return Array.isArray(params.value) ? params.value.join(', ') : params.value;
                },
            },
            {
                field: 'workingUnitLocationId',
                headerName: 'WorkingUnitLocation',
                minWidth: 130,
                flex: 2,
            },
            {
                field: 'workingUnitId',
                headerName: 'WorkingUnit',
                minWidth: 110,
                flex: 2,
            },
            {
                field: 'worktype.name',
                headerName: 'Worktype',
                minWidth: 115,
                flex: 2,
            },
            {
                field: 'contact',
                headerName: 'Kontaktart',
                minWidth: 120,
            },
            {
                field: 'channel.name',
                headerName: 'Kanal',
                minWidth: 90,
            },
            {
                field: 'task.name',
                headerName: 'Task',
                minWidth: 90,
            },
            {
                field: 'capacityType.name',
                headerName: 'Capacity',
                minWidth: 110,
            },
            {
                field: 'capacityType.unit',
                headerName: 'Einheit',
                minWidth: 95,
            },
            {
                field: 'comment',
                headerName: 'Anmerkungen',
                editable: true,
                suppressPaste: true,
                minWidth: 135,
            },
        ];

        for (const monthColumn of monthColumns) {
            columns.push({
                field: `${monthColumn.format('YYYY-MM')}`,
                headerName: `${monthColumn.format('MMM YY')}`,
                minWidth: 90,
                tooltipComponent: MatchingCapacityReportTooltip,
                tooltipField: `${monthColumn.format('YYYY-MM')}`,
                cellRenderer: (params) => {
                    const { column, data, value } = params;
                    const key = column.colId;
                    let cellClasses = '';

                    if (isRowSelected(params.data, selectedRows) && data[key].value) {
                        cellClasses += ' marked-for-release';
                    }

                    if (data?.[key]?.release === StrategicVolumeReportRelease.READY_FOR_RELEASE) {
                        cellClasses += ' ready-for-release';
                    } else if (data?.[key]?.release === StrategicVolumeReportRelease.RELEASED) {
                        cellClasses += ' released';
                    }

                    const cellHtml = (
                        <span className={cellClasses}>
                            {value}
                            {Number.isFinite(data[key]?.differenceWithCapacity) && (
                                <span style={{ color: 'red' }}> ({data[key]?.differenceWithCapacity})</span>
                            )}
                        </span>
                    );

                    return cellHtml;
                },
                valueGetter: (params) => {
                    const value = params?.data[`${monthColumn.format('YYYY-MM')}`]?.value;
                    return value !== undefined && value !== null ? Math.round(value) : value;
                },
                valueSetter: (params) => {
                    params.data[`${monthColumn.format('YYYY-MM')}`].value =
                        params.newValue === '' ? 0 : parseStringToFloat(params.newValue);
                    return true;
                },
                valueFormatter: stringToNumberFormatter,
                suppressMovable: true,
                editable: isTEFUser,
            });
        }

        const defaultArrowColumnDefs = {
            field: ``,
            cellClass: 'grid-cell-centered',
            headerComponent: ButtonIconRenderer,
            maxWidth: 25,
            editable: false,
            sortable: false,
            resizable: false,
            filter: false,
            suppressMovable: true,
        };

        if (isTEFUser) {
            columns = [
                {
                    ...defaultArrowColumnDefs,
                    headerComponentParams: {
                        icon: ArrowBackIosIcon,
                        onClick: () => goBackMonth(6),
                        iconStyle: { width: '14px', height: '14px' },
                    },
                    pinned: 'left',
                },
                ...columns,
                {
                    ...defaultArrowColumnDefs,
                    headerComponentParams: {
                        icon: ArrowForwardIosIcon,
                        onClick: () => goForwardMonth(6),
                        iconStyle: { width: '14px', height: '14px' },
                    },
                    pinned: 'right',
                },
            ];
        }

        return columns;
    }, [
        isTEFUser,
        goBackMonth,
        goForwardMonth,
        loggedInUser?.role?.callmart_osp,
        loggedInUser?.role?.callmart_tef,
        monthColumns,
        selectRow,
        selectedRows,
        tableRows.length,
    ]);

    const columnDefs = createColumnDefs();

    const processCellForClipboard = (params) => {
        return params.value.toString().replace('.', ',');
    };

    const handleCellValueChange = (params) => {
        if (isStringInYearMonthFormat(params.column.colId)) params.data[params.column.colId].changed = true;
    };

    const handleCellEdit = async (params) => {
        if (isStringInYearMonthFormat(params.column.colId)) {
            const enteredValue = parseStringToFloat(params.value);
            if (enteredValue && Number.isFinite(enteredValue)) {
                saveStrategicVolumeReport({
                    cellParams: params,
                    loggedInUserId: loggedInUser?.id,
                    isTEFUser,
                    firstMonth,
                    selectedLocation,
                });
            } else {
                dispatchAlert({
                    message: 'Geben Sie eine gültige Zahl ein',
                    type: 'error',
                });
            }
        } else {
            await updateStrategicVolume({
                id: params.data.strategicVolumeId,
                [params.column.colId]: params.value,
            });
        }
    };

    const onPasteEnd = () =>
        saveStrategicVolumeReport({
            tableRows,
            loggedInUserId: loggedInUser?.id,
            isTEFUser,
            firstMonth,
            selectedLocation,
        });

    const saveStrategicVolumeReport = useCallback(
        async ({ tableRows, loggedInUserId, isTEFUser, firstMonth, selectedLocation, cellParams }) => {
            try {
                let isDataSaved = false;
                let numOfNewReports = 0;
                if (cellParams) {
                    if (cellParams.newValue) {
                        let data = {
                            report: {
                                ...cellParams.data,
                                date: cellParams.column.colId,
                                value: cellParams.newValue,
                            },
                            userId: loggedInUserId,
                        };

                        await handleCreateOneStrategicVolumeReport(data);
                        isDataSaved = true;
                    }
                } else {
                    const result = await handleCreateStrategicVolumeReport({ data: tableRows, userId: loggedInUserId });
                    isDataSaved = numOfNewReports > 0 ? true : false;
                    numOfNewReports = result.numOfNewReports;
                }

                if (isDataSaved) {
                    dispatchAlert({
                        message: 'Strategischer Forecast gespeichert',
                        type: 'success',
                    });
                } else if (numOfNewReports === 0) {
                    dispatchAlert({
                        message: 'Geben Sie gültige Daten ein',
                        type: 'error',
                    });
                }
            } catch (error) {
                showErrorNotification({
                    dispatchAlert,
                    message: 'Strategischer Forecast konnte nicht gespeichert werden',
                    useSnackbar: false,
                });
            } finally {
                queryClient.invalidateQueries(strategicVolumeReportQueryKey);
            }
        },
        [
            dispatchAlert,
            handleCreateOneStrategicVolumeReport,
            handleCreateStrategicVolumeReport,
            strategicVolumeReportQueryKey,
        ]
    );

    const markReportRelease = (rows, releaseType) => {
        handleMarkStrategicVolumeReportsRelease({ strategicVolumes: rows, releaseType }).then((res) => {
            if (res.success) {
                if (showConfirmationDialog) setShowConfirmationDialog(false);
                if (releaseType === StrategicVolumeReportRelease.RELEASED) setSelectedRows([]);

                queryClient.invalidateQueries(strategicVolumeReportQueryKey);
            }
        });
    };

    const handleMarkReportReleaseClick = async (releaseType) => {
        if (releaseType === StrategicVolumeReportRelease.RELEASED) {
            setShowConfirmationDialog(true);
            return;
        }

        if (!selectedRows.length) {
            showErrorNotification({
                dispatchAlert,
                message: 'Keine Felder markiert. Bitte wähle die Reihen aus die du freigeben möchtest.',
                useSnackbar: false,
            });

            return;
        }

        markReportRelease(selectedRows, releaseType);
    };

    const handleDeactivate = () => {
        const rowsToDeactivate = selectedRows.reduce((acc, row) => {
            const filteredReports = row.strategicVolumeReports.filter(
                (report) => report.release === StrategicVolumeReportRelease.RELEASED
            );

            if (filteredReports.length > 0) {
                acc.push({
                    strategicVolumeId: row.strategicVolumeId,
                    strategicVolumeReports: filteredReports,
                });
            }

            return acc;
        }, []);

        markReportRelease(rowsToDeactivate, StrategicVolumeReportRelease.READY_FOR_RELEASE);
    };

    const closeConfirmationDialog = () => {
        setShowConfirmationDialog(false);
    };

    const uploadFiles = (files, closeDropzoneDialog) => {
        setLoading(true);

        const file = files[0];

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = parseXLSXData(e, 'locationCode');

            await handleUploadStrategicVolumes({ data, userId: loggedInUser.id, setPercentage })
                .then(async (res) => {
                    const resData = parseUploadResponse(res, 'Strategic Volume');
                    const { success, errorMessage, uploadContainsInactiveVolumes } = resData;

                    if (uploadContainsInactiveVolumes) {
                        dispatchAlert({
                            message:
                                'Bitte beachten Sie, dass Ihr Bericht Daten für inaktive Strategic Volumen enthält. Diese Daten werden nicht hochgeladen.',
                            type: 'success',
                            useSnackbar: false,
                        });

                        return;
                    }

                    if (success) {
                        showSuccessfulUploadNotification(dispatchAlert);
                    } else {
                        showErrorUploadNotification({
                            dispatchAlert,
                            errorMessage,
                        });
                    }
                })
                .catch(async (error) => {
                    Sentry.captureException(error);
                    showErrorUploadNotification({
                        dispatchAlert,
                        errorMessage: error.message,
                    });
                })
                .finally(async () => {
                    setCommonStateAfterDataUpload(setLoading, setPercentage, closeDropzoneDialog);

                    queryClient.invalidateQueries([
                        QueryKey.LastUploadDate + '-strategicVolumeReport',
                        { location: selectedLocation },
                    ]);
                });
        };
        reader.readAsArrayBuffer(file);
    };

    const applyFilterOnForecaster = useCallback(() => {
        let values = [];
        const name = loggedInUser?.name;
        const filterModel = gridRef.current.api.getColumnFilterModel('forecasterNames');
        if (filterModel?.values?.includes(loggedInUser?.name)) {
            values = [name];
        }
        gridRef.current.api.setColumnFilterModel('forecasterNames', {
            filterType: 'set',
            values,
        });
        gridRef.current.api.onFilterChanged();
        isForecasterFilterApplied.current = true;
    }, [loggedInUser?.name]);

    const onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    };

    useEffect(() => {
        if (isTEFUser && isMounted && tableRows.length > 0 && gridRef.current?.api) {
            if (!isForecasterFilterApplied.current) {
                applyFilterOnForecaster();
            }
        }
    }, [applyFilterOnForecaster, tableRows.length, isMounted, gridRef.current?.api, isTEFUser]);

    return (
        <Container>
            <GridContainer sx={loading ? classes.containerOpacity : {}}>
                {loggedInUser && (
                    <UserRoleLocation
                        loggedInUser={loggedInUser}
                        selectedLocation={selectedLocation}
                        locationOptions={locationOptions}
                        onChange={(value) => {
                            setSelectedLocation(value);
                            setShowComparisonWithCapacityReport(false);
                        }}
                    ></UserRoleLocation>
                )}
                <Grid item xs={isTEFUser ? 7 : 10} md={3} lg={2}>
                    <LastUpload showInfoIcon={false} date={lastUploadDate}></LastUpload>
                </Grid>
                <Grid item xs={1}>
                    <StrategicVolumeExportButton locations={locationOptions}></StrategicVolumeExportButton>
                </Grid>
                {isTEFUser && (
                    <Grid
                        xs={12}
                        item
                        sx={{ display: 'flex', alignItems: 'baseline', gap: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <UploadXlsxFileButton
                            buttonName="Upload"
                            variant="contained"
                            uploadFiles={uploadFiles}
                            dialogTitle="Forecasts hochladen"
                            style={{ marginRight: '0.5rem' }}
                        ></UploadXlsxFileButton>
                        <Button
                            onClick={() => setShowComparisonWithCapacityReport(true)}
                            variant="outlined"
                            style={{ marginRight: '0.5rem' }}
                        >
                            Abgleich
                        </Button>
                        <Button
                            onClick={() => handleMarkReportReleaseClick(StrategicVolumeReportRelease.READY_FOR_RELEASE)}
                            variant="contained"
                            style={{
                                backgroundColor: 'orange',
                                color: 'white',
                            }}
                        >
                            Freigabe vormerken
                        </Button>
                        <Button
                            onClick={() => handleMarkReportReleaseClick(StrategicVolumeReportRelease.RELEASED)}
                            variant="contained"
                            style={{ backgroundColor: 'var(--lightGreen)', color: 'white', marginRight: '0.5rem' }}
                        >
                            Freigabe aktivieren
                        </Button>
                        {selectedRows.length > 0 && (
                            <Button
                                onClick={handleDeactivate}
                                variant="contained"
                                style={{ backgroundColor: 'grey', color: 'white' }}
                            >
                                Auswahl deaktivieren
                            </Button>
                        )}
                    </Grid>
                )}

                <TableGridItem
                    ref={gridRef}
                    rowData={tableRows}
                    defaultColDef={{
                        editable: false,
                        filter: true,
                        sortable: true,
                    }}
                    columnDefs={columnDefs}
                    components={{
                        checkboxCellRenderer: CheckboxCellRenderer,
                    }}
                    onCellEditingStopped={handleCellEdit}
                    onCellValueChanged={handleCellValueChange}
                    onPasteEnd={onPasteEnd}
                    processCellForClipboard={processCellForClipboard}
                    tooltipShowDelay={0}
                    tooltipHideDelay={3000}
                    onFirstDataRendered={onFirstDataRendered}
                ></TableGridItem>
            </GridContainer>
            {showConfirmationDialog && (
                <ConfirmationDialog
                    open={showConfirmationDialog}
                    title="Strategischer Forecast Freigabe"
                    text="Möchtest du die Forecast Werte für alle Standorte freigeben?"
                    onClose={closeConfirmationDialog}
                    onConfirm={() => markReportRelease(selectedRows, StrategicVolumeReportRelease.RELEASED)}
                />
            )}

            <PercentageLoadingOverlay loading={loading} percentage={percentage}></PercentageLoadingOverlay>
        </Container>
    );
};

export default StrategicVolume;
