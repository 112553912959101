import { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { uploadForecast } from '../services/forecastDelta';
import Container from '../components/Layout/Container';
import {
    useAlertMessage,
    useIsMounted,
    useCookieWorktype,
    useWorktypes,
    useLastForecastDeltaUploadDate,
    useAllSlotDaysInMonthAndYear,
    useAllAvailableBookingsByDateAndWorktype,
    useCustomStyles,
} from '../hooks';
import { getRowsFromXLSXData } from '../utils/xlsxUtils';
import PercentageLoadingOverlay from '../components/Loaders/PercentageLoadingOverlay';
import CustomDatePicker from '../components/UI/CustomDatePicker';
import { createTableRows, getSlotTimeRowKey, getSlotTimeColumnDef } from '../utils/agGridUtils';
import { integerComparator } from '../components/AgGrid/comparators';
import WorktypeListDialog from '../components/CalldeltaOverview/WorktypeListDialog';
import WorktypeSelect from '../components/Selects/WorktypeSelect';
import UploadXlsxFileButton from '../components/Buttons/UploadXlsxFileButton';
import { uniq } from 'lodash';
import { read, utils } from 'xlsx';
import dayjs from 'dayjs';
import {
    setCommonStateAfterDataUpload,
    showSuccessfulUploadNotification,
    showErrorUploadNotification,
    handleDatePickerMonthChange,
    parseUploadResponse,
} from '../utils/componentUtils';
import LastUpload from '../components/General/LastUpload';
import { queryClient } from '../services/queryClient';
import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '../enums/QueryKey';
import { getShortDayNameFromDate } from '../utils/dateUtils';
import * as Sentry from '@sentry/react';
import TableGridItem from '../components/UI/TableGridItem';
import GridContainer from '../components/Layout/GridContainer';
import { useSocket } from '../store/SocketContext';
import { SocketEvents } from '../enums/SocketEvents';

const createMapWithTableRows = (availableBookingsList) => {
    let rowsMap = new Map();
    rowsMap = addRowsInMap(availableBookingsList, rowsMap);

    return rowsMap;
};

const addRowsInMap = (list, rowsMap) => {
    for (let i = 0; i < list.length; i++) {
        const key = getSlotTimeRowKey(list[i].slotDate);
        const day = dayjs(new Date(list[i].slotDate)).format('DD');

        if (rowsMap.has(key)) {
            rowsMap.set(key, {
                ...rowsMap.get(key),
                [`day${day}`]: list[i].availableBookings,
            });
        } else {
            rowsMap.set(key, {
                [`day${day}`]: list[i].availableBookings,
            });
        }
    }

    return rowsMap;
};

const createColumnDefs = (slotDaysInMonth) => {
    const columns = [getSlotTimeColumnDef()];
    for (let i = 0; i < slotDaysInMonth.length; i++) {
        const dayName = getShortDayNameFromDate(slotDaysInMonth[i].date, 'de');
        columns.push({
            field: `day${dayjs(slotDaysInMonth[i].date).format('DD')}`,
            headerName: `${dayjs(slotDaysInMonth[i].date).format('DD.MM')}\n${dayName}`,
            comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                return integerComparator({ valueA, valueB });
            },
            cellClass: 'grid-cell-centered',
            cellStyle: (params) => {
                let styles = {
                    padding: '0px',
                    textAlign: 'center',
                };
                if (params.value > 0) {
                    styles = { ...styles, color: 'red' };
                }
                if (i % 2 === 0) {
                    styles = { ...styles, backgroundColor: 'var(--lightGrey)' };
                }
                return styles;
            },
            minWidth: 90,
        });
    }

    return columns;
};

const CalldeltaOverview = () => {
    const socket = useSocket();
    const classes = useCustomStyles();

    const isMounted = useIsMounted();
    const { dispatchAlert } = useAlertMessage();

    const gridRef = useRef(null);

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [worktypeOptions] = useWorktypes({ prependAllWorktypesOption: true });
    const [selectedWorktype, setSelectedWorktype] = useCookieWorktype(worktypeOptions);
    const lastUploadDate = useLastForecastDeltaUploadDate(selectedWorktype);
    const [loading, setLoading] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [monthForUploading, setMonthForUploading] = useState(dayjs().format('YYYY-MM'));

    const [openWorktypeListDialog, setOpenWorktypeListDialog] = useState(false);
    const [notPresentWorktypes, setNotPresentWorktypes] = useState([]);

    const { mutateAsync: handleUploadForecast } = useMutation({
        mutationFn: uploadForecast,
    });

    const uploadFiles = (files, closeDropzoneDialog) => {
        setLoading(true);

        const file = files[0];

        const reader = new FileReader();
        reader.onload = async (e) => {
            /* Parse data */
            const ab = e.target.result;
            const wb = read(ab, { type: 'array' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = utils.sheet_to_json(ws, { header: 1 });

            const forecast = getRowsFromXLSXData(data, 2);
            handleUploadForecast({ dataToUpload: forecast, monthForUploading, setPercentage })
                .then((res) => {
                    const resData = parseUploadResponse(res, 'Calldelta');
                    const { success, errorMessage, notPresentWorktypes } = resData;

                    if (success) {
                        if (notPresentWorktypes.length > 0) {
                            setNotPresentWorktypes(uniq(resData.notPresentWorktypes));
                            handleWorktypeListDialogOpen();
                        } else {
                            showSuccessfulUploadNotification(dispatchAlert);
                        }

                        socket.emit(SocketEvents.uploadedForecastDelta, { monthForUploading });
                    } else {
                        showErrorUploadNotification({
                            dispatchAlert,
                            errorMessage,
                        });
                    }
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    showErrorUploadNotification({
                        dispatchAlert,
                        errorMessage: error.message,
                    });
                })
                .finally(async () => {
                    setCommonStateAfterDataUpload(setLoading, setPercentage, closeDropzoneDialog);
                    queryClient.invalidateQueries([
                        QueryKey.LastUploadDate + '-forecastDelta',
                        { worktype: selectedWorktype },
                    ]);
                });
        };
        reader.readAsArrayBuffer(file);
    };

    const handleWorktypeListDialogOpen = () => {
        setOpenWorktypeListDialog(true);
    };

    const handleWorktypeListDialogClose = () => {
        setOpenWorktypeListDialog(false);
    };

    const { data: slotDaysInMonthAndYear } = useAllSlotDaysInMonthAndYear(selectedDate);
    const columnDefs = slotDaysInMonthAndYear?.length > 0 ? createColumnDefs(slotDaysInMonthAndYear) : [];
    const { data: availableBookings } = useAllAvailableBookingsByDateAndWorktype({
        dateFrom: dayjs.utc(selectedDate).startOf('month'),
        dateTo: dayjs.utc(selectedDate).endOf('month'),
        worktype: selectedWorktype,
    });
    const tableRows =
        availableBookings?.length > 0 ? createTableRows(createMapWithTableRows(availableBookings), 'slotTime') : [];

    useEffect(() => {
        socket.on(SocketEvents.createdBookingDelta, ({ monthForCreatedBookings, worktypeForCreatedBookings }) => {
            const uploadedDate = dayjs().month(monthForCreatedBookings - 1);
            setSelectedDate(uploadedDate);
            setSelectedWorktype(worktypeForCreatedBookings);
        });

        return () => {
            socket.removeAllListeners();
        };
    }, [isMounted, setSelectedWorktype, socket]);

    return (
        <Container>
            <GridContainer sx={loading ? classes.containerOpacity : {}}>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                    <CustomDatePicker
                        date={selectedDate}
                        views={['year', 'month']}
                        openTo="month"
                        onChange={(newValue) => handleDatePickerMonthChange(selectedDate, newValue, setSelectedDate)}
                    ></CustomDatePicker>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <WorktypeSelect
                        selectedWorktype={selectedWorktype}
                        worktypeOptions={worktypeOptions}
                        onChange={setSelectedWorktype}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                    <LastUpload showInfoIcon={false} date={lastUploadDate}></LastUpload>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} sx={classes.btnRightAligned}>
                    <UploadXlsxFileButton
                        buttonName="Upload"
                        monthForUploading={monthForUploading}
                        onChangeMonthForUploading={setMonthForUploading}
                        uploadFiles={uploadFiles}
                        dialogTitle="Calldeltas hochladen"
                    ></UploadXlsxFileButton>
                </Grid>
                <TableGridItem
                    ref={gridRef}
                    rowData={tableRows}
                    defaultColDef={{
                        editable: false,
                        filter: true,
                        sortable: true,
                    }}
                    columnDefs={columnDefs}
                ></TableGridItem>
            </GridContainer>
            <PercentageLoadingOverlay loading={loading} percentage={percentage}></PercentageLoadingOverlay>
            <WorktypeListDialog
                open={openWorktypeListDialog}
                onClose={handleWorktypeListDialogClose}
                worktypes={notPresentWorktypes}
            ></WorktypeListDialog>
        </Container>
    );
};

export default CalldeltaOverview;
