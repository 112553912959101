import { Button } from '@mui/material';
import { forwardRef } from 'react';

const ChangeTotalsButton = forwardRef(({ onChangeTotals, ...props }, ref) => {
    if (ref.current) ref.current.style.display = 'none';

    return (
        <Button ref={ref} onClick={onChangeTotals} variant="contained" style={{ display: 'none' }} {...props}>
            Änderungen Speichern
        </Button>
    );
});

export default ChangeTotalsButton;
