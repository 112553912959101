import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';

const BASE = '/workingHours';

export const getWorkingHours = async ({ workingUnit }) => {
    let url = `${BASE}`;
    url = appendQueryParamsToUrl(url, { workingUnit });
    return api.get(url, await setToken()).then((res) => res.data);
};

export const postWorkingHour = async (data) => {
    return api.post(`${BASE}`, { data }, await setToken()).then((res) => res.data);
};

export const postWorkingHours = async (data) => {
    return api.post(`${BASE}/multiple`, data, await setToken()).then((res) => res.data);
};

export const getWorkingHoursByWorkingUnitLocation = async (workingUnitLocationId) => {
    return api.get(`${BASE}/working-unit-location/${workingUnitLocationId}`, await setToken()).then((res) => res.data);
};
