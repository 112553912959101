import { Grid } from '@mui/material';
import { handleDatePickerMonthChange } from '../../utils/componentUtils';
import CustomDatePicker from '../UI/CustomDatePicker';
import WorkingUnitLocationSelect from '../Selects/WorkingUnitLocationSelect';

const BiddingCalculatedFilters = ({
    locationOptions,
    selectedLocation,
    onSelectedLocationChange,
    selectedWorkingUnitLocation,
    onSelectedWorkingUnitLocationChange,
    selectedDate,
    onSelectedDateChange,
}) => {
    return (
        <>
            <WorkingUnitLocationSelect
                locationOptions={locationOptions}
                selectedLocation={selectedLocation}
                onSelectedLocationChange={onSelectedLocationChange}
                selectedWorkingUnitLocation={selectedWorkingUnitLocation}
                onSelectedWorkingUnitLocationChange={onSelectedWorkingUnitLocationChange}
            ></WorkingUnitLocationSelect>
            <Grid item xs={3} md={2} xl={1}>
                <CustomDatePicker
                    date={selectedDate}
                    views={['year', 'month']}
                    openTo="month"
                    onChange={(newValue) => handleDatePickerMonthChange(selectedDate, newValue, onSelectedDateChange)}
                ></CustomDatePicker>
            </Grid>
        </>
    );
};

export default BiddingCalculatedFilters;
