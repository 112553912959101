import { useContext } from 'react';
import { ChannelOptions } from '../../enums/ChannelOptions';
import { usePreferredUserLocation, useIsUserAllowedToAccessALocation } from '../../hooks';
import UserContext from '../../store/User/UserContext';
import dayjs from 'dayjs';
import { getAdditionalWorktypeChannelForLocation } from '../../services/additionalLocationDeltaWorktypeChannel';
import { QueryKey } from '../../enums/QueryKey';
import { useQuery } from '@tanstack/react-query';
import DownloadExcelButton from '../Buttons/DownloadExcelButton';
import { getAllDatesInAMonth, getAllHourSlotsInATimeRange } from '../../utils/dateUtils';

const columnNames = [
    'START_TIME',
    'HOUR',
    'MINUTE',
    'Calldelta',
    'Stadt',
    'Standort Detail',
    'Worktype',
    'Kanal',
    'Grund',
];

function LocationDeltaEmptyTemplateDownloadButton({ selectedDate, selectedLocation, allWorktypes }) {
    const loggedInUser = useContext(UserContext);

    const [location] = usePreferredUserLocation(selectedLocation);
    const isUserAllowedToAccessLocation = useIsUserAllowedToAccessALocation(loggedInUser?.id, selectedLocation);

    const { data: additionalWorktypeChannelsForLocation } = useQuery({
        queryKey: [QueryKey.AdditionalWorktypeChannelsForLocation, { location: selectedLocation }],
        queryFn: () => getAdditionalWorktypeChannelForLocation(selectedLocation),
        initialData: [],
        enabled: isUserAllowedToAccessLocation === true && !!selectedLocation,
    });

    const worktypes = [
        ...allWorktypes.map((worktype) => ({
            ...worktype,
            worktypeName: worktype.worktype,
            sheetName: worktype.worktype,
            channel: ChannelOptions.INBOUND_VOICE.name,
        })),
        ...additionalWorktypeChannelsForLocation.map((worktypeChannel) => {
            const worktypeName = worktypeChannel.worktype.worktype;
            const channelName = worktypeChannel.channel.name;
            return {
                worktypeName,
                sheetName: `${worktypeName} ${channelName}`,
                channel: channelName,
            };
        }),
    ];

    const getXLSXSheets = (worktypes, currentMonthDates, allHourSlots) => {
        const sheetsData = [];
        for (let i = 0; i < worktypes.length; i++) {
            const sheetData = [];

            for (let j = 0; j < currentMonthDates.length; j++) {
                let row = [];
                for (let k = 0; k < allHourSlots.length; k++) {
                    const start_time = currentMonthDates[j].format('DD.MM.YYYY');
                    const hour = allHourSlots[k].split(':')[0];
                    const minute = allHourSlots[k].split(':')[1];
                    row = [
                        start_time,
                        hour,
                        minute,
                        '',
                        location?.city,
                        location?.code,
                        worktypes[i].worktypeName,
                        worktypes[i].channel,
                        '',
                    ];

                    sheetData.push(row);
                }
            }

            sheetsData.push({
                sheetName: worktypes[i].sheetName,
                sheetData: sheetData,
            });
        }

        return sheetsData;
    };

    return (
        <DownloadExcelButton
            title="Standortdelta Template"
            fileName={`delta_${location?.city}_${dayjs(selectedDate).format('MM_YYYY')}.xlsx`}
            location={selectedLocation}
            columnNames={columnNames}
            getSheetsData={() =>
                getXLSXSheets(
                    worktypes,
                    getAllDatesInAMonth(selectedDate),
                    getAllHourSlotsInATimeRange({ startTime: 7, endTime: 22 })
                )
            }
            buttonText="Leeres Template"
        ></DownloadExcelButton>
    );
}

export default LocationDeltaEmptyTemplateDownloadButton;
