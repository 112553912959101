import { CardContent, Card, Popover } from '@mui/material';

function CardPopover({ anchorEl, onClose, children, className }) {
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Card
                className={className}
                sx={{
                    height: '50%',
                    overflow: 'auto',
                    minWidth: '200px',
                    textAlign: 'center',
                    '& .MuiCardContent-root': {
                        padding: 0,
                    },
                    '& .closeIcon': {
                        '&:hover': {
                            backgroundColor: 'lightgray',
                            borderRadius: '50%',
                        },
                        maxWidth: '18px',
                        maxHeight: '18px',
                        padding: '3px',
                        float: 'right',
                        top: '8px',
                        position: 'absolute',
                        right: '8px',
                    },
                    '& .lastUploadPopover': {
                        overflow: 'scroll',
                        width: '50vw',
                    },
                }}
            >
                <CardContent>{children}</CardContent>
            </Card>
        </Popover>
    );
}

export default CardPopover;
