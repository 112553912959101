export const CapacityType = {
    FTE_PAID_AND_SENIOR_AGENT: {
        id: 1,
        name: 'FTE Paid Agent + Senior Agent',
    },
    PAID_TIME: {
        id: 4,
        name: 'Paid Time',
    },
    PLANNED_ABSENCE: {
        id: 5,
        name: 'geplante Abwesenheit (planned absence)',
    },
    UNPLANNED_ABSENCE: {
        id: 6,
        name: 'ungeplante Abwesenheit (unplanned absence)',
    },
    TRAINING: {
        id: 7,
        name: 'Training',
    },
    OTHER_UNPRODUCTIVITY: {
        id: 9,
        name: 'Sonstige Unproduktivität (other unproductivity)',
    },
    AVAILABLE_TIME: {
        id: 10,
        name: 'Available Time',
    },
    CUSTOMER_WORKLOAD: {
        id: 11,
        name: 'Customer Workload',
    },
    ABT: {
        id: 12,
        name: 'ABT',
    },
    HANDLED_CONTACTS: {
        id: 13,
        name: 'Handled Contacts',
    },
};
