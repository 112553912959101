import { Box, CircularProgress, Typography } from '@mui/material';

function LoadingOverlay({ loading, percentage }) {
    return (
        <>
            {loading && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        flex: 1,
                        padding: 8,
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: (theme) => theme.zIndex.modal + 1,
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 50,
                        left: 0,
                    }}
                >
                    <CircularProgress
                        size={80}
                        sx={{
                            color: 'secondary',
                        }}
                    />
                    <Typography position="absolute" style={{ fontWeight: '500', fontSize: '1.5em' }}>
                        {percentage}%
                    </Typography>
                </Box>
            )}
        </>
    );
}

export default LoadingOverlay;
