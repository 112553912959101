import WorkingUnitLocationAutocomplete from '../Selects/WorkingUnitLocationAutocomplete';
import { useState } from 'react';
import {
    useDispatchErrorNotification,
    useDispatchSuccessNotification,
    useCustomStyles,
    useLocationsByLoggedInUserRole,
    useWorkingUnits,
} from '../../hooks';
import ResponsiveDialog from '../UI/Dialogs/ResponsiveDialog';
import MonthPicker from '../UI/MonthPicker';
import dayjs from 'dayjs';
import { Typography, DialogContent } from '@mui/material';
import { completeCalculation } from '../../services/completedCalculation';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../services/queryClient';
import { getQueryKey } from '../../hooks/api/completedCalculations';

const getSelectedOptionIds = (selectedRecords, allRecords) => {
    if (selectedRecords.find((r) => r.id === -1)) {
        return allRecords.filter((r) => r.id !== -1).map((r) => r.id);
    }
    return selectedRecords.map((r) => r.id);
};

const CompleteCalculationDialog = ({ open, onClose, dateFilter, workingUnitLocationFilter }) => {
    const classes = useCustomStyles();
    const { dispatchSuccessNotification } = useDispatchSuccessNotification();
    const { dispatchErrorNotification } = useDispatchErrorNotification();

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [locationOptions] = useLocationsByLoggedInUserRole({ prependAllLocationsOption: true });
    const [workingUnitOptions] = useWorkingUnits({ hasMatchingOfferedCluster: true, prependAllOption: true });
    const [selectedWorkingUnits, setSelectedWorkingUnits] = useState([]);

    const { mutate: handleCompleteCalculation } = useMutation({
        mutationFn: completeCalculation,
        onSuccess: () => {
            dispatchSuccessNotification({ message: 'Die Berechnung wurde erfolgreich abgeschlossen.' });
            onClose();
            setSelectedWorkingUnits([]);
            queryClient.invalidateQueries(
                getQueryKey({ date: dateFilter, workingUnitLocation: workingUnitLocationFilter })
            );
        },
        onError: (error) => {
            dispatchErrorNotification({
                message: error.message || 'Beim Abschließen der Berechnung ist ein Fehler aufgetreten.',
            });
        },
    });

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <ResponsiveDialog
            fullScreen={false}
            fullWidth={true}
            title={'Plannung abschließen'}
            onClose={onClose}
            open={open}
            isConfirmationDialog={true}
            confirmButtonText={'Ja'}
            closeButtonText={'Nein'}
            onConfirm={() => {
                handleCompleteCalculation({
                    dateFrom: dayjs.utc(selectedDate).startOf('month'),
                    dateTo: dayjs.utc(selectedDate).endOf('month'),
                    locations: getSelectedOptionIds(selectedLocations, locationOptions),
                    workingUnits: getSelectedOptionIds(selectedWorkingUnits, workingUnitOptions),
                });
            }}
        >
            <DialogContent>
                <WorkingUnitLocationAutocomplete
                    locationOptions={locationOptions}
                    selectedLocations={selectedLocations}
                    onSelectedLocationsChange={setSelectedLocations}
                    workingUnitOptions={workingUnitOptions}
                    selectedWorkingUnits={selectedWorkingUnits}
                    onSelectedWorkingUnitsChange={setSelectedWorkingUnits}
                    isLocationMultiSelect={true}
                    isWorkingUnitMultiSelect={true}
                ></WorkingUnitLocationAutocomplete>
                <MonthPicker date={selectedDate} onDateChange={handleDateChange} sx={classes.marginTop}></MonthPicker>
                <Typography sx={classes.marginTop}>
                    Möchten Sie die Planung für diesen Zeitraum und Work Type abschließen? Bitte beachten: weitere
                    Korrekturen werden nicht möglich sein.
                </Typography>
            </DialogContent>
        </ResponsiveDialog>
    );
};

export default CompleteCalculationDialog;
