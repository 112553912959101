import { useContext } from 'react';
import { Grid } from '@mui/material';
import { isOSPUser } from '../../utils/authUtils';
import LocationSelect from '../Selects/LocationSelect';
import CustomAutoComplete from '../UI/CustomAutoComplete';
import CustomDatePicker from '../UI/CustomDatePicker';
import UserContext from '../../store/User/UserContext';

const MultiSelectWorktypeLocDateRangeFilter = ({
    locationOptions,
    selectedLocation,
    onSelectedLocationChange,
    selectedLocations,
    onSelectedLocationsChange,
    worktypeOptions,
    selectedWorktypes,
    onSelectedWorktypesChange,
    startDate,
    onStartDateChange,
    endDate,
    onEndDateChange,
}) => {
    const loggedInUser = useContext(UserContext);

    const handleChangeSelectedWorktypes = (worktypes) => {
        let allWorktypesObject = worktypes.find((worktype) => worktype.id === -1);
        if (worktypes.length > 1 && allWorktypesObject) {
            if (worktypes.indexOf(allWorktypesObject) === worktypes.length - 1) {
                onSelectedWorktypesChange([allWorktypesObject]);
                return;
            } else {
                worktypes = worktypes.filter((worktype) => worktype.id !== allWorktypesObject.id);
            }
        }

        onSelectedWorktypesChange(worktypes);
    };

    const handleChangeSelectedLocations = (locations) => {
        let allLocationsObject = locations.find((location) => location.id === -1);
        if (locations.length > 1 && allLocationsObject) {
            if (locations.indexOf(allLocationsObject) === locations.length - 1) {
                onSelectedLocationsChange([allLocationsObject]);
                return;
            } else {
                locations = locations.filter((location) => location.id !== allLocationsObject.id);
            }
        }

        onSelectedLocationsChange(locations);
    };

    return (
        <>
            {isOSPUser(loggedInUser) && (
                <Grid item xs={12} md={5} lg={3}>
                    <LocationSelect
                        selectedLocation={selectedLocation}
                        locationOptions={locationOptions}
                        onChange={onSelectedLocationChange}
                    ></LocationSelect>
                </Grid>
            )}
            {loggedInUser?.role?.callmart_tef && (
                <Grid item xs={12} md={5} lg={3}>
                    <CustomAutoComplete
                        options={locationOptions}
                        labelKeys={['code', 'city']}
                        value={selectedLocations}
                        label="Location"
                        onChange={handleChangeSelectedLocations}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                </Grid>
            )}
            <Grid item xs={12} md={6} lg={3}>
                <CustomAutoComplete
                    options={worktypeOptions}
                    labelKeys={['worktype']}
                    value={selectedWorktypes}
                    label="Worktype"
                    onChange={handleChangeSelectedWorktypes}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                />
            </Grid>
            <Grid item>
                <CustomDatePicker
                    label="Start"
                    date={startDate}
                    views={['year', 'month']}
                    openTo="month"
                    onChange={onStartDateChange}
                ></CustomDatePicker>
            </Grid>
            <Grid item>
                <CustomDatePicker
                    label="End"
                    date={endDate}
                    views={['year', 'month']}
                    openTo="month"
                    onChange={onEndDateChange}
                ></CustomDatePicker>
            </Grid>
        </>
    );
};

export default MultiSelectWorktypeLocDateRangeFilter;
