import { getSlotTimeColumnDef } from '../../../utils/agGridUtils';
import { integerComparator } from '../../AgGrid/comparators';
import { getAllHourSlotsInATimeRange, getShortDayNameFromDate } from '../../../utils/dateUtils';
import CellBorderRenderer from '../../AgGrid/CellBorderRenderer';
import { isNumberDecimal } from '../../../utils/numberUtils';

export const STATIC_EXCEL_COLUMN_NAMES = [
    'workingUnitId',
    'workingUnitLocationId',
    'worktypeId',
    'channelId',
    'taskId',
    'Standort Detail',
    'Stadt',
    'Worktype',
    'Kanal',
    'Task',
    'Zeit',
];

export const defaultColumnDefs = {
    editable: false,
    filter: true,
    sortable: true,
    minWidth: 90,
};

const findRecordBySlot = (records, slot) =>
    records?.find((record) => record?.offeredReport?.slotId?.substring(0, 16) === slot);

export const createColumnDefs = ({
    allDatesInAMonth,
    valuePropertyName,
    hasFooter,
    showPercentageSign,
    alwaysAllowEdit,
}) => {
    const footerRowText = hasFooter ? 'Summe' : '';
    const maxWidth = hasFooter ? 80 : 50;
    const columns = [getSlotTimeColumnDef({ footerRowText, maxWidth })];

    for (const currentDate of allDatesInAMonth) {
        const dayOfWeek = currentDate.day();
        const shouldHighlightDay = dayOfWeek === 0;

        const dayName = getShortDayNameFromDate(currentDate, 'de');
        const dayKey = `day${currentDate.format('DD')}`;

        columns.push({
            field: `day${currentDate.format('DD')}`,
            headerName: `${dayName}\n${currentDate.format('DD.MM')}`,
            resizable: true,
            comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                return integerComparator({ valueA, valueB });
            },
            editable: (params) => {
                const dayData = params?.data[dayKey];

                if (!dayData.isWorking) return false;

                if (valuePropertyName === 'total') {
                    return alwaysAllowEdit || !dayData?.released;
                }

                return false;
            },
            valueGetter: (params) => {
                const dayData = params?.data[dayKey];
                const value = dayData && dayData[valuePropertyName];

                if (value === undefined || value === null) return;

                return showPercentageSign ? `${value} %` : value;
            },
            valueSetter: (params) => {
                const newValue = parseFloat(params.newValue);
                if (!params.data || newValue < 0 || isNumberDecimal(newValue)) {
                    return false;
                }

                if (!params.data[dayKey]) {
                    params.data[dayKey] = {};
                }

                const dayData = params?.data[dayKey];

                if (!isNaN(newValue)) {
                    dayData[valuePropertyName] = newValue;
                    dayData[`${valuePropertyName}Changed`] = true;

                    return true;
                }

                return false;
            },
            cellRenderer: CellBorderRenderer,
            cellRendererParams: { shouldHighlightDay },
            aggFunc: 'sum',
            cellStyle: (params) => {
                if (valuePropertyName === 'difference') {
                    return { color: params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'black' };
                }

                if (!params.data) {
                    return;
                }

                const dayData = params?.data[dayKey];
                if (dayData?.totalChanged) {
                    return { backgroundColor: 'rgba(0, 0, 0, 0.5)' };
                }

                return null;
            },
        });
    }

    return columns;
};

const addRowsInMap = ({
    dates,
    biddingCalculated,
    correctedTotals,
    biddingCalculatedResults,
    correctedResults,
    rowsMap,
    workingHours,
    publicHolidays,
}) => {
    const allHourSlots = getAllHourSlotsInATimeRange({
        startTime: 0,
        endTime: 23,
        displayHoursInHH: true,
        displayMinutesInmm: true,
    });

    for (const hourSlot of allHourSlots) {
        const valuesPerDay = {};
        for (const date of dates) {
            const isPublicHoliday = publicHolidays?.some((holiday) => holiday.date === date.format('YYYY-MM-DD'));
            const dayNumberForDate = isPublicHoliday ? 8 : date.day() === 0 ? 7 : date.day();
            const workingHour = workingHours?.find(
                (wh) => wh.dayTimeSlot.day === dayNumberForDate && wh.dayTimeSlot.timeSlot === hourSlot
            );
            const slot = `${date.format('DD.MM.YYYY')}-${hourSlot}`;
            const biddingCalculatedRecord = findRecordBySlot(biddingCalculated, slot);
            const correctedTotal = findRecordBySlot(correctedTotals, slot);
            const biddingCalculatedResult = findRecordBySlot(biddingCalculatedResults, slot);
            const correctedResult = findRecordBySlot(correctedResults, slot);

            valuesPerDay[`day${date.format('DD')}`] = {
                date: slot,
                ...(biddingCalculatedRecord && {
                    ...biddingCalculatedRecord,
                    percentage: Number.isFinite(biddingCalculatedRecord?.percentage)
                        ? Math.round(biddingCalculatedRecord?.percentage * 100)
                        : biddingCalculatedRecord?.percentage,
                }),
                ...(correctedTotal && {
                    ...correctedTotal,
                }),
                ...(biddingCalculatedResult && {
                    ...biddingCalculatedResult,
                }),
                ...(correctedResult && {
                    ...correctedResult,
                }),
                difference: (correctedTotal?.total || 0) - (biddingCalculatedRecord?.total || 0),
                isWorking: workingHour?.isWorking,
            };
        }

        rowsMap.set(hourSlot, valuesPerDay);
    }

    return rowsMap;
};

export const createMapWithTableRows = ({
    dates,
    biddingCalculated,
    correctedTotals,
    biddingCalculatedResults,
    correctedResults,
    workingHours,
    publicHolidays,
}) => {
    let rowsMap = new Map();
    rowsMap = addRowsInMap({
        dates,
        biddingCalculated,
        correctedTotals,
        biddingCalculatedResults,
        correctedResults,
        rowsMap,
        workingHours,
        publicHolidays,
    });

    return rowsMap;
};

export const filterChangedRows = (rows) => {
    const result = [];
    rows.forEach((row) => {
        const obj = row;
        Object.keys(obj).forEach((dayKey) => {
            if (!dayKey.includes('day')) return;

            const day = dayKey;
            const dayData = obj[day];
            const keysWithChanged = Object.keys(dayData).filter((key) => key.includes('Changed'));
            if (keysWithChanged.length === 0) return;

            const key = keysWithChanged[0].split('Changed')[0];
            result.push({
                ...dayData,
                total: dayData[key],
                released: false,
                releaseDate: null,
                createdAt: new Date(),
                updatedAt: new Date(),
            });
        });
    });

    return result;
};
