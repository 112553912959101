import { Typography } from '@mui/material';
import { useLastCorrectedTotalsReleaseDate } from '../../hooks/api/correctedTotals';

const LastCorrectedTotalsReleaseDate = ({ workingUnitLocation }) => {
    const lastCorrectedTotalsReleaseDate = useLastCorrectedTotalsReleaseDate(workingUnitLocation);

    return (
        <Typography>
            Gesendet am: <span style={{ display: 'block' }}>{lastCorrectedTotalsReleaseDate}</span>
        </Typography>
    );
};

export default LastCorrectedTotalsReleaseDate;
