export const TotalsType = {
    BIDDING: {
        nameTEF: 'Bidding Totals',
        nameOSP: 'Totals',
        value: 'bidding',
    },
    CORRECTED: {
        nameTEF: 'Corrected Totals',
        nameOSP: 'Totals - Korrektur',
        value: 'corrected',
    },
};

export const getTotalsTypeByValue = (value) => {
    return Object.values(TotalsType).find((type) => type.value === value);
};
