import { Stack, Typography, Grid, Avatar } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import DashboardCard from './DashboardCard';
import { styled } from '@mui/system';

const Title = styled(Typography)`
    text-align: center;
    margin-top: 10px !important;
    margin-bottom: 40px !important;
`;

const LabelContainer = styled(Typography)`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
`;

const Icon = styled(RadioButtonCheckedIcon)`
    color: var(--primary);
`;

const Text = styled(Typography)`
    color: var(--grey);
    text-align: left;
    margin-left: 1.5em !important;
    font-weight: 600;
`;

function DashboardTotals({ totals, ...props }) {
    const { totalForecastDeltas, totalBookedBookings, totalAvailableBookings } = totals;

    return (
        <DashboardCard>
            <Title variant="h6">Verbleibende Calls</Title>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    <Avatar
                        sx={{
                            border: '2px solid var(--primary)',
                            bgcolor: 'var(--white)',
                            color: 'var(--primary)',
                            width: '160px',
                            height: '160px',
                            overflowWrap: 'break-word',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                        variant="round"
                    >
                        <Typography variant="h4" align="center">
                            {totalAvailableBookings.positive + totalAvailableBookings.negative}
                            <Typography variant="body2" align="center">
                                verbleibende Calls
                            </Typography>
                        </Typography>
                    </Avatar>
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Grid container item md={12} justifyContent="center" alignItems="center">
                        <Stack
                            sx={{
                                textAlign: 'right',
                            }}
                            style={{ textAlign: 'center' }}
                        >
                            <div>
                                <LabelContainer>
                                    <Icon />
                                    <span>gebuchte Calls</span>
                                </LabelContainer>
                                <Text variant="body1">
                                    <span>
                                        +{totalBookedBookings.positive} | {totalBookedBookings.negative}
                                    </span>
                                </Text>
                            </div>
                            <div>
                                <LabelContainer>
                                    <Icon sx={{ opacity: '0.6' }} />
                                    verbleibende Calls
                                </LabelContainer>
                                <Text variant="body1">
                                    <span>
                                        +{totalAvailableBookings.positive} | {totalAvailableBookings.negative}
                                    </span>
                                </Text>
                            </div>
                            <div>
                                <LabelContainer>
                                    <Icon sx={{ color: 'var(--grey)' }} />
                                    <span>verfügbare Calls</span>
                                </LabelContainer>
                                <Text variant="body1">
                                    <span>
                                        +{totalForecastDeltas.positive} | {totalForecastDeltas.negative}
                                    </span>
                                </Text>
                            </div>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </DashboardCard>
    );
}

export default DashboardTotals;
