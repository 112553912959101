import ResponsiveDialog from '../UI/Dialogs/ResponsiveDialog';
import { DialogContent, DialogActions, Button } from '@mui/material';
import WorkingUnitLocationAutocomplete from '../Selects/WorkingUnitLocationAutocomplete';
import { useCustomStyles, useLocationsByLoggedInUserRole } from '../../hooks';

const ReleaseTotalsDialog = ({
    open,
    onClose,
    date,
    onRelease,
    selectedLocations,
    onSelectedLocationsChange,
    workingUnitOptions,
    selectedWorkingUnits,
    onSelectedWorkingUnitsChange,
    isLocationMultiSelect,
    releaseButtonText,
    children,
}) => {
    const classes = useCustomStyles();
    const dialogTitle = 'Totals freigeben - ' + date.format('MM.YYYY');

    const [locationOptions] = useLocationsByLoggedInUserRole({ prependAllLocationsOption: isLocationMultiSelect });

    return (
        <ResponsiveDialog
            fullScreen={false}
            fullWidth={true}
            title={dialogTitle}
            onClose={onClose}
            open={open}
            actionButtons={
                <DialogActions sx={{ ...classes.dialogActions, ...classes.marginBottom }}>
                    <Button onClick={onClose} variant="outlined">
                        Abbrechen
                    </Button>
                    <Button onClick={onRelease} variant="contained" disabled={selectedWorkingUnits.length === 0}>
                        {releaseButtonText}
                    </Button>
                </DialogActions>
            }
        >
            <DialogContent>
                <WorkingUnitLocationAutocomplete
                    locationOptions={locationOptions}
                    selectedLocations={selectedLocations}
                    onSelectedLocationsChange={onSelectedLocationsChange}
                    workingUnitOptions={workingUnitOptions}
                    selectedWorkingUnits={selectedWorkingUnits}
                    onSelectedWorkingUnitsChange={onSelectedWorkingUnitsChange}
                    isLocationMultiSelect={isLocationMultiSelect}
                    isWorkingUnitMultiSelect={true}
                ></WorkingUnitLocationAutocomplete>
                {children}
            </DialogContent>
        </ResponsiveDialog>
    );
};

export default ReleaseTotalsDialog;
