import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
    Routes,
    Route,
    BrowserRouter,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from 'react-router-dom';
import axios from 'axios';
import { Auth0ProviderWithHistory } from './components/Auth/Auth0ProviderWithHistory';
import { LicenseManager } from 'ag-grid-enterprise';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './services/queryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import ErrorFallback from './pages/ErrorFallback';

Sentry.init({
    dsn: 'https://d3eb42a8de69b6a52f0178527f402582@sentry.yournext.agency/206',
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 10% of transactions for performance monitoring.
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: process.env.REACT_APP_NODE_ENV !== 'development',
    environment: process.env.REACT_APP_NODE_ENV,
});
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const baseUrl = process.env.REACT_APP_URL;
const isDevelopmentEnv = process.env.REACT_APP_NODE_ENV === 'development';

LicenseManager.setLicenseKey(
    'Using_this_{AG_Grid}_Enterprise_key_{AG-062074}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Unetiq_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{callmart}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{callmart}_need_to_be_licensed___{callmart}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{21_August_2025}____[v3]_[01]_MTc1NTczMDgwMDAwMA==10544d45af749361fe5a19b3c11328cc'
);

if (!baseUrl || !baseUrl.startsWith('http')) {
    throw Error('Environment variable `REACT_APP_CMS_URL` needs to start with `http` or `https`');
}

axios.defaults.baseURL = baseUrl;

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <SentryRoutes>
                    <Route
                        path="*"
                        element={
                            <Sentry.ErrorBoundary fallback={ErrorFallback}>
                                <Auth0ProviderWithHistory>
                                    <App />
                                </Auth0ProviderWithHistory>
                            </Sentry.ErrorBoundary>
                        }
                    />
                </SentryRoutes>
            </BrowserRouter>
            {isDevelopmentEnv && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
    </React.StrictMode>
);
