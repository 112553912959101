import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useHEMTLocations } from '../../hooks';
import { getAllCapacitiesByLocation } from '../../services/capacity';
import DownloadExcelButton from '../Buttons/DownloadExcelButton';

const columnNames = ['Datum', 'Stadt', 'Standort Detail', 'Worktype', 'Kanal', 'Task', 'Capacity Type', 'Value'];

function CapacityReportEmptyTemplateDownloadButton({ months, location }) {
    const allLocationsFromHEMT = useHEMTLocations();
    const [capacitiesByWorktype, setCapacitiesByWorktype] = useState([]);

    const startDate = dayjs(months[0]).format('MM_YYYY');
    const endDate = dayjs(months[months.length - 1]).format('MM_YYYY');

    useEffect(() => {
        if (location) {
            getAllCapacitiesByLocation(location).then((res) => setCapacitiesByWorktype(res));
        }
    }, [location]);

    const getXLSXSheets = (capacitiesByWorktype, months) => {
        const sheetsData = [];
        for (const [worktype, capacities] of Object.entries(capacitiesByWorktype)) {
            const sheetData = [];

            for (const month of months) {
                for (const capacity of capacities) {
                    const date = dayjs(month).format('01.MM.YYYY');
                    const worktype = capacity.workingUnitLocation.workingUnit.worktype.worktype;
                    const location = allLocationsFromHEMT.find(
                        (loc) => loc.id === capacity.workingUnitLocation.worktypeLocation.locationId
                    );
                    const { city, code } = location;
                    const channel = capacity.workingUnitLocation.workingUnit.channel.name;
                    const task = capacity.workingUnitLocation.workingUnit.task.name;
                    const capacityType = capacity.capacityType.name;

                    const row = [date, city, code, worktype, channel, task, capacityType];

                    sheetData.push(row);
                }
            }

            sheetsData.push({
                sheetName: worktype,
                sheetData: sheetData,
            });
        }

        return sheetsData;
    };

    return (
        <DownloadExcelButton
            title="Capacity Report"
            fileName={`capacityReport_${startDate} - ${endDate}.xlsx`}
            location={location}
            columnNames={columnNames}
            getSheetsData={() => getXLSXSheets(capacitiesByWorktype, months)}
            buttonText={'Leeres Template'}
        ></DownloadExcelButton>
    );
}

export default CapacityReportEmptyTemplateDownloadButton;
