import CustomDatePicker from './CustomDatePicker';
import { handleDatePickerMonthChange } from '../../utils/componentUtils';

const MonthPicker = ({ date, onDateChange, ...props }) => {
    return (
        <CustomDatePicker
            date={date}
            views={['year', 'month']}
            openTo="month"
            onChange={(newValue) => handleDatePickerMonthChange(date, newValue, onDateChange)}
            {...props}
        ></CustomDatePicker>
    );
};

export default MonthPicker;
