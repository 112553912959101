import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function CustomDatePicker({ date, onChange, ...props }) {
    const handleChange = (newValue) => {
        if (!newValue || !newValue.isValid()) return;
        onChange(newValue);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
                {...props}
                fullWidth={true}
                value={date}
                onChange={(newValue) => {
                    handleChange(newValue);
                }}
                slotProps={{ textField: { variant: 'outlined' } }}
            />
        </LocalizationProvider>
    );
}

export default CustomDatePicker;
