import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { write, utils } from 'xlsx';
import { useAlertMessage } from '../../hooks';
import { appendSheetToWorkbook, downloadXLSXFile } from '../../utils/xlsxUtils';
import UserContext from '../../store/User/UserContext';
import { useIsUserAllowedToAccessALocation } from '../../hooks';
import { ReactComponent as FileExport } from '../../assets/file-export.svg';

const DownloadExcelButton = ({
    title,
    fileName,
    buttonText,
    getSheetsData,
    columnNames,
    location,
    buttonProps,
    showFileIcon,
    columnsToHide,
}) => {
    const loggedInUser = useContext(UserContext);
    const { dispatchAlert } = useAlertMessage();
    const isUserAllowedToAccessLocation = useIsUserAllowedToAccessALocation(loggedInUser?.id, location);

    const handleDownload = async () => {
        if (!isUserAllowedToAccessLocation) {
            dispatchAlert({
                message: 'Aktion für diesen Standort nicht erlaubt',
                type: 'error',
            });

            return;
        }

        const wb = utils.book_new();
        wb.Props = {
            Title: title,
            Subject: title,
            Author: 'Unetiq',
            CreatedDate: new Date(),
        };

        const data = await getSheetsData();

        for (const { sheetName, sheetData } of data) {
            const ws_data = [columnNames, ...sheetData];
            const ws = utils.aoa_to_sheet(ws_data);

            if (columnsToHide) {
                if (!ws['!cols']) ws['!cols'] = [];
                for (const col of columnsToHide) {
                    if (!ws['!cols'][col]) ws['!cols'][col] = { hidden: true };
                }
            }

            appendSheetToWorkbook(wb, ws, sheetName);
        }

        if (wb.SheetNames.length > 0) {
            const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });
            downloadXLSXFile(wbout, fileName);
        } else {
            dispatchAlert({
                message: 'Keine Daten verfügbar',
                type: 'error',
            });
        }
    };

    return (
        <Button onClick={handleDownload} variant="outlined" style={{ marginRight: '5px' }} {...buttonProps}>
            {showFileIcon && <FileExport />}
            {buttonText}
        </Button>
    );
};

export default DownloadExcelButton;
