import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';

const BASE = '/working-unit-location';

export const getWorkingUnitLocations = async ({ location, hasMatchingOfferedCluster } = {}) => {
    let url = `${BASE}`;
    url = appendQueryParamsToUrl(url, { location, hasMatchingOfferedCluster });

    return api.get(url, await setToken()).then((res) => res.data);
};
