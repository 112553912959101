import { MenuItem } from '@mui/material';
import CustomSelect from '../UI/CustomSelect';

export const OfferedClusterSelect = ({ selectedCluster, onChange, offeredClusterOptions, ...props }) => {
    return (
        <CustomSelect
            selectedValue={selectedCluster}
            options={offeredClusterOptions}
            onChange={onChange}
            label={'Offered Cluster'}
            {...props}
        >
            {offeredClusterOptions &&
                offeredClusterOptions.map((offeredCluster) => (
                    <MenuItem value={offeredCluster.id} key={offeredCluster.id}>
                        <span className="notranslate">{offeredCluster.label}</span>
                    </MenuItem>
                ))}
        </CustomSelect>
    );
};
