import React, { useRef, useMemo, useCallback } from 'react';
import { Grid, Button } from '@mui/material';
import Container from '../Layout/Container';
import SaveIcon from '@mui/icons-material/Save';
import ButtonIconRenderer from '../AgGrid/ButtonIconRenderer/ButtonIconRenderer';
import EditIcon from '@mui/icons-material/Edit';
import TableGridItem from '../UI/TableGridItem';
import GridContainer from '../Layout/GridContainer';

const CRUDSettings = ({
    tableRows,
    columnDefs,
    createRecord,
    updateRecord,
    getAllData,
    btnText,
    recordStructure,
    recordType,
    validateData,
    isCreationViaDialog,
    isEditViaDialog,
    setShowCreationDialog,
    onSelectedRowChange,
    filters,
    ...restTableSettings
}) => {
    const gridRef = useRef(null);
    const columnToFocusOnEdit = columnDefs[0].field;

    const addEmptyRowToTopOfTable = () => {
        const emptyRow = {
            id: null,
            name: null,
            isNewRecord: true,
            ...recordStructure,
        };

        gridRef.current.api.applyTransaction({
            add: [emptyRow],
            addIndex: 0,
        });
    };

    const startEditingRecord = useCallback((rowIndex, columnToFocusOnEdit) => {
        gridRef.current.api.setFocusedCell(rowIndex, columnToFocusOnEdit);
        gridRef.current.api.startEditingCell({
            rowIndex,
            colKey: columnToFocusOnEdit,
        });
    }, []);

    const handleRowEdit = useCallback(
        (params) => {
            if (isEditViaDialog) {
                setShowCreationDialog(true);
                onSelectedRowChange(params.data);
                return;
            }

            const { rowIndex } = params;
            params.node.setData({ ...params.node.data, isRowEdited: true });

            startEditingRecord(rowIndex, columnToFocusOnEdit);
        },
        [startEditingRecord, columnToFocusOnEdit, isEditViaDialog, onSelectedRowChange, setShowCreationDialog]
    );

    const updateExistingRecord = useCallback(
        (params) => {
            let isDataValid = true;
            if (validateData) isDataValid = validateData(params.data);
            isDataValid && updateRecord(params.data);
        },
        [updateRecord, validateData]
    );

    const postRecord = useCallback(
        (params) => {
            const { isNewRecord } = params.data;
            if (isNewRecord) {
                createRecord(params.data);
            } else {
                updateExistingRecord(params);
            }
        },
        [createRecord, updateExistingRecord]
    );

    const handleCreateButtonClick = () => {
        if (isCreationViaDialog) {
            if (isEditViaDialog) {
                onSelectedRowChange(null);
            }

            setShowCreationDialog(true);
        } else {
            addEmptyRowToTopOfTable();
        }
    };

    const columns = useMemo(() => {
        return [
            {
                field: 'id',
                headerName: 'ID',
                editable: (params) => params.data.isNewRecord,
            },
            ...columnDefs,
            {
                field: '',
                headerName: 'Edit',
                editable: false,
                filter: false,
                suppressHeaderMenuButton: true,
                cellRenderer: (params) => {
                    return (
                        <div>
                            {!params.data.isNewRecord && (
                                <ButtonIconRenderer
                                    icon={EditIcon}
                                    onClick={() => handleRowEdit(params)}
                                ></ButtonIconRenderer>
                            )}

                            {(params.data.isRowEdited || params.data.isNewRecord) && (
                                <ButtonIconRenderer
                                    icon={SaveIcon}
                                    onClick={() => postRecord(params)}
                                ></ButtonIconRenderer>
                            )}
                        </div>
                    );
                },
            },
        ];
    }, [handleRowEdit, postRecord, columnDefs]);

    return (
        <Container>
            <GridContainer>
                {filters}
                <Grid item xs={3}>
                    <Button onClick={handleCreateButtonClick} variant="contained">
                        {btnText}
                    </Button>
                </Grid>
                <TableGridItem
                    ref={gridRef}
                    rowData={tableRows}
                    defaultColDef={{
                        editable: false,
                        filter: true,
                        sortable: true,
                    }}
                    columnDefs={columns}
                    {...restTableSettings}
                ></TableGridItem>
            </GridContainer>
        </Container>
    );
};

export default CRUDSettings;
