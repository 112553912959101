import {
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    FormHelperText,
    Checkbox,
    ListItemText,
    IconButton,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import NoOptionsItem from '../UI/NoOptionsItem';

const ReactHookFormSelect = ({
    name,
    label,
    control,
    options,
    defaultValue,
    mainProp,
    secondProp,
    rules,
    formErrors,
    isMultiSelect,
    valueProp,
    onItemClick,
    showClearButton,
    ...props
}) => {
    const labelId = `${name}-label`;
    const valueProperty = valueProp ? valueProp : 'id';

    const getRenderValue = (selectedValue) => {
        selectedValue = Array.isArray(selectedValue) ? selectedValue : [selectedValue];

        const value =
            options.length > 0
                ? selectedValue
                      .map((value) => {
                          const option = options.find((option) => option[valueProperty] === value);
                          return `${option[mainProp]}${secondProp ? ` - ${option[secondProp]}` : ''}`;
                      })
                      .join(', ')
                : '';

        return <span className="notranslate">{value}</span>;
    };

    const handleMenuItemClick = (field, value) => {
        if (onItemClick) {
            field.onChange(value);
            onItemClick();
        }
    };

    const handleClearClick = (field) => {
        field.onChange('');
    };

    return (
        <FormControl {...props} fullWidth={true}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                render={({ field, fieldState }) => (
                    <>
                        <Select
                            {...field}
                            label={label}
                            multiple={isMultiSelect}
                            renderValue={(selected) => getRenderValue(selected)}
                            endAdornment={
                                showClearButton ? (
                                    <IconButton
                                        sx={{ display: field.value ? '' : 'none' }}
                                        onClick={() => handleClearClick(field)}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                ) : null
                            }
                            sx={{
                                '& .MuiSelect-iconOutlined': { display: showClearButton && field.value ? 'none' : '' },
                            }}
                        >
                            {options.length === 0 && <NoOptionsItem />}
                            {options?.map((option) => (
                                <MenuItem
                                    key={option[valueProperty]}
                                    value={option[valueProperty]}
                                    onMouseDown={(e) => handleMenuItemClick(field, option[valueProperty])}
                                >
                                    {isMultiSelect && <Checkbox checked={field.value.indexOf(option.id) > -1} />}
                                    <ListItemText
                                        primary={`${option[mainProp]} ${secondProp ? ` - ${option[secondProp]}` : ''}`}
                                        className="notranslate"
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                        {fieldState?.error && (
                            <FormHelperText
                                sx={{
                                    color: 'var(--error) !important',
                                }}
                            >
                                {formErrors[name].message}
                            </FormHelperText>
                        )}
                    </>
                )}
            />
        </FormControl>
    );
};
export default ReactHookFormSelect;
