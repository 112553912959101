import { useQuery } from '@tanstack/react-query';
import {
    getBiddingCalculated,
    getBiddingCalculatedResults,
    getLastBiddingCalculationDate,
    getLastBiddingCalculatedTotalsReleaseDate,
    getSumOfReleasedBiddingCalculatedTotals,
} from '../../services/biddingCalculated';
import { QueryKey } from '../../enums/QueryKey';
import dayjs from 'dayjs';
import { formatLastUploadDate } from '../../utils/dateUtils';

export function useBiddingCalculated({ date, workingUnitLocation, released }) {
    const dateFrom = dayjs(date).startOf('month');
    const dateTo = dayjs(date).endOf('month');

    const { data: biddingCalculated, isSuccess } = useQuery({
        queryKey: [QueryKey.BiddingCalculated, { date, workingUnitLocation }],
        queryFn: () => getBiddingCalculated({ dateFrom, dateTo, workingUnitLocation, released }),
        initialData: [],
        enabled: !!date && !!workingUnitLocation,
    });

    return { biddingCalculated, isSuccess };
}

export function useBiddingCalculatedResults({ date, workingUnitLocation }) {
    const dateFrom = dayjs(date).startOf('month');
    const dateTo = dayjs(date).endOf('month');

    const { data: biddingCalculatedResults } = useQuery({
        queryKey: [QueryKey.BiddingCalculatedResults, { date, workingUnitLocation }],
        queryFn: () => getBiddingCalculatedResults({ dateFrom, dateTo, workingUnitLocation }),
        initialData: [],
        enabled: !!date && !!workingUnitLocation,
    });

    return [biddingCalculatedResults];
}

export function useLastBiddingCalculationDate(workingUnitLocation) {
    const { data } = useQuery({
        queryKey: [QueryKey.LastBiddingCalculationDate, { workingUnitLocation }],
        queryFn: () => getLastBiddingCalculationDate({ workingUnitLocation }),
        enabled: !!workingUnitLocation,
    });

    return formatLastUploadDate(data);
}

export function useLastBiddingCalculatedTotalsReleaseDate(workingUnitLocation) {
    const { data } = useQuery({
        queryKey: [QueryKey.LastBiddingCalculatedTotalsReleaseDate, { workingUnitLocation }],
        queryFn: () => getLastBiddingCalculatedTotalsReleaseDate({ workingUnitLocation }),
        enabled: !!workingUnitLocation,
    });

    return data ? formatLastUploadDate(data) : null;
}

export function useSumOfReleasedBiddingCalculatedTotals({ date, workingUnitLocation, enabled = true }) {
    const dateFrom = dayjs(date).startOf('month');
    const dateTo = dayjs(date).endOf('month');

    const { data: sumOfReleasedBiddingCalculatedTotals } = useQuery({
        queryKey: [QueryKey.ReleasedBiddingCalculatedTotalsSum, { date, workingUnitLocation }],
        queryFn: () => getSumOfReleasedBiddingCalculatedTotals({ dateFrom, dateTo, workingUnitLocation }),
        initialData: [],
        enabled: !!date && !!workingUnitLocation && enabled,
    });

    return { sumOfReleasedBiddingCalculatedTotals };
}
