import { useQuery } from '@tanstack/react-query';
import { getAllLocationFromHEMT } from '../../services/telefonica';
import { QueryKey } from '../../enums/QueryKey';

export function useHEMTLocations() {
    const { data } = useQuery({
        queryKey: [QueryKey.AllLocationsFromHEMT],
        queryFn: getAllLocationFromHEMT,
        initialData: [],
    });

    return Array.isArray(data) ? data : data ? [data] : [];
}
