import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';
const BASE = '/bidding-calculated';

export const getBiddingCalculated = async ({ dateFrom, dateTo, workingUnitLocation, released } = {}) => {
    let url = `${BASE}`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo, workingUnitLocation, released });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const getBiddingCalculatedResults = async ({ dateFrom, dateTo, workingUnitLocation } = {}) => {
    let url = `${BASE}/result`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo, workingUnitLocation });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const getLastBiddingCalculationDate = async ({ workingUnitLocation } = {}) => {
    let url = `${BASE}/last-calculation`;
    url = appendQueryParamsToUrl(url, { workingUnitLocation });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const releaseTotals = async ({ dateFrom, dateTo, locations, workingUnits, isSubsequentRelease }) => {
    return api
        .post(
            `${BASE}/release-totals`,
            { dateFrom, dateTo, locations, workingUnits, isSubsequentRelease },
            await setToken()
        )
        .then((res) => res.data);
};

export const getLastBiddingCalculatedTotalsReleaseDate = async ({ workingUnitLocation } = {}) => {
    let url = `${BASE}/last-totals-release`;
    url = appendQueryParamsToUrl(url, { workingUnitLocation });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const getSumOfReleasedBiddingCalculatedTotals = async ({ dateFrom, dateTo, workingUnitLocation } = {}) => {
    let url = `${BASE}/totals-sum`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo, workingUnitLocation });

    return api.get(url, await setToken()).then((res) => res.data);
};

export const calculateBidding = async ({ date }) => {
    let url = `${BASE}/calculate`;
    url = appendQueryParamsToUrl(url, { date });
    return api
        .get(url, await setToken())
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const createBiddingCalculatedTotals = async (data) => {
    return api.post(`${BASE}`, { data }, await setToken()).then((res) => res.data);
};
