import Container from '../components/Layout/Container';
import { Grid, Button } from '@mui/material';
import UserContext from '../store/User/UserContext';
import { useState, useContext, useCallback, useRef } from 'react';
import { useAlertMessage, useLocationsByLoggedInUserRole, useCookieLocation, useCustomStyles } from '../hooks';
import {
    createPerformedCapacityReport,
    createOnePerformedCapacityReport,
    checkPerformedCapacityReportPlausibility,
} from '../services/performedCapacityReport';
import { createTableRows } from '../utils/agGridUtils';
import { NUM_OF_MONTH_COLUMNS_FOR_CAPACITY_REPORT } from '../components/CapacityReport/helpers/constants';
import { getMonthColumns, createMapWithTableRows } from '../components/CapacityReport/helpers/utils';
import CapacityReportTable from '../components/CapacityReport/CapacityReportTable';
import PlausibilityCheckSelect from '../components/Selects/PlausibilityCheckSelect';
import { plausibilityCheckOptions } from '../components/CapacityReport/helpers/constants';
import { ReactComponent as FileExport } from '../assets/file-export.svg';
import SelectExportCapacitiesDateRangeDialog from '../components/CapacityReport/SelectExportCapacitiesDateRangeDialog';
import { CapacityReportType } from '../enums/CapacityReportType';
import dayjs from 'dayjs';
import UserRoleLocation from '../components/General/UserRoleLocation';
import LastUpload from '../components/General/LastUpload';
import GridContainer from '../components/Layout/GridContainer';
import {
    useLastPerformedCapacityReportUploadDate,
    getPerformedCapacityReportQueryKey,
    usePerformedCapacityReport,
} from '../hooks/api/performedCapacityReports';
import { queryClient } from '../services/queryClient';
import { useMutation } from '@tanstack/react-query';

function PerformedCapacityReport() {
    const classes = useCustomStyles();

    const { dispatchAlert } = useAlertMessage();
    const loggedInUser = useContext(UserContext);

    const gridRef = useRef(null);

    const [locationOptions] = useLocationsByLoggedInUserRole();
    const [selectedLocation, setSelectedLocation] = useCookieLocation(locationOptions);
    const [selectedPlausibilityCheck, setSelectedPlausibilityCheck] = useState(plausibilityCheckOptions[0].value);
    const lastUploadDate = useLastPerformedCapacityReportUploadDate(selectedLocation);

    const [firstMonth, setFirstMonth] = useState(dayjs().subtract(11, 'months'));
    const [expandedRows, setExpandedRows] = useState([]);

    const [openExportDialog, setOpenExportDialog] = useState(false);

    const performedCapacityReportQueryKey = getPerformedCapacityReportQueryKey({
        date: firstMonth,
        location: selectedLocation,
        plausibilityCheck: selectedPlausibilityCheck,
        numOfMonthColumns: NUM_OF_MONTH_COLUMNS_FOR_CAPACITY_REPORT,
    });
    const monthColumns = getMonthColumns(firstMonth);

    const { data: performedCapacityReports } = usePerformedCapacityReport({
        date: firstMonth,
        location: selectedLocation,
        plausibilityCheck: selectedPlausibilityCheck,
        numOfMonthColumns: NUM_OF_MONTH_COLUMNS_FOR_CAPACITY_REPORT,
    });
    const tableRows =
        performedCapacityReports.length > 0
            ? createTableRows(
                  createMapWithTableRows(performedCapacityReports, monthColumns, 'performedCapacityReports'),
                  'id'
              )
            : [];

    const { mutateAsync: handlePlausibilityCheck } = useMutation({
        mutationFn: checkPerformedCapacityReportPlausibility,
        onSuccess: () => {
            queryClient.invalidateQueries(performedCapacityReportQueryKey);
        },
    });

    const { mutateAsync: handleCreatePerformedCapacityReport } = useMutation({
        mutationFn: createPerformedCapacityReport,
    });
    const { mutateAsync: handleCreateOnePerformedCapacityReport } = useMutation({
        mutationFn: createOnePerformedCapacityReport,
    });

    const getExpandedRowsInTable = () => {
        const expandedRows = [];
        gridRef.current.api.forEachNode((node) => {
            if (node.displayed && node.expanded) {
                expandedRows.push(node);
            }
        });

        return expandedRows;
    };

    const saveCapacityReport = useCallback(
        async ({ tableRows, loggedInUserId, cellParams }) => {
            try {
                setExpandedRows(getExpandedRowsInTable());

                let numOfNewReports = 0;
                if (cellParams) {
                    const capacityReportValue = cellParams.newValue;
                    let data = {
                        changedCapacity: {
                            capacityId: parseInt(cellParams.data.id),
                            capacityTypeName: cellParams.data.capacityType.name,
                            date: cellParams.column.colId,
                            value: capacityReportValue,
                            capacityType: cellParams.data.capacityType,
                        },
                        userId: loggedInUserId,
                    };

                    await handleCreateOnePerformedCapacityReport(data);
                    numOfNewReports = 1;
                } else {
                    const result = await handleCreatePerformedCapacityReport(tableRows, loggedInUserId);
                    numOfNewReports = result.numOfNewReports;
                }

                if (numOfNewReports === 0) {
                    dispatchAlert({
                        message: 'Geben Sie gültige Daten ein',
                        type: 'error',
                    });
                } else {
                    dispatchAlert({
                        message: 'Potenzialmeldung erfolgreich erstellt',
                        type: 'success',
                    });
                }
            } catch (error) {
                dispatchAlert({
                    message: 'Error occured',
                    type: 'error',
                });
            } finally {
                queryClient.invalidateQueries(performedCapacityReportQueryKey);
            }
        },
        [
            dispatchAlert,
            handleCreateOnePerformedCapacityReport,
            handleCreatePerformedCapacityReport,
            performedCapacityReportQueryKey,
        ]
    );

    const handleExportDialogClose = () => {
        setOpenExportDialog(false);
    };

    const handleExportDialogOpen = () => {
        setOpenExportDialog(true);
    };

    return (
        <Container>
            <GridContainer>
                {loggedInUser && (
                    <UserRoleLocation
                        loggedInUser={loggedInUser}
                        selectedLocation={selectedLocation}
                        locationOptions={locationOptions}
                        onChange={setSelectedLocation}
                    ></UserRoleLocation>
                )}
                <Grid item xs={12} md={3} lg={2}>
                    <PlausibilityCheckSelect
                        selectedValue={selectedPlausibilityCheck}
                        onChange={setSelectedPlausibilityCheck}
                    ></PlausibilityCheckSelect>
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                    <LastUpload showInfoIcon={false} date={lastUploadDate}></LastUpload>
                </Grid>
                <Grid item>
                    <Button onClick={handleExportDialogOpen} variant="contained">
                        <FileExport />
                    </Button>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <Button
                        onClick={() => handlePlausibilityCheck({ data: tableRows, locationId: selectedLocation })}
                        variant="outlined"
                    >
                        Plausibilitäts-Check
                    </Button>
                </Grid>
                <CapacityReportTable
                    ref={gridRef}
                    firstMonth={firstMonth}
                    onFirstMonthChanged={setFirstMonth}
                    tableRows={tableRows}
                    expandedRows={expandedRows}
                    onExpandedRowsChanged={setExpandedRows}
                    getExpandedRowsInTable={getExpandedRowsInTable}
                    saveCapacityReport={(params) =>
                        saveCapacityReport({
                            tableRows,
                            loggedInUserId: loggedInUser?.id,
                            firstMonth,
                            selectedLocation,
                            ...params,
                        })
                    }
                ></CapacityReportTable>
                <Grid item sm={6} md={8} lg={9}></Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} sx={classes.btnRightAligned}>
                    <Button
                        variant="outlined"
                        onClick={() =>
                            saveCapacityReport({
                                tableRows,
                                loggedInUserId: loggedInUser?.id,
                                firstMonth,
                                selectedLocation,
                            })
                        }
                    >
                        Speichern
                    </Button>
                </Grid>
            </GridContainer>
            {openExportDialog && (
                <SelectExportCapacitiesDateRangeDialog
                    open={openExportDialog}
                    onClose={handleExportDialogClose}
                    locations={locationOptions}
                    reportType={CapacityReportType.PERFORMED_CAPACITY_REPORT}
                ></SelectExportCapacitiesDateRangeDialog>
            )}
        </Container>
    );
}

export default PerformedCapacityReport;
