import { forwardRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import getLoadingOverlay from './LoadingOverlay';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './agGridStyle.scss';
import NoRowsOverlay from './NoRowsOverlay';

const DISTANCE_TO_TABLE = 250; //px

let headerComponentParams = {
    template: `<div class="ag-cell-label-container" role="presentation">
    <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
    <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button" aria-hidden="true"></span>
    <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span data-ref="eText" class="ag-header-cell-text" style="white-space: pre-line;"></span>
        <span data-ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
        <span data-ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
        <span data-ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
        <span data-ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
        <span data-ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
    </div>
</div>`,
};

const Table = forwardRef(({ children, height, defaultColDef, ...props }, ref) => {
    return (
        <div
            className="ag-theme-material"
            style={{ height: height ? height : `calc(100vh - ${DISTANCE_TO_TABLE}px)`, width: '100%' }}
        >
            <AgGridReact
                ref={ref}
                defaultColDef={{
                    editable: true,
                    sortable: true,
                    resizable: true,
                    filter: 'agMultiColumnFilter',
                    minWidth: 70,
                    flex: 1,
                    headerComponentParams: headerComponentParams,
                    cellClass: 'notranslate',
                    ...defaultColDef,
                }}
                animateRows={true}
                undoRedoCellEditing={true}
                undoRedoCellEditingLimit={10}
                overlayLoadingTemplate={getLoadingOverlay('Loading...')}
                noRowsOverlayComponent={() => <NoRowsOverlay text="No data available"></NoRowsOverlay>}
                suppressCsvExport={true}
                suppressExcelExport={true}
                rowHeight={32}
                {...props}
            >
                {children}
            </AgGridReact>
        </div>
    );
});

export default Table;
