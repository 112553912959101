import ExportToExcelButton from '../Buttons/ExportToExcelButton';
import dayjs from 'dayjs';
import { useTotalsExportData } from '../../hooks/api/totals';
import { getTotalsTypeByValue } from '../../enums/TotalsType';

const exportColumnNames = ['Worktype', 'Kanal', 'Task', 'Company', 'Location', 'Uhrzeit', 'Datum', 'Value'];

const TEFExportTotalsButton = ({ date, totalsType }) => {
    const { refetch: refetchExportData } = useTotalsExportData({
        date,
        totalsType,
        fetchImmediately: false,
    });

    const getExportFileName = () => {
        return `${getTotalsTypeByValue(totalsType).nameTEF}_${dayjs(date).format('MM.YYYY')}.xlsx`;
    };

    const getExportData = (totals) => {
        return totals.data;
    };

    return (
        <ExportToExcelButton
            columnNames={exportColumnNames}
            sheetName="Tabelle1"
            getFileName={getExportFileName}
            wbTitle="Totals"
            wbSubject="Export Totals"
            getDataFromDb={refetchExportData}
            getExportData={getExportData}
        ></ExportToExcelButton>
    );
};

export default TEFExportTotalsButton;
