import api from './index';
import { setToken } from '../utils/authUtils';
import { appendQueryParamsToUrl } from '../utils';

const BASE = '/corrected-results';

export const calculateCorrectedResults = async ({ dateFrom, dateTo }) => {
    let url = `${BASE}/calculate`;

    return api.post(url, { dateFrom, dateTo }, await setToken()).then((res) => res.data);
};

export const getCorrectedResults = async ({ dateFrom, dateTo, workingUnitLocation } = {}) => {
    let url = `${BASE}`;
    url = appendQueryParamsToUrl(url, { dateFrom, dateTo, workingUnitLocation });

    return api.get(url, await setToken()).then((res) => res.data);
};
